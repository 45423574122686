export const extraClassifications = {
  incidentStatus: [
    { id: 'open', name: 'open' },
    { id: 'closed', name: 'closed' }
  ],

  incidentCritical: [
    { id: 'true', name: 'yes' },
    { id: 'false', name: 'no' }
  ]
};
