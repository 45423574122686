import PointSchema from './point';
import { UUID } from '../helpers';

const InvolvedIndividualSchema = {
  type: 'object',
  properties: {
    id: { type: ['string', 'null'] },
    firstNames: { type: ['string', 'null'] },
    middleNames: { type: ['string', 'null'] },
    lastNames: { type: ['string', 'null'] },
    dateOfBirth: { type: ['string', 'null'] },
    phoneNumber: { type: ['string', 'null'] },
    postalAddress: { type: ['string', 'null'] },
    email: { type: ['string', 'null'] }
  }
};

const InvolvedVesselSchema = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    name: { type: ['string', 'null'] },
    description: { type: ['string', 'null'] }
  }
};

export default {
  version: 1,
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: UUID.maxLength,
      final: true
    },
    sequenceNumber: { type: ['integer', 'null'] },
    createdAt: { type: ['integer', 'null'] },
    updatedAt: { type: ['integer', 'null'] },
    datetime: { type: ['integer', 'null'] },
    description: { type: 'string' },
    critical: { type: ['boolean', 'null'] },
    breachNoticeServed: { type: 'boolean', default: false },
    bodyCamAttachments: { type: 'boolean', default: false },
    deviceAttachments: { type: 'boolean', default: false },
    logBookAttachments: { type: 'boolean', default: false },
    harboursUserGroup: { type: ['string', 'null'], ref: 'harboursusergroups' },
    harbourmasterVessel: {
      type: ['string', 'null'],
      ref: 'harbourmastervessels'
    },
    parentHarboursUserGroup: {
      type: ['string', 'null'],
      ref: 'harboursusergroups'
    },
    operationalArea: { type: ['string', 'null'], ref: 'operationalareas' },
    user: { type: ['string', 'null'], ref: 'users' },
    status: { type: 'string', default: 'open' },
    parentOperationalArea: {
      type: ['string', 'null'],
      ref: 'operationalareas'
    },
    category: {
      type: ['string', 'null'],
      ref: 'incidentcategories'
    },
    subcategory: {
      type: ['string', 'null'],
      ref: 'incidentcategories'
    },
    location: { ...PointSchema, type: ['object', 'null'] },
    closedAt: { type: ['integer', 'null'] },
    involvedIndividuals: {
      type: 'array',
      items: InvolvedIndividualSchema
    },
    involvedVessels: {
      type: 'array',
      items: InvolvedVesselSchema
    }
  },
  required: ['id', 'datetime', 'description', 'user']
};
