import PropTypes from 'prop-types';
import React from 'react';

const printContactDetailsIfPresent = individual => {
  if (individual.phoneNumber) {
    return individual.phoneNumber;
  } else if (individual.email) {
    return <a href={`mailto:${individual.email}`}>{individual.email}</a>;
  }
};

export default function InvolvedIndividualSummary({ individual }) {
  return (
    <>
      {`${individual.firstNames ? individual.firstNames : ''}${
        individual.middleNames ? ' ' + individual.middleNames : ''
      }${individual.lastNames ? ' ' + individual.lastNames : ''}`}
      <br />
      {printContactDetailsIfPresent(individual)}
      <br />
    </>
  );
}

InvolvedIndividualSummary.propTypes = {
  individual: PropTypes.object.isRequired
};
