import gql from 'graphql-tag';

export default gql`
  query {
    operationalAreas {
      id
      name
      children {
        id
        name
      }
    }

    incidentCategories {
      id
      name
      children {
        id
        name
      }
    }

    actionCategories {
      id
      name
      children {
        id
        name
      }
    }

    harbourmasterVessels {
      id
      name
    }

    harboursUserGroups {
      id
      name
      children {
        id
        name
      }
    }

    assetCategories {
      id
      name
    }

    users {
      id
      name
      staff
    }
  }
`;
