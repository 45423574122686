import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
export const EXPAND_ALL = 'all';

const CollapsibleList = ({
  initiallyExpanded = [],
  collection = [],
  TitleComponent,
  ItemComponent
}) => {
  const [expanded, setExpanded] = useState(
    new Set(initiallyExpanded === EXPAND_ALL ? collection : initiallyExpanded)
  );

  const expandAll = useCallback(
    () => setExpanded(new Set(collection)),
    [collection]
  );
  const collapseAll = useCallback(() => setExpanded(new Set()), []);
  const toggleExpanded = useCallback(
    item => () =>
      setExpanded(prev => {
        let next = new Set(prev);
        next.has(item) ? next.delete(item) : next.add(item);

        return next;
      }),
    [setExpanded]
  );

  const expandOrCollapseState = {
    expanded: expanded.size > 0,
    expandOrCollapseAll: expanded.size > 0 ? collapseAll : expandAll
  };

  return (
    <>
      <TitleComponent {...expandOrCollapseState} />
      {collection.map(item => {
        const isExpanded = expanded.has(item);
        const expandOrCollapse = toggleExpanded(item);

        return (
          <ItemComponent
            key={item.id}
            item={item}
            isExpanded={isExpanded}
            expandOrCollapse={expandOrCollapse}
          />
        );
      })}
    </>
  );
};

CollapsibleList.propTypes = {
  ItemComponent: PropTypes.func.isRequired,
  TitleComponent: PropTypes.func.isRequired,
  collection: PropTypes.array.isRequired,
  initiallyExpanded: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default CollapsibleList;
