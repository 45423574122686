import { sortBy } from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import CustomSelect from './CustomSelect';
import { FormGroupField } from '../forms/field';

const ClassificationSelect = ({
  id,
  name,
  label,
  labelClass,
  includeBlank = false,
  collection,
  ...fieldProps
}) => {
  let options = sortBy(collection, 'name').map(item => {
    return { value: item.id, label: item.name };
  });

  includeBlank && (options = [{ value: null, label: '' }, ...options]);

  return (
    <>
      <label className={labelClass ? labelClass : null} htmlFor={id}>
        {label}
      </label>
      <FormGroupField
        {...fieldProps}
        id={id}
        name={name}
        ariaLabel={label}
        component={CustomSelect}
        options={options}
        isClearable={includeBlank}
      />
    </>
  );
};

ClassificationSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  includeBlank: PropTypes.bool,
  collection: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ClassificationSelect;
