import { harbourmasterVesselsPullQueryBuilder } from '../replication/harbourmasterVessel';
import schema, { migrationStrategies } from '../schemas/taxonomy';

export default {
  operationalareas: {
    schema,
    migrationStrategies,
    graphql: { field: 'operationalAreas', hasChildren: true }
  },
  incidentcategories: {
    schema,
    migrationStrategies,
    graphql: { field: 'incidentCategories', hasChildren: true }
  },
  actioncategories: {
    schema,
    migrationStrategies,
    graphql: { field: 'actionCategories', hasChildren: true }
  },
  assetcategories: {
    schema,
    migrationStrategies,
    graphql: { field: 'assetCategories', hasChildren: true }
  },
  harbourmastervessels: {
    schema,
    migrationStrategies,
    graphql: {
      queryBuilder: harbourmasterVesselsPullQueryBuilder,
      dataPath: 'data.assetCollection.collection'
    }
  },
  harboursusergroups: {
    schema,
    migrationStrategies,
    graphql: { field: 'harboursUserGroups', hasChildren: true }
  }
};
