import jQuery from 'jquery';
import { startCase } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRxData } from 'rxdb-hooks';
import LatestActionMetadata from './LatestActionMetadata';
import OpenRecurringActionLink from './OpenRecurringActionLink';
import ActionsList from '../actions/ActionsList';
import useRelatedActions from '../actions/useRelatedActions';
import FormattedContent from '../common/content/FormattedContent';
import { formatMonthsAndWeeks, localize } from '../common/date-format';
import useRxDocument from '../common/hooks/useRxDocument';
import MapUI from '../common/map/index';
import titleize from '../common/titleize';
import LogEntryCollection from '../logEntries/Collection';

const AssetMetadata = ({ asset, render }) => {
  const ignoredProperties = [
    /operational_area_/,
    'identification_number',
    'additionalProperties',
    'type'
  ];

  return (
    <>
      {Object.keys(asset.metadata)
        .reverse()
        .map(key => {
          if (
            ignoredProperties.some(ignore =>
              ignore instanceof RegExp ? ignore.test(key) : ignore === key
            )
          ) {
            return null;
          }

          let value = asset.metadata[key];

          if (key === 'purchaseDate') {
            value = localize(value, { format: 'defaultDate' });
          }

          return render({
            key,
            value: value,
            title: titleize(key).replace(/Id\s?/, 'ID ')
          });
        })}
    </>
  );
};

AssetMetadata.propTypes = {
  asset: PropTypes.object.isRequired,
  render: PropTypes.func
};

const AssetShow = props => {
  const {
    match: { params },
    location
  } = props;

  const foundationRef = useRef();
  useEffect(
    () => jQuery(foundationRef.current).foundation() && undefined,
    [foundationRef]
  );

  const [associated, setAssociated] = useState({});
  const { result: asset, isFetching } = useRxDocument('assets', params.id);

  const { openActions, closedActions } = useRelatedActions('asset', params.id);

  let logEntries = [];
  let logEntriesFetching = false;

  const logEntriesQueryConstructor = useCallback(
    collection =>
      collection.find({
        selector: {
          'source.id': { $eq: asset && asset.id },
          'source.type': { $eq: 'Asset' }
        }
      }),
    [asset]
  );

  ({ result: logEntries, isFetching: logEntriesFetching } = useRxData(
    'logentries',
    logEntriesQueryConstructor
  ));

  useEffect(
    () =>
      (async () => {
        if (!asset) {
          return;
        }

        const category = await asset.populate('category');
        const parentOperationalArea = await asset.populate(
          'parentOperationalArea'
        );

        setAssociated({
          parentOperationalArea,
          operationalArea:
            parentOperationalArea &&
            parentOperationalArea.children.find(
              oa => oa.id === asset.operationalArea
            ),
          category,
          subcategory:
            category &&
            category.children.find(cat => cat.id === asset.subcategory)
        });
      })() && undefined,
    [asset]
  );

  if (isFetching) {
    return 'Loading...';
  }

  if (!asset) {
    return 'Not found';
  }

  return (
    <div
      ref={foundationRef}
      className="page page--white-background grid-x grid-margin-x"
    >
      <div className="small-8 cell large-9 panel summary large-offset-1 navigation-aid-summary">
        <div className="grid-x cell panelheader">
          <div className="cell auto">
            <h2>Asset Details</h2>
          </div>
          <div className="cell small-4 large-3 text-right">
            <LatestActionMetadata
              openActions={openActions}
              closedActions={closedActions}
            />
          </div>
        </div>

        <div className="grid-x">
          <div className="cell auto">
            <dl className="data-list">
              <div className="grid-x row">
                <dt className="cell large-3">Asset Number</dt>
                <dd className="cell large-9">{asset.sequenceNumber}</dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Asset Name</dt>
                <dd className="cell large-9">{asset.name}</dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Asset Category</dt>
                <dd className="cell large-9">
                  {' '}
                  {associated.category && associated.category.name} →{' '}
                  {associated.subcategory && associated.subcategory.name}
                </dd>
              </div>

              <div className="grid-x row">
                <dt className="cell large-3">Asset Details</dt>
                <dd className="cell large-9">
                  <FormattedContent content={asset.description} />
                </dd>
              </div>

              <div className="grid-x row">
                <dt className="cell large-3">Asset Location</dt>
                <dd className="cell large-9">{asset.locationDescription}</dd>
              </div>

              {asset.location && (
                <>
                  <div className="grid-x row">
                    <dt className="cell large-3">Location</dt>
                    <dd className="cell large-9">
                      Lat: {asset.location.lat}, Lng: {asset.location.lng}
                    </dd>
                  </div>
                  <MapUI
                    zoom={10}
                    graphicLocation={[asset.location.lng, asset.location.lat]}
                  />
                </>
              )}

              <div className="grid-x row">
                <dt className="cell large-3">Operational area</dt>
                <dd className="cell large-9">
                  {associated.parentOperationalArea &&
                  associated.parentOperationalArea.name
                    ? associated.parentOperationalArea.name + ' → '
                    : null}
                  {associated.operationalArea &&
                    associated.operationalArea.name}
                </dd>
              </div>

              <div className="grid-x row">
                <dt className="cell large-3">Routine check period</dt>
                <dd className="cell large-9">
                  {formatMonthsAndWeeks(asset.checkIntervalMonths)}
                </dd>
              </div>

              <AssetMetadata
                asset={asset}
                // eslint-disable-next-line react/jsx-no-bind
                render={({ key, title, value }) => (
                  <div className="grid-x row" key={key}>
                    <dt className="cell large-3">{startCase(title)}</dt>
                    <dd className="cell large-9">{value}</dd>
                  </div>
                )}
              />
            </dl>
            {logEntriesFetching || isFetching ? (
              'Loading log entries...'
            ) : (
              <LogEntryCollection
                sourceType={'Asset'}
                sourceId={asset.id}
                canBePropagated={false}
                logEntries={logEntries.sort((a, b) => a.logTime - b.logTime)}
              />
            )}
          </div>
        </div>
      </div>
      <div className="cell small-4 large-2">
        <h5>
          <small>Add</small>
        </h5>
        <Link
          to={`/actions/new?assetId=${asset.id}`}
          className="button tertiary expanded"
        >
          New Action
        </Link>
        <OpenRecurringActionLink openActions={openActions} />
        <a
          href={`#log-entry-create-Asset-${asset.id}-content`}
          className="button tertiary expanded"
        >
          Add Log Entry to Asset
        </a>
        <h5>
          <small>Manage</small>
        </h5>
        <Link
          to={`/manage-assets/${asset.id}/edit`}
          className="button secondary expanded"
        >
          Edit Asset
        </Link>
      </div>
      {openActions && openActions.length > 0 && (
        <ActionsList
          actions={openActions}
          expandedActions={[
            openActions.find(({ id }) => `#action-item-${id}` === location.hash)
          ]}
          // Use a cache-busting key when a hash is present, to ensure that the action item always re-expands
          key={
            location.hash &&
            location.hash.startsWith('#action-item-') &&
            `actions-list-rerender-${Math.random()}`
          }
          title="Open Actions"
        />
      )}
      {closedActions && closedActions.length > 0 && (
        <ActionsList actions={closedActions} title="Closed Actions" />
      )}
    </div>
  );
};

AssetShow.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object
};

export default AssetShow;
