import React, { useEffect, useState } from 'react';
import { useRxData } from 'rxdb-hooks';
import { headers } from './data/asset-headers';
import getAssetDocumentProperties from './data/get-asset-document-properties';
import DataTable from '../dataTable/data-table';

export default () => {
  const [tableData, setTableData] = useState([]);

  const { result: assets, isLoading } = useRxData('assets', collection =>
    // Filter out AtoNs, this will be undone when support for AtoNs has been added to the create/edit assets page
    collection.find({ selector: { category: { $ne: '1' } } })
  );

  const { result: actions } = useRxData('actions', collection =>
    collection.find()
  );

  const { result: users } = useRxData('users', collection => collection.find());

  const unsortableHeaders = [...headers];
  unsortableHeaders.forEach(header => (header.sortable = false));

  useEffect(() => {
    assets &&
      actions &&
      users &&
      Promise.all(
        assets.map(asset => getAssetDocumentProperties(asset, actions, users))
      ).then(setTableData);
  }, [assets, actions, users]);

  if (isLoading || !tableData.length) {
    return 'Loading...';
  }

  return (
    <DataTable
      resource={'manage-assets'}
      tableData={tableData}
      headers={unsortableHeaders}
    />
  );
};
