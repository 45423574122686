import { diff } from 'deep-object-diff';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { FormSpy } from 'react-final-form';
import { loadAutosaveItem, saveAutosaveItem } from './autosave-helpers';
import { includeRemovedValues } from '../../common/include-removed-values';

function FinalFormAutoSave(props) {
  const { storageKey, form, DecoratorComponent, ...rest } = props;
  const { dirty, dirtyFieldsSinceLastSubmit, initialValues } = form.getState();

  // If values have been removed, they are not included
  // in final-form values. We re-add them here.
  const values = includeRemovedValues(initialValues, props.values);
  const JSONvalues = JSON.stringify(values);
  const changes = diff(
    JSON.parse(loadAutosaveItem(storageKey)),
    JSON.parse(JSONvalues)
  );

  // If the form has actually submitted all the fields,
  // then this state change is probably the values changing to an empty
  // object, in which case we don't want to re-save.
  if (dirty && !isEmpty(dirtyFieldsSinceLastSubmit) && !isEmpty(changes)) {
    saveAutosaveItem(storageKey, values);
  }

  return DecoratorComponent ? (
    <DecoratorComponent {...rest} form={form} values={values} key="decorator" />
  ) : null;
}

FinalFormAutoSave.propTypes = {
  storageKey: PropTypes.string,
  form: PropTypes.object,
  values: PropTypes.object,
  DecoratorComponent: PropTypes.func
};

export default props => (
  <FormSpy
    {...props}
    subscription={{ values: true }}
    component={FinalFormAutoSave}
  />
);
