import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ReviewActionSubmission from '../actions/ReviewSubmission';
import ClassificationsContext from '../common/ClassificationsContext';
import formatBoolean from '../common/boolean-format';
import FormattedContent from '../common/content/FormattedContent';
import { localize } from '../common/date-format';

const classificationName = (records, id) =>
  records.find(r => r.id.toString() === (id && id.toString()))?.name;

const ReviewSubmission = ({ params }) => {
  const {
    incidentCategories,
    harbourUserGroups,
    harbourmasterVessels,
    users,
    operationalAreas
  } = useContext(ClassificationsContext);

  const parentOperationalAreaName =
    params.parentOperationalArea &&
    classificationName(operationalAreas, params.parentOperationalArea);

  const operationalAreaName = params.operationalArea
    ? classificationName(
        operationalAreas.find(
          c => c.id.toString() === params.parentOperationalArea.toString()
        ).children,
        params.operationalArea
      )
    : parentOperationalAreaName;

  const categoryName =
    params.category && classificationName(incidentCategories, params.category);

  const subcategoryName = params.subcategory
    ? classificationName(
        incidentCategories.find(
          c => c.id.toString() === params.category.toString()
        ).children,
        params.subcategory
      )
    : categoryName;

  return (
    <>
      <div className="panel summary">
        <div className="grid-x">
          <div className="cell auto">
            <h3>Incident Summary</h3>
          </div>
          <div className="cell shrink">
            <small>
              Opened by {classificationName(users, params.user)}
              <br />
              {localize(new Date())}
            </small>
          </div>
        </div>
        <dl className="data-list">
          <div className="grid-x row">
            <dt className="cell large-3">Date/Time</dt>
            <dd className="cell large-9">{localize(params.datetime)}</dd>
          </div>
          <div className="grid-x row">
            <dt className="cell large-3">Patrol vessel involved</dt>
            <dd className="cell large-9">
              {classificationName(
                harbourmasterVessels,
                params.harbourmasterVessel
              )}
            </dd>
          </div>
          <div className="grid-x row">
            <dt className="cell large-3">Incident category</dt>
            <dd className="cell large-9">
              {categoryName &&
                subcategoryName &&
                `${categoryName} → ${subcategoryName}`}
            </dd>
          </div>
          <div className="grid-x row">
            <dt className="cell large-3">Harbour user group</dt>
            <dd className="cell large-9">
              {params.parentHarboursUserGroup &&
                classificationName(
                  harbourUserGroups,
                  params.parentHarboursUserGroup
                )}
              {params.parentHarboursUserGroup &&
                params.harboursUserGroup &&
                params.parentHarboursUserGroup !== params.harboursUserGroup &&
                ` → ${classificationName(
                  harbourUserGroups
                    .filter(c => c.id === params.parentHarboursUserGroup)
                    .flatMap(hug => hug.children),
                  params.harboursUserGroup
                )}`}
            </dd>
          </div>
          <div className="grid-x row">
            <dt className="cell large-3">Operational area</dt>
            <dd className="cell large-9">
              {`${parentOperationalAreaName} → ${operationalAreaName}`}
            </dd>
          </div>
          <div className="grid-x row">
            <dt className="cell large-3">Location</dt>
            <dd className="cell large-9">
              {params.latitude &&
                params.longitude &&
                `Lat: ${params.latitude}, Lng: ${params.longitude}`}
            </dd>
          </div>
          <div className="grid-x row">
            <dt className="cell large-3">Incident description</dt>
            <dd className="cell large-9">
              {<FormattedContent content={params.description} />}
            </dd>
          </div>
          <div className="grid-x row">
            <dt className="cell large-3">Breach notice served</dt>
            <dd className="cell large-9">
              {formatBoolean(params.breachNoticeServed)}
            </dd>
          </div>
          <div className="grid-x row">
            <dt className="cell large-3">Body Cam</dt>
            <dd className="cell large-9">
              {formatBoolean(params.bodyCamAttachments)}
            </dd>
          </div>
          <div className="grid-x row">
            <dt className="cell large-3">Device</dt>
            <dd className="cell large-9">
              {formatBoolean(params.deviceAttachments)}
            </dd>
          </div>
          <div className="grid-x row">
            <dt className="cell large-3">Log books and paperwork</dt>
            <dd className="cell large-9">
              {formatBoolean(params.logBookAttachments)}
            </dd>
          </div>
          <div className="grid-x row">
            <dt className="cell large-3">Individuals involved</dt>
            {(params.involvedIndividuals || []).map(ind => (
              <dd className="cell large-9" key={ind}>
                {ind.firstNames} {ind.middleNames} {ind.lastNames}
                <br />
                {ind.phoneNumber || ind.email}
              </dd>
            ))}
          </div>
          <div className="grid-x row">
            <dt className="cell large-3">Vessels involved</dt>
            {(params.involvedVessels || []).map(vessel => {
              return (
                vessel && (
                  <dd className="cell large-9" key={vessel}>
                    {vessel.name || vessel.description}
                  </dd>
                )
              );
            })}
          </div>
        </dl>
      </div>
      {params.incidentAction && (
        <ReviewActionSubmission params={params.incidentAction} />
      )}
    </>
  );
};

ReviewSubmission.propTypes = {
  params: PropTypes.object
};

export default ReviewSubmission;
