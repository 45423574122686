import PropTypes from 'prop-types';
import React from 'react';
import { FormSpy } from 'react-final-form';
import CustomSelect from './CustomSelect';
import { FormGroupContext, FormGroupField } from '../forms/field';

const TaxonomyChildSelect = ({
  parents,
  parentAttribute,
  childAttribute,
  label,
  labelClass,
  ...fieldProps
}) => (
  <FormGroupContext.Consumer>
    {prefix => (
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          const parentId = prefix
            ? values[prefix]?.[parentAttribute]
            : values[parentAttribute];
          const parent = parents.find(c => c.id === parentId);

          const children = parent ? parent.children : [];
          const childIds = children.map(c => c.id);

          const childId = prefix
            ? values[prefix]?.[childAttribute]
            : values[childAttribute];

          if (children.length && !childIds.includes(childId)) {
            prefix
              ? delete values[prefix]?.[childAttribute]
              : delete values[childAttribute];
          }

          if (children.length === 1) {
            prefix
              ? (values[prefix][childAttribute] = children[0].id.toString())
              : (values[childAttribute] = children[0].id.toString());
          }

          if (children.length <= 1) {
            return null;
          }

          const options = [...children]
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(cat => ({ value: cat.id, label: cat.name }));

          return (
            <>
              <label
                className={labelClass ? labelClass : null}
                htmlFor={childAttribute}
              >
                {label}
              </label>
              <FormGroupField
                {...fieldProps}
                id={childAttribute}
                name={childAttribute}
                component={CustomSelect}
                options={options}
                ariaLabel={label}
                isClearable
              />
            </>
          );
        }}
      </FormSpy>
    )}
  </FormGroupContext.Consumer>
);

TaxonomyChildSelect.propTypes = {
  parents: PropTypes.arrayOf(PropTypes.object),
  parentAttribute: PropTypes.string.isRequired,
  childAttribute: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelClass: PropTypes.string
};

export default TaxonomyChildSelect;
