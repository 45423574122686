import PropTypes from 'prop-types';
import React from 'react';
import ActionSummary from './ActionSummary';
import useRxDocument from '../common/hooks/useRxDocument';

const ActionShow = ({ match: { params } }) => {
  const { result: action, isFetching } = useRxDocument('actions', params.id);
  if (isFetching) {
    return 'Loading...';
  }

  if (!action) {
    return 'Not found';
  }

  return (
    <div className="page page--white-background grid-x grid-margin-x">
      <ActionSummary action={action} />
    </div>
  );
};

ActionShow.propTypes = {
  match: PropTypes.object.isRequired
};

export default ActionShow;
