export const headers = [
  { name: 'Number', id: 'sequence_number', className: 'table-header--small' },
  { name: 'People Involved', id: 'involved_individuals', sortable: false },
  { name: 'Category', id: 'incident_category' },
  { name: 'Location', id: 'operational_area' },
  { name: 'User Group', id: 'harbours_user_group' },
  { name: 'Recorded By', id: 'user' },
  { name: 'Date & Time', id: 'datetime', className: 'table-header--medium' },
  { name: 'Status', id: 'status', className: 'table-header--small' }
];
