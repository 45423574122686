import { useEffect, useState, useContext } from 'react';
import ClassificationsContext from '../../common/ClassificationsContext';
import CurrentUserContext from '../../common/CurrentUserContext';

export const RESTRICTED_ACCESS_BEHAVIOUR = 'restricted_access';

/**
 * Checks whether the given category has the restricted access behaviour or not
 */
const checkForRestrictedAccess = cat =>
  cat.behaviours.includes(RESTRICTED_ACCESS_BEHAVIOUR);

/**
 * Filter a given collection of categories to exclude any that are restricted access.
 * This function should be used if there is a contextual list of categories other
 * than the top-level one. Otherwise, usePermittedActionCategories should be
 * used so that the unpermitted categories are never surfaced in your component to
 * prevent accidental use.
 */
export const filterActionCategories = actionCategories =>
  actionCategories
    .map(cat => {
      // We can't reassign attributes on an RxDocument, so we turn it into a JS object
      const safeCat = { ...(cat.isInstanceOfRxDocument ? cat.toJSON() : cat) };

      // Does the parent category have this behaviour
      if (checkForRestrictedAccess(safeCat)) {
        return false;
      }

      // Remove any children that have this behaviour
      safeCat.children = safeCat.children.filter(
        subcat => !checkForRestrictedAccess(subcat)
      );

      // And exclude this category if all the children are restricted
      if (safeCat.children.length === 0) {
        return false;
      }

      return safeCat;
    })
    // Remove any categories for which we returned false
    .filter(val => val);

/**
 * Provides a pre-filtered list of action categories that only includes
 * categories that are available to the current user. Use this in components
 * that allow a user to assign a category to an action, where restricted
 * categories should not be available.
 */
export default function usePermittedActionCategories() {
  const { actionCategories } = useContext(ClassificationsContext);
  const { user } = useContext(CurrentUserContext);
  const [permittedActionCategories, setPermittedActionCategories] = useState(
    []
  );
  useEffect(
    () =>
      user &&
      setPermittedActionCategories(
        user.admin ? actionCategories : filterActionCategories(actionCategories)
      ),
    [actionCategories, user]
  );

  return permittedActionCategories;
}
