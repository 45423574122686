import { useEffect } from 'react';

export const TITLE = 'MDC Harbours';

export default function usePageTitle(title) {
  useEffect(() => {
    document.title = `${(title && `${title} - `) || ''}${TITLE}`;
  }, [title]);

  return document.title;
}
