import { replicateGraphQL } from 'rxdb/plugins/replication-graphql';
import { ENDPOINT as url, HEADERS as headers } from '../../graphql/constants';
import {
  replicationWithErrorReporting,
  replicationWithLiveInterval,
  retryTime
} from '../helpers';
import { wrapDocumentsWithCheckpoint } from '../helpers';

const defaultPullQueryBuilder = ({ field, hasChildren = true }) => {
  return priorCheckpoint => {
    const checkpoint = priorCheckpoint || { id: '', updatedAt: 0 };

    const query = `
      query ($updatedSince: ISO8601DateTime) {
        ${field}(updatedSince: $updatedSince) {
          id
          name
          behaviours
          updatedAt
          ${hasChildren ? 'children { id name behaviours }' : ''}
        }
      }
    `;

    return {
      query,
      variables: {
        updatedSince: checkpoint.updatedAt
          ? new Date(checkpoint.updatedAt).toISOString()
          : null
      }
    };
  };
};

const convertTaxonomyTypeToDocument = taxonomy => {
  taxonomy.updatedAt = new Date(taxonomy.updatedAt).getTime();

  return taxonomy;
};

export default ({
  collection,
  field,
  hasChildren,
  dataPath,
  queryBuilder = defaultPullQueryBuilder
}) => {
  return replicationWithErrorReporting(
    replicationWithLiveInterval(
      replicateGraphQL({
        collection,
        url: { http: url },
        headers,
        pull: {
          queryBuilder: queryBuilder({ field, hasChildren }),
          dataPath: dataPath ?? `data.${field}`,
          batchSize: 100,
          responseModifier: wrapDocumentsWithCheckpoint,
          modifier: convertTaxonomyTypeToDocument
        },
        deletedField: 'deactivated_at',
        live: process.env.RAILS_ENV === 'test',
        retryTime
      })
    )
  );
};
