import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form-html5-validation';
import {
  options as checkIntervalOptions,
  parseOption as parseCheckIntervalOption,
  formatOption as formatCheckIntervalOption
} from './data/check-interval-options';
import TaxonomySelect from '../forms/classification-select';
import { FormGroupField } from '../forms/field';
import LocationSelect from '../forms/location-select';
import TaxonomyChildSelect from '../forms/taxonomy-child-select';

const NavigationAidFields = ({
  operationalBounds,
  defaultPosition,
  operationalAreas,
  assetCategories,
  form
}) => {
  return (
    <>
      <fieldset className="form-section panel summary">
        <legend>
          <h3>AtoN details</h3>
        </legend>

        <label htmlFor="identificationNumber">Almanac Number</label>
        <FormGroupField
          component="input"
          type="text"
          id="identificationNumber"
          name="identificationNumber"
        />

        <label htmlFor="name">AtoN Name</label>
        <FormGroupField
          component="input"
          required
          type="text"
          id="name"
          name="name"
        />
      </fieldset>

      <fieldset className="form-section panel summary">
        <legend>
          <h3>Location</h3>
        </legend>
        <LocationSelect
          form={form}
          bounds={operationalBounds}
          defaultPosition={defaultPosition}
        />
        <TaxonomySelect
          id="parentOperationalArea"
          name="parentOperationalArea"
          includeBlank
          required
          label="Operational area"
          collection={operationalAreas}
        />
        <TaxonomyChildSelect
          parents={operationalAreas}
          parentAttribute="parentOperationalArea"
          childAttribute="operationalArea"
          label="Operational sub-area"
        />

        <label htmlFor="locationDescription">Site description</label>
        <FormGroupField
          component="textarea"
          id="locationDescription"
          name="locationDescription"
        />

        <label htmlFor="category">Category</label>
        <FormGroupField
          component="input"
          type="number"
          min={1}
          max={3}
          id="atonCategory"
          name="atonCategory"
        />

        <TaxonomyChildSelect
          parents={assetCategories}
          parentAttribute="category"
          childAttribute="subcategory"
          required
          label="AtoN Type"
        />
      </fieldset>

      <fieldset className="form-section panel summary">
        <legend>
          <h3>Routine check period</h3>
        </legend>

        <div className="row wrappable-input-group">
          {checkIntervalOptions.map(({ months, label }) => (
            <div
              key={months}
              className="input-group panel panel--bordered panel--compact wrappable-input"
            >
              <Field
                name="checkIntervalMonths"
                type="radio"
                value={months ? months.toString() : ''}
                format={formatCheckIntervalOption}
                parse={parseCheckIntervalOption}
              >
                {fieldProps => {
                  return (
                    <>
                      <label className="radio-label margin-bottom-0 flex-child-grow">
                        <span>{label}</span>
                        <input {...fieldProps.input} />
                        <span className="radio" />
                      </label>
                    </>
                  );
                }}
              </Field>
            </div>
          ))}
        </div>
      </fieldset>

      <fieldset className="form-section panel summary">
        <label htmlFor="battery_type">Battery Type</label>
        <FormGroupField
          component="input"
          type="text"
          id="battery_type"
          name="battery_type"
        />

        <label htmlFor="battery_id">Battery ID</label>
        <FormGroupField
          component="input"
          type="text"
          id="battery_id"
          name="battery_id"
        />

        <label htmlFor="light_type">Light Type</label>
        <FormGroupField
          component="input"
          type="text"
          id="light_type"
          name="light_type"
        />

        <label htmlFor="light_id">Light ID</label>
        <FormGroupField
          component="input"
          type="text"
          id="light_id"
          name="light_id"
        />
      </fieldset>
    </>
  );
};

NavigationAidFields.propTypes = {
  operationalAreas: PropTypes.arrayOf(PropTypes.object),
  assetCategories: PropTypes.arrayOf(PropTypes.object),
  form: PropTypes.object.isRequired,
  operationalBounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  defaultPosition: PropTypes.arrayOf(PropTypes.number)
};

export default NavigationAidFields;
