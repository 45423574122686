import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { createRxDatabase } from 'rxdb';
import initializeDatabase, {
  databaseOpts,
  initCollections
} from './db/initialize';

const saveJsonFile = (filename, json) => {
  const link = document.createElement('a');
  const blob = new Blob([JSON.stringify(json)], { type: 'application/json' });
  link.setAttribute('target', '_blank');
  link.setAttribute('href', URL.createObjectURL(blob));
  link.setAttribute('download', filename);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const StorageDiagnostics = () => {
  const [storageQuota, setStorageQuota] = useState();

  useEffect(() => {
    navigator.storage &&
      navigator.storage.estimate &&
      navigator.storage.estimate().then(setStorageQuota);

    return undefined;
  }, []);

  const clearStorage = useCallback(async () => {
    if (
      confirm(
        'Are you sure you want to clear storage? This will delete any unsynched data!'
      )
    ) {
      window.localStorage.clear();
      for (let cacheName of await window.caches.keys()) {
        window.caches.delete(cacheName);
      }
      (await window.indexedDB.databases()).forEach(db =>
        window.indexedDB.deleteDatabase(db.name)
      );

      window.location.reload();
    }
  }, []);

  if (!storageQuota) {
    return 'Loading storage diagnostics...';
  }

  return (
    <>
      <h2>Storage</h2>
      <details>
        <summary>
          <strong>
            Used {Math.round(storageQuota.usage / 1024 / 1024, 2)}mb of{' '}
            {Math.round(storageQuota.quota / 1024 / 1024, 2)}mb
          </strong>
        </summary>
        <pre>{JSON.stringify(storageQuota, null, 2)}</pre>
      </details>
      <br />
      <button
        type="button"
        id="button-clear-storage"
        onClick={clearStorage}
        className="button primary"
      >
        Clear Storage
      </button>
    </>
  );
};

const ServiceWorkerDiagnostics = () => {
  const [registration, setRegistration] = useState();

  useEffect(
    () =>
      window.Harbours.serviceWorker.current().then(setRegistration) &&
      undefined,
    []
  );
  function stopServiceWorker() {
    window.Harbours.serviceWorker.stop().then(() => window.location.reload());
  }

  function updateServiceWorker() {
    registration
      .update()
      .then(() => alert('Service worker updated successfully.'))
      .catch(() => alert('Service worker failed to update.'));
  }

  return (
    <>
      <h2>Service Worker</h2>

      <p>
        Service worker is{' '}
        <strong>{(registration && 'running') || 'not running'}</strong>
      </p>

      <p>
        <small>
          Unregisters the active service worker. To register a new service
          worker, visit any page of the application as a logged in user
        </small>
        <br />
        <br />
        <button
          className="button primary"
          type="button"
          onClick={stopServiceWorker}
        >
          Stop service worker
        </button>{' '}
        {registration && (
          <button
            className="button primary"
            type="button"
            onClick={updateServiceWorker}
          >
            Update service worker
          </button>
        )}
      </p>
    </>
  );
};

const NetworkDiagnostics = () => {
  const [averageResponseTimes, setAverageResponseTimes] = useState([]);

  useEffect(() => {
    document.addEventListener('harbours.networkConnectivity', ({ detail }) =>
      setAverageResponseTimes(responseTimes =>
        responseTimes.concat({
          asAt: new Date(),
          responseTime: detail.averageResponseTime
        })
      )
    );
  }, []);

  const dumpNetworkInformation = useCallback(() => {
    saveJsonFile('harboursNetworkDiagnostics.json', averageResponseTimes);
  }, [averageResponseTimes]);

  return (
    <>
      <h2>Network</h2>

      {averageResponseTimes.length > 0 && (
        <strong>
          Rolling average:{' '}
          {Math.round(
            averageResponseTimes[averageResponseTimes.length - 1].responseTime
          )}
          ms
        </strong>
      )}

      <table>
        <thead>
          <tr>
            <th>Time</th>
            <th>Response time (ms)</th>
          </tr>
        </thead>
        <tbody>
          {averageResponseTimes.map((data, idx) => (
            <tr key={`network-info-response-time-${idx}`}>
              <td>{data.asAt.toISOString()}</td>
              <td>{Math.round(data.responseTime, 2)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {averageResponseTimes.length > 0 && (
        <>
          <button
            onClick={dumpNetworkInformation}
            className="button primary"
            type="button"
          >
            Export network diagnostics
          </button>
          <br />
          <small>
            Exports the table data above for diagnosis by the support team.
            Please do this if requested, or if you are experiencing problems
            with offline/online mode triggering.
          </small>
        </>
      )}
    </>
  );
};

const ManageReplicationState = ({ replicationState }) => {
  const [replicationActive, setReplicationActive] = useState(false);
  useEffect(
    () => replicationState.active$.subscribe(setReplicationActive),
    [replicationState]
  );

  const forceSync = useCallback(() => {
    replicationState.reSync();
  }, [replicationState]);

  if (replicationActive) {
    return (
      <button className="button clear padded" type="button" disabled>
        Synching {replicationState.collection.name}...
      </button>
    );
  }

  return (
    <button className="button clear padded" type="button" onClick={forceSync}>
      Sync {replicationState.collection.name}
    </button>
  );
};

ManageReplicationState.propTypes = {
  replicationState: PropTypes.object.isRequired
};

const DatabaseDiagnostics = () => {
  const [database, setDatabase] = useState();
  const [databaseError, setDatabaseError] = useState();
  const replicationStates =
    (window.Harbours.database && window.Harbours.database.replicationStates) ||
    [];

  useEffect(
    () =>
      // DB3 means that the collection already exists, which we can ignore since we
      // EXPECT this if we've initialized the database before.
      initializeDatabase()
        .then(setDatabase)
        .catch(err => err.code !== 'DB3' && setDatabaseError(err)) && undefined,
    []
  );

  const dropDatabase = useCallback(
    () => database && database.remove().then(() => alert('Database dropped!')),
    [database]
  );

  const importDatabase = useCallback(
    async evt => {
      const file = evt.target.files[0];
      // Read file as JSON and import it into the database
      const json = JSON.parse(await file.text());
      await database.destroy();
      const newDatabase = await createRxDatabase(databaseOpts);
      await initCollections(newDatabase);
      await newDatabase.importJSON(json);
      setDatabase(newDatabase);

      alert('Database imported!');
      window.location.reload();
    },
    [database]
  );

  const dumpDatabase = useCallback(
    () =>
      database &&
      database.exportJSON().then(json => saveJsonFile('harboursdb.json', json)),
    [database]
  );

  return (
    <>
      <h2>Database</h2>
      <p>
        Database is named <strong>{(database && database.name) || '-'}</strong>
      </p>
      {databaseError && (
        <>
          <strong>
            Database reported the following errors during initialisation:
          </strong>
          <pre>{JSON.stringify(databaseError.message, null, 2)}</pre>
          <p>
            Try the <a href="#button-clear-storage">Clear Storage</a> button
            above to clear everything out and start again.
          </p>
        </>
      )}

      {database && (
        <>
          <p>
            These functions allow the Harbours application database to be
            managed:
          </p>

          {replicationStates.map(replicationState => (
            <ManageReplicationState
              replicationState={replicationState}
              key={replicationState.collection.name}
            />
          ))}
          <p>
            <button
              className="button primary"
              type="button"
              onClick={dropDatabase}
            >
              Drop database
            </button>
            <br />
            <small>
              Remove the database and start from scratch. An active internet
              connection is required to set up the database.
            </small>
          </p>
          <p>
            <label className="button primary" htmlFor="import_database_file">
              Import database
            </label>
            <input
              type="file"
              className="hide"
              onChange={importDatabase}
              id="import_database_file"
            />
            <br />
            <small>
              This function imports a database from a file. Please only use this
              function if requested by a support team member.
              <br />
              Note that this will drop your current database.
            </small>
          </p>
          <p>
            <button
              className="button primary"
              type="button"
              onClick={dumpDatabase}
            >
              Export database
            </button>
            <br />
            <small>
              Export the database content to a file. Please only use this
              function if requested by a support team member.
            </small>
          </p>
        </>
      )}
    </>
  );
};

export default () => (
  <div className="page page--white-background grid-x grid-margin-x">
    <div className="cell small-12">
      <h1>Application diagnostics</h1>
      <StorageDiagnostics />
      <ServiceWorkerDiagnostics />
      <DatabaseDiagnostics />
      <NetworkDiagnostics />
    </div>
  </div>
);
