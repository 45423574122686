export const transformStringToArray = (str, regexp) => {
  let result = [];
  let match;
  while ((match = regexp.exec(str))) {
    if (match.length >= 2) {
      result.push(match.slice(1));
    } else {
      result.push(match[0]);
    }
  }

  return result;
};
