import { pick } from 'lodash-es';
import { replicateGraphQL } from 'rxdb/plugins/replication-graphql';
import { HEADERS as headers, ENDPOINT as url } from '../../graphql/constants';
import {
  retryTime,
  removeOptionalProperties,
  replicationWithErrorReporting,
  wrapDocumentsWithCheckpoint,
  replicationWithLiveInterval,
  replicationPushResponseModifier
} from '../helpers';

const ActionTypeFragment = `
  id sequenceNumber createdAt archivedAt
  updatedAt datetime instructions urgency marineFarmSiteNumber mooringNumber status closedAt
  category { id }
  subcategory { id }
  requestedBy { id }
  assignedTo { id }
  parentOperationalArea { id }
  operationalArea { id }
  asset { id }
  location { lat lng srid }
  incident { id }`;

const pushMutationBuilder = () => {
  return ([{ newDocumentState: action }]) => {
    delete action.archivedAt; // Deleted field gets re-added by RxDB

    const mutation = `
      mutation ($action: ActionSetInput!) {
      setAction(input: $action) {
          action {
            ${ActionTypeFragment}
          }
          errors {
            message
            path
          }
        }
      }
    `;

    return {
      query: mutation,
      variables: { action: action }
    };
  };
};

const pullQueryBuilder = () => {
  return (priorCheckpoint, limit) => {
    const checkpoint = priorCheckpoint || { id: '', updatedAt: 0 };

    console.log(
      `Fetching actions at ${new Date()}, last modified document is ${
        checkpoint.updatedAt && new Date(checkpoint.updatedAt)
      }`
    );
    const query = `
      query ($filters: [Filter!], $limit: Int!) {
        actionCollection(filters: $filters, sort: { dir: ASC, order: "updatedAt" }, limit: $limit) {
          collection {
            ${ActionTypeFragment}
          }
        }
      }
    `;

    return {
      query,
      variables: {
        limit: limit,
        filters: [
          {
            identifier: 'updated_since',
            value: checkpoint.updatedAt
              ? new Date(checkpoint.updatedAt).toISOString()
              : ''
          },
          {
            identifier: 'include_archived',
            value: 'true'
          }
        ]
      }
    };
  };
};

const convertActionTypeToDocument = action => {
  const doc = action;

  let {
    operationalArea,
    parentOperationalArea,
    category,
    subcategory,
    asset,
    incident,
    requestedBy,
    assignedTo
  } = doc;

  delete doc.asset;

  doc.updatedAt = new Date(doc.updatedAt).getTime();
  doc.createdAt = new Date(doc.createdAt).getTime();
  doc.closedAt = new Date(doc.closedAt).getTime();
  doc.datetime = new Date(doc.datetime).getTime();
  doc.operationalArea = operationalArea && operationalArea.id;
  doc.parentOperationalArea = parentOperationalArea && parentOperationalArea.id;
  doc.category = category && category.id;
  doc.subcategory = subcategory && subcategory.id;
  doc.asset = asset && asset.id;
  doc.requestedBy = requestedBy && requestedBy.id;
  doc.assignedTo = assignedTo && assignedTo.id;
  doc.incident = incident && incident.id;

  removeOptionalProperties(doc);

  return doc;
};

const convertDocumentToActionType = doc => {
  if (doc.archivedAt) {
    return null;
  }

  return Object.assign(
    {},
    pick(doc, [
      'id',
      'instructions',
      'marineFarmSiteNumber',
      'mooringNumber',
      'urgency'
    ]),
    {
      datetime: new Date(doc.datetime).toISOString(),
      closedAt: doc.closedAt ? new Date(doc.closedAt).toISOString() : null,
      operationalAreaId: doc.operationalArea,
      assignedToId: doc.assignedTo,
      incidentId: doc.incident,
      location: doc.location
        ? [doc.location.lng.toString(), doc.location.lat.toString()]
        : null,
      assetId: doc.asset,
      requestedById: doc.requestedBy,
      categoryId: doc.subcategory
    }
  );
};

export default ({ collection }) =>
  replicationWithErrorReporting(
    replicationWithLiveInterval(
      replicateGraphQL({
        collection,
        url: {
          http: url
        },
        headers,
        pull: {
          queryBuilder: pullQueryBuilder(),
          dataPath: 'data.actionCollection.collection',
          batchSize: 100,
          responseModifier: wrapDocumentsWithCheckpoint,
          modifier: convertActionTypeToDocument
        },
        push: {
          queryBuilder: pushMutationBuilder(),
          dataPath: 'setAction',
          batchSize: 1,
          responseModifier: replicationPushResponseModifier,
          // This modifier processes RxDB document data BEFORE the data is pushed
          modifier: convertDocumentToActionType
        },
        deletedField: 'archivedAt',
        live: true,
        retryTime: retryTime
      })
    )
  );
