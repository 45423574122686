import { replicateGraphQL } from 'rxdb/plugins/replication-graphql';
import { ENDPOINT as url, HEADERS as headers } from '../../graphql/constants';
import {
  retryTime,
  replicationWithErrorReporting,
  wrapDocumentsWithCheckpoint,
  replicationWithLiveInterval
} from '../helpers';

export const withInactiveUsersPullQueryBuilder = () => {
  return priorCheckpoint => {
    const checkpoint = priorCheckpoint || { id: '', updatedAt: 0 };

    const query = `
      query ($updatedSince: ISO8601DateTime) {
        users(updatedSince: $updatedSince, withInactive: true, onlyStaff: false) {
          id
          name
          updatedAt
          active
          roles
        }
      }
    `;

    return {
      query,
      variables: {
        updatedSince: checkpoint.updatedAt
          ? new Date(checkpoint.updatedAt).toISOString()
          : null
      }
    };
  };
};

const convertUserTypeToDocument = user => {
  user.updatedAt = new Date(user.updatedAt).getTime();
  user.active = user.active === 'true';
  user.admin = user.roles && user.roles.includes('admin');
  user.staff = user.roles && user.roles.includes('staff');

  delete user.roles;

  return user;
};

export default ({ collection }) =>
  replicationWithErrorReporting(
    replicationWithLiveInterval(
      replicateGraphQL({
        collection,
        url: { http: url },
        headers,
        pull: {
          queryBuilder: withInactiveUsersPullQueryBuilder(),
          batchSize: 100,
          responseModifier: wrapDocumentsWithCheckpoint,
          modifier: convertUserTypeToDocument
        },
        deletedField: 'deactivated_at',
        live: process.env.RAILS_ENV === 'test',
        retryTime
      })
    )
  );
