import { orderBy } from 'lodash-es';
import React, { useEffect, useState, useContext } from 'react';
import { useRxData } from 'rxdb-hooks';
import { headers } from './data/actions-headers';
import getActionDocumentProperties from './data/get-action-document-properties';
import ApplicationConfigContext from '../common/application-config-context';
import DataTable from '../dataTable/data-table';

export default () => {
  const [tableData, setTableData] = useState([]);
  const { offlineRecordsLimit } = useContext(ApplicationConfigContext);
  const { result: actions, isLoading } = useRxData('actions', collection =>
    collection.find()
  );

  const unsortableHeaders = [...headers];
  unsortableHeaders.forEach(header => (header.sortable = false));

  useEffect(() => {
    actions &&
      Promise.all(
        orderBy(actions, 'sequenceNumber', 'desc')
          .map(i => getActionDocumentProperties(i))
          .slice(0, offlineRecordsLimit)
      ).then(setTableData);
  }, [actions, offlineRecordsLimit]);

  if (isLoading || !tableData.length) {
    return 'Loading...';
  }

  return (
    <DataTable
      resource={'actions'}
      tableData={tableData}
      headers={unsortableHeaders}
    />
  );
};
