import { pick } from 'lodash-es';
import { replicateGraphQL } from 'rxdb/plugins/replication-graphql';
import { HEADERS as headers, ENDPOINT as url } from '../../graphql/constants';
import {
  retryTime,
  removeOptionalProperties,
  replicationWithErrorReporting,
  wrapDocumentsWithCheckpoint,
  replicationWithLiveInterval,
  replicationPushResponseModifier
} from '../helpers';

const pushMutationBuilder = () => {
  return ([{ newDocumentState: document }]) => {
    const mutation = `
      mutation ($incident: IncidentSetInput!) {
      setIncident(input: $incident) {
          incident {
            id
          }
          errors {
            message
            path
          }
        }
      }
    `;

    return {
      query: mutation,
      variables: { incident: document }
    };
  };
};

const pullQueryBuilder = () => {
  return (priorCheckpoint, limit) => {
    const checkpoint = priorCheckpoint || { id: '', updatedAt: 0 };

    console.log(
      `Fetching incidents at ${new Date()}, last modified document is ${
        checkpoint.updatedAt && new Date(checkpoint.updatedAt)
      }`
    );

    const query = `
      query ($filters: [Filter!], $limit: Int!) {
        incidentCollection(filters: $filters, sort: { dir: ASC, order: "updatedAt" }, limit: $limit) {
          collection {
            id createdAt updatedAt archivedAt datetime description critical breachNoticeServed
            bodyCamAttachments logBookAttachments deviceAttachments status closedAt
            sequenceNumber
            incidentCategory { id }
            parentIncidentCategory { id }
            user { id }
            parentOperationalArea { id }
            operationalArea { id }
            location { lat lng srid }
            harboursUserGroup { id }
            harbourmasterVessel { id }
            parentHarboursUserGroup { id }
            involvedIndividuals { id firstNames middleNames lastNames dateOfBirth phoneNumber postalAddress email }
            involvedVessels { id name description }
          }
        }
      }
    `;

    return {
      query,
      variables: {
        limit: limit,
        filters: [
          {
            identifier: 'updated_since',
            value: checkpoint.updatedAt
              ? new Date(checkpoint.updatedAt).toISOString()
              : ''
          },
          {
            identifier: 'include_archived',
            value: 'true'
          }
        ]
      }
    };
  };
};

const convertDocumentIntoIncidentType = doc => {
  // Don't try and push an archived document
  if (doc.archivedAt || doc._deleted) {
    return null;
  }

  const result = Object.assign(
    {},
    pick(doc, [
      'id',
      'description',
      'breachNoticeServed',
      'bodyCamAttachments',
      'logBookAttachments',
      'deviceAttachments',
      'critical',
      'involvedIndividuals',
      'involvedVessels'
    ]),
    {
      datetime: new Date(doc.datetime).toISOString(),
      operationalAreaId: doc.operationalArea,
      userId: doc.user,
      location: doc.location
        ? [doc.location.lng.toString(), doc.location.lat.toString()]
        : null,
      harboursUserGroupId: doc.harboursUserGroup,
      incidentCategoryId: doc.subcategory,
      harbourmasterVesselId: doc.harbourmasterVessel
    }
  );

  return result;
};

const convertIncidentTypeToDocument = incident => {
  let {
    operationalArea,
    parentOperationalArea,
    incidentCategory,
    harbourmasterVessel,
    parentIncidentCategory,
    user,
    archivedAt,
    harboursUserGroup,
    parentHarboursUserGroup
  } = incident;

  // If we pull a document that is archived, flag it as deleted
  if (archivedAt) {
    incident._deleted = true;
  }

  delete incident.archivedAt;

  incident.updatedAt = new Date(incident.updatedAt).getTime();
  incident.createdAt = new Date(incident.createdAt).getTime();
  incident.closedAt =
    incident.closedAt && new Date(incident.closedAt).getTime();
  incident.datetime = new Date(incident.datetime).getTime();
  incident.harbourmasterVessel = harbourmasterVessel && harbourmasterVessel.id;
  incident.operationalArea = operationalArea && operationalArea.id;
  incident.parentOperationalArea =
    parentOperationalArea && parentOperationalArea.id;
  incident.category = parentIncidentCategory && parentIncidentCategory.id;
  incident.subcategory = incidentCategory && incidentCategory.id;
  incident.parentHarboursUserGroup =
    parentHarboursUserGroup && parentHarboursUserGroup.id;
  incident.harboursUserGroup = harboursUserGroup && harboursUserGroup.id;
  incident.user = user && user.id;

  delete incident.incidentCategory;
  delete incident.parentIncidentCategory;

  removeOptionalProperties(incident);

  return incident;
};

export default ({ collection }) =>
  replicationWithErrorReporting(
    replicationWithLiveInterval(
      replicateGraphQL({
        collection,
        url: { http: url },
        headers,
        pull: {
          queryBuilder: pullQueryBuilder(),
          dataPath: 'data.incidentCollection.collection',
          batchSize: 100,
          responseModifier: wrapDocumentsWithCheckpoint,
          modifier: convertIncidentTypeToDocument
        },
        push: {
          queryBuilder: pushMutationBuilder(),
          batchSize: 1,
          dataPath: 'setIncident',
          responseModifier: replicationPushResponseModifier,
          modifier: convertDocumentIntoIncidentType
        },
        live: true,
        retryTime
      })
    )
  );
