import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import circleOk from '../../images/circle-ok.svg';

const FollowUpActionBanner = ({ action, cancel }) => {
  const [category, setCategory] = useState(null);
  useEffect(() => action.populate('category').then(setCategory), [action]);

  return (
    <div className="grid-x grid-margin-x padding-1 align-middle page page--white-background">
      <div className="cell shrink">
        <img src={circleOk} alt="" aria-hidden width="64" height="64" />
      </div>
      <div className="cell auto">
        <h1 className="margin-bottom-0">Follow up Action</h1>
        <p className="margin-bottom-0">
          You have successfully completed a {category && category.name} action.
          You can now create a follow up action if needed.
        </p>
      </div>

      <div className="cell shrink">
        <button
          className="button tertiary font-bold margin-bottom-0"
          type="button"
          onClick={cancel}
        >
          Skip this Action
        </button>
      </div>
    </div>
  );
};

FollowUpActionBanner.propTypes = {
  action: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired
};

export default FollowUpActionBanner;
