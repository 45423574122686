import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import tableCog from '../../../images/table-cog.svg';

export default function getAtonProperties(navigationAid) {
  const {
    id,
    identificationNumber,
    name,
    metadata,
    operationalArea,
    subcategory,
    actionCollection,
    incidentCollection
  } = navigationAid;

  const lastVisit = actionCollection.collection[0] || {};

  const lastVisitCompleted = lastVisit.closedAt;
  const hasIncidents =
    incidentCollection &&
    incidentCollection.pageInfo &&
    incidentCollection.pageInfo.count > 0
      ? 'Yes'
      : 'No';

  return [
    { value: id, skip: true },
    { value: identificationNumber },
    { value: name },
    { value: subcategory?.name },
    { value: operationalArea?.name },
    { value: metadata.category },
    { value: hasIncidents },
    { value: lastVisitWithControls(lastVisitCompleted, id) }
  ];
}
function lastVisitWithControls(lastVisitCompleted, id) {
  return (
    <div className="grid-x align-middle align-justify">
      <div>
        {lastVisitCompleted
          ? format(new Date(lastVisitCompleted), 'dd/MM/yyyy HH:mm')
          : 'N/A'}
      </div>
      <ul
        id={`dropdown-menu-${id}`}
        className="dropdown menu margin-left-auto"
        data-dropdown-menu
        data-alignment="right"
      >
        <li>
          <Link to={`/navigation_aids/${id}`}>
            <img src={tableCog} className="icon" alt="options" />
          </Link>
          <ul className="menu">
            <li>
              <Link to={`/actions/new?navigationAidId=${id}`}>New Action</Link>
            </li>
            <li>
              <Link to={`/navigation_aids/${id}#add_log_entry`}>Log Entry</Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
