import markdownitMentions from '@quartzy/markdown-it-mentions';
import MarkdownIt from 'markdown-it';

const markdown = new MarkdownIt({
  typographer: true,
  linkify: true,
  breaks: true
})
  .use(markdownitMentions)
  .disable(['code']);

markdown.renderer.rules.mention = (tokens, idx) =>
  `<span class="mentioned">${tokens[idx].mention.label}</span>`;

// From https://github.com/markdown-it/markdown-it/blob/master/docs/architecture.md#renderer
const defaultRender =
  markdown.renderer.rules.link_open ||
  /* eslint-disable consistent-this */
  function (tokens, idx, options, _env, self) {
    return self.renderToken(tokens, idx, options);
  };

// eslint-disable-next-line camelcase
markdown.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  /* eslint-enable consistent-this */

  tokens[idx].attrPush(['target', '_blank']); // add new attribute
  tokens[idx].attrPush(['rel', 'noopener noreferrer']); // add new attribute

  // pass token to default renderer.
  return defaultRender(tokens, idx, options, env, self);
};

const formatContent = content => markdown.render(content);
const formatNode = node => {
  node.innerHTML = formatContent(node.innerHTML);
  node.dataset.formatted = true;

  return node;
};

document.addEventListener('DOMContentLoaded', () => {
  document
    .querySelectorAll('[data-format]:not([data-formatted]')
    .forEach(formatNode);
});

export { formatContent, formatNode };
