// These options are available for navigation aids to specify their check interval.
// Navigation aids support any positive integer as a check interval, or null, which is
// what is mapped here.
// In the future, these options may be moved into the backend as an association with
// ScheduledCheckInterval. This would restrict the options, but allow them to be constrained
// conditionally, admin-managed and/or reported on (e.g. all AtoNs on an annual check).
export const options = [
  { months: 3, label: '3 months' },
  { months: 6, label: '6 months' },
  { months: 12, label: '12 months', default: true },
  { months: null, label: 'None' }
];

/** Convert a month value option to it's input value form as a string */
export const formatOption = option => (option ? option.toString() : '');

/** Convert a month form value option to it's params value as a number or null */
export const parseOption = option => (option ? Number(option) : null);

/** Find the first option that has the 'default' key set to true */
export const defaultOption = options.find(opt => opt.default);
