import { isAfter } from 'date-fns';
import { diff } from 'deep-object-diff';
import { FORM_ERROR } from 'final-form';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import MentionableInput from './mention-input';
import calendarIcon from '../../images/calendar.svg';
import clockIcon from '../../images/clock.svg';
import CurrentUserContext from '../common/CurrentUserContext';
import leftPad from '../common/left-pad';
import { composeValidators, mustNotBeInTheFuture } from '../common/validators';
import AutosaveSpy from '../forms/autosave/AutosaveSpy';
import AutosaveWarning from '../forms/autosave/AutosaveWarning';
import {
  buildStorageKey,
  loadAutosaveItem,
  removeAutosaveItem
} from '../forms/autosave/autosave-helpers';
import LogEntryDecorator from '../forms/decorators/LogEntryDecorator';

const PropagatedField = () => {
  return (
    <div className="small-5">
      <Field
        name="propagated"
        id="propagated"
        label="Include in incident log?"
        component="input"
        type="checkbox"
      />
      <label htmlFor="propagated">Include in incident log?</label>
    </div>
  );
};

const Update = ({ users, onSuccess, canBePropagated, logEntry }) => {
  const storageKey = buildStorageKey('log-entry-update');
  const {
    user: { id: currentUserId }
  } = useContext(CurrentUserContext);

  const onSubmit = async values => {
    let submissionErrors = {};

    let params = {
      propagated: values.propagated,
      content: values.content,
      logTime: values.logTime,
      editor: values.editor
    };

    return logEntry
      .incrementalPatch(params)
      .then(() => {
        removeAutosaveItem(storageKey);

        return onSuccess();
      })
      .catch(err => {
        submissionErrors[[FORM_ERROR]] = err.message || err;

        return submissionErrors;
      });
  };

  const { id, content, propagated, editor, logTime, updatedAt } = logEntry;

  const values = useMemo(() => {
    const now = new Date(logTime);

    return {
      date: `${now.getFullYear()}-${leftPad(now.getMonth() + 1)}-${leftPad(
        now.getDate()
      )}`,
      time: `${leftPad(now.getHours())}:${leftPad(now.getMinutes())}`,
      id,
      editor: editor || currentUserId,
      content,
      propagated
    };
  }, [content, logTime, id, editor, currentUserId, propagated]);

  const [warning, setWarning] = useState(false);
  const storedData = loadAutosaveItem(storageKey)
    ? JSON.parse(loadAutosaveItem(storageKey))
    : null;

  const storedForm = storedData && storedData.form;
  const initialValues = storedForm ? storedForm : values;

  useEffect(() => {
    if (!storedForm) {
      return;
    }
    const JSONvalues = JSON.stringify(values);
    const changes = diff(storedForm, JSON.parse(JSONvalues));

    if (!isEmpty(changes)) {
      isAfter(new Date(updatedAt), new Date(storedData.storedAt))
        ? setWarning('updatedSince')
        : setWarning('saved');
    }
  }, [storedForm, storedData, updatedAt, values]);

  return (
    <div className="page page--white-background">
      <div className="row row--divider">
        <h1>Edit Log Entry</h1>
      </div>
      <div className="grid-x grid-padding-x row row--spaced margin-top-2">
        <div className="cell auto">
          <div className="small-8 large-9 panel summary large-offset-1">
            <div className="margin-top-1 margin-bottom-2">
              <Form
                initialValues={initialValues}
                onSubmit={useCallback(onSubmit, [
                  logEntry,
                  onSuccess,
                  storageKey
                ])}
                validateOnBlur
              >
                {({ handleSubmit, submitting, submitError, form }) => {
                  return (
                    <form
                      onSubmit={handleSubmit}
                      className="grid-x align-center"
                    >
                      <AutosaveSpy
                        storageKey={storageKey}
                        DecoratorComponent={LogEntryDecorator}
                      />
                      <AutosaveWarning
                        storageKey={storageKey}
                        warning={warning}
                        setWarning={setWarning}
                        form={form}
                      />
                      <div className="cell small-auto large-7">
                        {submitError && (
                          <div className="alert alert-danger">
                            {submitError}
                          </div>
                        )}
                        <div className="row grid-margin-x">
                          <div className="block--6">
                            <label htmlFor="date">Date of Log entry</label>
                            <div className="input-group">
                              <Field
                                required
                                component="input"
                                id="date"
                                className="input-group-field"
                                validate={composeValidators(
                                  mustNotBeInTheFuture
                                )}
                                name="date"
                                type="date"
                              />
                              <div className="input-group-label">
                                <img
                                  alt="Calendar"
                                  style={{ width: '0.9rem' }}
                                  src={calendarIcon}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="block--6">
                            <label htmlFor="time">Time of Log entry</label>
                            <div className="input-group">
                              <Field
                                required
                                component="input"
                                id="time"
                                className="input-group-field"
                                name="time"
                                type="time"
                              />
                              <div className="input-group-label">
                                <img
                                  alt="Clock"
                                  style={{ width: '0.9rem' }}
                                  src={clockIcon}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <br />
                        <Field
                          name="content"
                          component={MentionableInput}
                          users={users}
                          required
                        />
                        {canBePropagated && <PropagatedField />}
                        <div className="row grid-x">
                          <div className="cell auto" />
                          <div className="cell shrink">
                            <button
                              className="button primary"
                              type="submit"
                              disabled={submitting}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Update.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  onSuccess: PropTypes.func.isRequired,
  canBePropagated: PropTypes.bool,
  logEntry: PropTypes.object.isRequired
};

export default Update;
