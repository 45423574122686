import PropTypes from 'prop-types';
import React from 'react';
import 'rxdb-hooks';
import { useRxData } from 'rxdb-hooks';
import useRxDocument from './hooks/useRxDocument';
import { ATON_CATEGORY_NAME } from '../db/schemas/navigationAid';

const initialContext = {
  harbourUserGroups: [],
  incidentCategories: [],
  operationalAreas: [],
  actionCategories: [],
  harbourmasterVessels: [],
  assetCategories: [],
  nonAtonAssetCategories: [],
  atonCategories: [],
  users: []
};

const Context = React.createContext(initialContext);

const Provider = ({ children }) => {
  const useTaxonData = name =>
    useRxData(name, collection => collection.find()).result;

  const useNonAtonAssetCategories = () =>
    useRxData('assetcategories', collection =>
      collection.find().where({ name: { $ne: ATON_CATEGORY_NAME } })
    ).result;

  const useAtonCategories = () =>
    useRxDocument('assetcategories', ATON_CATEGORY_NAME, {
      idAttribute: 'name'
    }).result?.children || [];

  const context = {
    harbourUserGroups: useTaxonData('harboursusergroups'),
    harbourmasterVessels: useTaxonData('harbourmastervessels'),
    incidentCategories: useTaxonData('incidentcategories'),
    operationalAreas: useTaxonData('operationalareas'),
    actionCategories: useTaxonData('actioncategories'),
    assetCategories: useTaxonData('assetcategories'),
    nonAtonAssetCategories: useNonAtonAssetCategories(),
    atonCategories: useAtonCategories(),
    users: useRxData('users', collection =>
      collection.find().where({ active: true })
    ).result
  };

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

Provider.propTypes = {
  children: PropTypes.node
};

export { Context, Provider };
export default Context;
