// These options are available for assets to specify their check interval.
// Assets support any positive integer as a check interval, or null, which is
// what is mapped here.
// In the future, these options may be moved into the backend as an association with
// ScheduledCheckInterval. This would restrict the options, but allow them to be constrained
// conditionally, admin-managed and/or reported on (e.g. all assets on an annual check).
export const options = [
  { value: null, label: 'None' },
  { value: 0.25, label: '1 week' },
  { value: 1, label: '1 month' },
  { value: 3, label: '3 months' },
  { value: 6, label: '6 months' },
  { value: 12, label: '12 months' },
  { value: 24, label: '2 years' },
  { value: 36, label: '3 years' },
  { value: 48, label: '4 years' },
  { value: 60, label: '5 years' },
  { value: 120, label: '10 years' }
];
