import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import I18n from '../i18n/index.js.erb';
const queryString = require('query-string');

export default function Flash() {
  const [notice, setNotice] = useState(null);

  const location = useLocation();

  const query = queryString.parse(location.search, {
    parseNumbers: true,
    arrayFormat: 'index'
  });

  useEffect(() => {
    const serverFlash = document.getElementById('server-flash-success');
    serverFlash && serverFlash.classList.add('hide');

    setNotice(query.status_message);
  }, [query]);

  return notice ? (
    <div className="alert alert-success">{I18n.t(notice)}</div>
  ) : null;
}
