import { isEmpty } from 'lodash-es';
import capitalize from 'lodash-es/capitalize';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useRxData } from 'rxdb-hooks';
import ActionStatusControl from './ActionStatusControl';
import ApplicationConfigContext from '../common/application-config-context';
import categorySummary from '../common/categorySummary';
import FormattedContent from '../common/content/FormattedContent';
import { localize } from '../common/date-format';
import MapUI from '../common/map/index';
import checkStatus from '../common/status-checker';
import { ATON_CATEGORY_NAME } from '../db/schemas/navigationAid';
import LogEntryCollection from '../logEntries/Collection';

const ActionSummary = ({ action, showHeader = true }) => {
  const [associated, setAssociated] = useState({
    requestedBy: {},
    assignedTo: {},
    category: {},
    subcategory: {},
    incident: {}
  });

  const { overdueAfterDays } = useContext(ApplicationConfigContext);

  const logEntriesQueryConstructor = useCallback(
    collection =>
      collection.find({
        selector: {
          'source.id': { $eq: action && action.id },
          'source.type': { $eq: 'Action' }
        }
      }),
    [action]
  );
  const { result: logEntries, isFetching: logEntriesFetching } = useRxData(
    'logentries',
    logEntriesQueryConstructor
  );

  useEffect(
    () =>
      (async () => {
        if (!action) {
          return;
        }

        const parentOperationalArea = await action.populate(
          'parentOperationalArea'
        );

        const parentActionCategory = await action.populate('category');

        setAssociated({
          parentOperationalArea,
          parentActionCategory,
          actionCategory:
            parentActionCategory &&
            parentActionCategory.children.find(
              ac => ac.id === action.subcategory
            ),
          operationalArea:
            parentOperationalArea &&
            parentOperationalArea.children.find(
              oa => oa.id === action.operationalArea
            ),
          asset: await action.populate('asset'),
          incident: await action.populate('incident'),
          requestedBy: await action.populate('requestedBy'),
          assignedTo: await action.populate('assignedTo')
        });
      })() && undefined,
    [action]
  );

  const {
    result: [atonAssetCategory]
  } = useRxData('assetcategories', collection =>
    collection.findOne({ selector: { name: ATON_CATEGORY_NAME } })
  );

  return (
    <>
      <div
        className="small-8 large-9 panel summary large-offset-1 cell action-summary"
        id={`action-${action.id}`}
      >
        {showHeader && (
          <div className="grid-x cell panelheader">
            <div className="cell auto">
              <h2>
                Action {action.sequenceNumber && `#${action.sequenceNumber}`}{' '}
                Details
              </h2>
            </div>

            <div className="cell small-4 large-3 text-right">
              Opened by {associated.requestedBy && associated.requestedBy.name}
              <br />
              {action.createdAt && localize(action.createdAt)}
              {action.closedAt ? (
                <>
                  <br />
                  Closed at {localize(action.closedAt)}
                </>
              ) : null}
            </div>
          </div>
        )}

        <div className="grid-x">
          <div className="cell auto">
            <dl className="data-list">
              <div className="grid-x row">
                <dt className="cell large-3">Due date</dt>
                <dd className="cell large-9">
                  {localize(action.datetime, { format: 'defaultDate' })}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Status</dt>
                <dd className="cell large-9">
                  {action.status &&
                    capitalize(checkStatus(action, { overdueAfterDays }))}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Assigned to</dt>
                <dd className="cell large-9">
                  {associated.assignedTo && associated.assignedTo.name}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Category</dt>
                <dd className="cell large-9">
                  {associated.parentActionCategory &&
                    categorySummary(
                      associated.parentActionCategory,
                      associated.actionCategory
                    )}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Urgency</dt>
                <dd className="cell large-9">
                  {action.urgency && capitalize(action.urgency)}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Description</dt>
                <dd className="cell large-9">
                  <FormattedContent content={action.instructions} />
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Log</dt>
                <dd className="cell large-9">
                  {logEntriesFetching ? (
                    'Loading action log...'
                  ) : (
                    <LogEntryCollection
                      sourceType={'Action'}
                      sourceId={action.id}
                      canBePropagated={!isEmpty(action.incident)}
                      logEntries={logEntries.sort(
                        (a, b) => a.logTime - b.logTime
                      )}
                    />
                  )}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Mooring number</dt>
                <dd className="cell large-9">{action.mooringNumber}</dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Marine farm site number</dt>
                <dd className="cell large-9">{action.marineFarmSiteNumber}</dd>
              </div>

              {associated.incident && (
                <div className="grid-x row">
                  <dt className="cell large-3">Incident</dt>
                  <dd className="cell large-9">
                    <Link to={`/incidents/${associated.incident.id}`}>
                      {associated.incident.sequenceNumber
                        ? `Incident #${associated.incident.sequenceNumber}`
                        : 'View incident'}
                    </Link>
                  </dd>
                </div>
              )}

              {associated.asset && (
                <div className="grid-x row">
                  <dt className="cell large-3">Asset</dt>
                  <dd className="cell large-9">
                    <Link
                      to={`/${
                        atonAssetCategory !== undefined &&
                        associated.asset.category === atonAssetCategory.id
                          ? 'navigation_aids'
                          : 'manage-assets'
                      }/${associated.asset.id}`}
                    >
                      {associated.asset.name}
                    </Link>
                  </dd>
                </div>
              )}

              {action.location && (
                <>
                  <div className="grid-x row">
                    <dt className="cell large-3">Location</dt>
                    <dd className="cell large-9">
                      Lat: {action.location.lat}, Lng: {action.location.lng}
                    </dd>
                  </div>
                  <MapUI
                    zoom={10}
                    graphicLocation={[action.location.lng, action.location.lat]}
                  />
                </>
              )}

              <div className="grid-x row">
                <dt className="cell large-3">Operational area</dt>
                <dd className="cell large-9">
                  {associated.parentOperationalArea &&
                    associated.parentOperationalArea.name + ' → '}
                  {associated.operationalArea &&
                    associated.operationalArea.name}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div className="cell small-4 large-2">
        <div>
          <h5>
            <small>Add</small>
          </h5>
          <a
            href={`#log-entry-create-Action-${action.id}-content`}
            className="button tertiary expanded"
          >
            Add Log Entry to Action
          </a>
          <h5>
            <small>Manage</small>
          </h5>
          <Link
            to={`/actions/${action.id}/edit`}
            className="button secondary expanded"
          >
            Edit Action
          </Link>
          <ActionStatusControl action={action} />
          <br />
        </div>
      </div>
    </>
  );
};

ActionSummary.propTypes = {
  action: PropTypes.object.isRequired,
  showHeader: PropTypes.bool
};

export default ActionSummary;
