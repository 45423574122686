import PointSchema from './point';
import { UUID } from '../helpers';

export default {
  version: 3,
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: UUID.maxLength,
      final: true
    },
    sequenceNumber: {
      type: ['integer', 'null']
    },
    assignedTo: {
      type: ['string', 'null'],
      ref: 'users'
    },
    incident: {
      type: ['string', 'null'],
      ref: 'incidents'
    },
    asset: {
      type: ['string', 'null'],
      ref: 'assets'
    },
    category: {
      type: 'string',
      ref: 'actioncategories'
    },
    subcategory: {
      type: 'string',
      ref: 'actioncategories'
    },
    createdAt: { type: ['integer', 'null'] },
    updatedAt: { type: ['integer', 'null'] },
    datetime: { type: ['integer', 'null'] },
    instructions: { type: 'string' },
    marineFarmSiteNumber: { type: ['string', 'null'] },
    mooringNumber: { type: ['string', 'null'] },
    operationalArea: { type: ['string', 'null'], ref: 'operationalareas' },
    parentOperationalArea: {
      type: ['string', 'null'],
      ref: 'operationalareas'
    },
    location: { ...PointSchema, type: ['object', 'null'] },
    urgency: { type: 'string', default: 'normal' },
    status: { type: 'string', default: 'open' },
    closedAt: { type: ['integer', 'null'] },
    requestedBy: { type: 'string', ref: 'users' }
  },
  required: [
    'id',
    'instructions',
    'category',
    'urgency',
    'status',
    'requestedBy'
  ]
};
