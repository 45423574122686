import { isAfter } from 'date-fns';
import { diff } from 'deep-object-diff';
import { FORM_ERROR } from 'final-form';
import { merge as mergeObject } from 'lodash-es';
import camelCase from 'lodash-es/camelCase';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReviewSubmission from './ReviewSubmission';
import IncidentFields from './incident-fields';
import ClassificationsContext from '../common/ClassificationsContext';
import CurrentUserContext from '../common/CurrentUserContext';
import ApplicationConfigContext from '../common/application-config-context';
import useRxDocument from '../common/hooks/useRxDocument';
import { includeRemovedValues } from '../common/include-removed-values';
import leftPad from '../common/left-pad';
import { POINT_DEFAULT_SRID } from '../db/schemas/point';
import {
  buildStorageKey,
  loadAutosaveItem,
  removeAutosaveItem
} from '../forms/autosave/autosave-helpers';
import Wizard from '../forms/wizard';

const camelizeKeys = obj =>
  Object.keys(obj)
    .map(key => ({ [camelCase(key)]: obj[key] }))
    .reduce((a, b) => Object.assign({}, a, b));

/**
 * Maps the `dateOfBirth` field on the given `individual` to the camelized
 * `dateOfBirth` equivalent, transforming it into an object in the process.
 *
 * @param {TIndividual} individual
 *
 * @return {TIndividual & { dateOfBirth: { month: string, year: string, day: string } }}
 *
 * @template {{ dateOfBirth: string }} TIndividual
 */
const mapDateOfBirth = individual => {
  const [year, month, day] = (individual.dateOfBirth || '---').split('-');

  return {
    ...individual,
    dateOfBirth: { day, month, year }
  };
};

const buildInitialValues = (incident, currentUserId) => {
  const {
    bodyCamAttachments,
    breachNoticeServed,
    critical,
    datetime,
    description,
    deviceAttachments,
    harbourmasterVessel,
    harboursUserGroup,
    id,
    category,
    involvedIndividuals,
    involvedVessels,
    location,
    logBookAttachments,
    operationalArea,
    parentHarboursUserGroup,
    subcategory,
    parentOperationalArea,
    user
  } = incident;

  const { lat, lng } = location || {};
  const incidentDatetime = new Date(datetime);

  return {
    date: datetime
      ? `${incidentDatetime.getFullYear()}-${leftPad(
          incidentDatetime.getMonth() + 1
        )}-${leftPad(incidentDatetime.getDate())}`
      : undefined,
    time: incidentDatetime
      ? `${leftPad(incidentDatetime.getHours())}:${leftPad(
          incidentDatetime.getMinutes()
        )}`
      : undefined,
    user: user || currentUserId,
    longitude: lng || '',
    latitude: lat || '',
    involvedIndividuals: (involvedIndividuals || [])
      .map(mapDateOfBirth)
      .map(camelizeKeys),
    involvedVessels: (involvedVessels || []).map(camelizeKeys),
    critical,
    category,
    subcategory,
    harbourmasterVessel,
    operationalArea,
    harboursUserGroup,
    parentHarboursUserGroup,
    parentOperationalArea,
    breachNoticeServed,
    bodyCamAttachments,
    deviceAttachments,
    logBookAttachments,
    id,
    description
  };
};

const Edit = props => {
  const { incident, currentUserId } = props;
  const history = useHistory();
  const storageKey = buildStorageKey('incident-update');

  const dbValues = useMemo(() => {
    return buildInitialValues(incident, currentUserId);
  }, [incident, currentUserId]);

  const [warning, setWarning] = useState(false);
  const storedData = loadAutosaveItem(storageKey)
    ? JSON.parse(loadAutosaveItem(storageKey))
    : null;
  const storedForm = storedData && storedData.form;

  const initialValues = useMemo(() => {
    const storedInitialValues = storedForm || {};

    return mergeObject({}, dbValues, storedInitialValues);
  }, [dbValues, storedForm]);

  useEffect(() => {
    if (!storedForm) {
      return;
    }
    const JSONvalues = JSON.stringify(dbValues);
    const changes = diff(storedForm, JSON.parse(JSONvalues));

    if (!isEmpty(changes)) {
      isAfter(new Date(incident.updatedAt), new Date(storedData.storedAt))
        ? setWarning('updatedSince')
        : setWarning('saved');
    }
  }, [incident, dbValues, storedForm, storedData]);

  const onSubmit = async values => {
    const params = includeRemovedValues(dbValues, values);
    let submissionErrors = {};
    const { longitude, latitude } = params;
    delete params.longitude;
    delete params.latitude;
    delete params.date;
    delete params.time;

    params.datetime = new Date(params.datetime).getTime();

    if (!params.operationalArea && params.parentOperationalArea) {
      params.operationalArea = params.parentOperationalArea;
    }

    if (!params.subcategory && params.category) {
      params.subcategory = params.category;
    }

    if (!params.harboursUserGroup && params.parentHarboursUserGroup) {
      params.harboursUserGroup = params.parentHarboursUserGroup;
    }

    if (longitude && latitude) {
      params.location = {
        lng: parseFloat(longitude),
        lat: parseFloat(latitude),
        srid: POINT_DEFAULT_SRID
      };
    }

    params.involvedIndividuals.forEach(individual => {
      const { day, month, year } = individual.dateOfBirth || {};

      // if one of the parts is missing, drop everything
      if (!day || !month || !year) {
        individual.dateOfBirth = null;

        return;
      }

      // ISO8601DateTime requires day & month to be xx
      individual.dateOfBirth = [year, month, day]
        .map(s => s.padStart(2, '0'))
        .join('-');
    });

    params.involvedIndividuals = params.involvedIndividuals.filter(
      individual => individual
    );
    params.involvedVessels = params.involvedVessels.filter(vessel => vessel);

    try {
      await incident.incrementalPatch(params);
      removeAutosaveItem(storageKey);
      history.push(
        `/incidents/${incident.id}?status_message=incidents.update.success`
      );
    } catch (err) {
      // If we get validation errors, treat this as an error, but present
      // a generic message.
      console.error(err);
      submissionErrors[[FORM_ERROR]] = `
      There were some problems updating this incident.
      Please check fields and try again.
    `;
    }

    return submissionErrors;
  };

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <Wizard onSubmit={onSubmit} initialValues={initialValues}>
      <IncidentFields
        storageKey={storageKey}
        warning={warning}
        setWarning={setWarning}
        title="Update incident"
        {...props}
      />
      <ReviewSubmission title="Submit" />
    </Wizard>
  );
};

Edit.propTypes = {
  currentUserId: PropTypes.string.isRequired,
  operationalAreas: PropTypes.arrayOf(PropTypes.object),
  harbourUserGroups: PropTypes.arrayOf(PropTypes.object),
  harbourmasterVessels: PropTypes.arrayOf(PropTypes.object),
  users: PropTypes.arrayOf(PropTypes.object),
  incidentCategories: PropTypes.arrayOf(PropTypes.object),
  incident: PropTypes.object.isRequired
};

export default function WrappedEdit({
  match: {
    params: { id }
  }
}) {
  const classifications = useContext(ClassificationsContext);
  const { user } = useContext(CurrentUserContext);
  const { result: incident, isFetching } = useRxDocument('incidents', id);
  const { defaultPosition, operationalBounds } = useContext(
    ApplicationConfigContext
  );

  if (isFetching) {
    return 'Loading...';
  }

  if (!isFetching && !incident) {
    return 'Not found.';
  }

  return (
    <Edit
      currentUserId={user.id}
      incident={incident}
      operationalBounds={operationalBounds}
      defaultPosition={defaultPosition}
      {...classifications}
    />
  );
}

WrappedEdit.propTypes = {
  match: PropTypes.object.isRequired
};
