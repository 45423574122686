import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import useNetworkConnectivityStatus from './common/hooks/useNetworkConnectivityStatus';
import { HEADERS } from './graphql/constants';
import actions from '../images/actions.svg';
import home from '../images/home.svg';
import logoWithText from '../images/logo-with-text.svg';
import logo from '../images/logo.svg';
import map from '../images/map.svg';
import notification from '../images/notification.svg';
import plus from '../images/plus.svg';
import settings from '../images/settings.svg';

const OfflineDisabledLink = ({
  className = '',
  offlineMessage,
  children,
  ...linkProps
}) => {
  const isOnline = useNetworkConnectivityStatus();
  const linkClassName = isOnline ? className : className + ' disabled';

  const onClick = useCallback(
    evt => {
      if (!isOnline) {
        alert(offlineMessage || 'This feature is not available offline.');
        evt.preventDefault();
      }

      linkProps.onClick && linkProps.onClick(evt);
    },
    [offlineMessage, linkProps, isOnline]
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <a {...linkProps} onClick={onClick} className={linkClassName}>
      {children}
    </a>
  );
};

OfflineDisabledLink.propTypes = {
  offlineMessage: PropTypes.string,
  linkProps: PropTypes.shape({ href: PropTypes.string.isRequired }),
  children: PropTypes.node,
  className: PropTypes.string
};

const DesktopHeader = ({ hasPermissionToManageUsers, currentUserId }) => {
  const isOnline = useNetworkConnectivityStatus();
  const logOut = useCallback(() => {
    window.Harbours.application.stop();

    const url = '/users/sign_out';

    fetch(url, {
      method: 'DELETE',
      redirect: 'follow',
      headers: {
        ...HEADERS,
        'Content-Type': 'application/json'
      }
    }).then(() => (window.location = '/users/sign_in'));
  }, []);

  return (
    <header className="header">
      <div className="header--left">
        <a href="/" className="button tertiary header__button logo">
          <img src={logo} alt="MDC Logo" className="hide-for-large" />
          <img src={logoWithText} alt="MDC Logo" className="show-for-large" />
        </a>
        <a href="/" className="button clear header__button show-for-large">
          <img src={home} alt="Dashboard" className="icon" />
          Dashboard
        </a>
        <a href="/actions" className="button clear header__button">
          <img src={actions} alt="Actions" className="icon" />
          <span>Actions</span>
        </a>
        <a href="/" className="button clear header__button show-for-large">
          <img src={map} alt="Map" className="icon" />
          Map
        </a>
      </div>
      <div className="header--right">
        <button
          className="button header__button"
          data-toggle="application__create-new-menu"
          type="button"
        >
          <img src={plus} className="icon" alt="Create New" />
          Create New
        </button>
        <div
          className="dropdown-pane"
          id="application__create-new-menu"
          data-dropdown
          data-hover="true"
          data-hover-pane="true"
        >
          <ul className="menu vertical">
            <li>
              <a href="/incidents/new">Incident</a>
            </li>
            <li>
              <a href="/actions/new">Action</a>
            </li>
            <li>
              <a href="/navigation_aids/new">AtoN</a>
            </li>
            <li>
              <a href="/manage-assets/new">Asset</a>
            </li>
          </ul>
        </div>
        <OfflineDisabledLink
          href="/notifications"
          offlineMessage="Mentions are not available offline"
          className="button clear header__button icon-only show-for-medium"
        >
          <img src={notification} className="icon" alt="Notifications" />
        </OfflineDisabledLink>
        <span className="button clear network-status">
          {isOnline ? 'Online' : 'Offline'}
        </span>
        <button
          className="button clear header__button icon-only show-for-medium"
          data-toggle="application__settings-menu"
          type="button"
        >
          <img src={settings} className="icon" alt="Settings" />
        </button>
        <div
          className="dropdown-pane"
          id="application__settings-menu"
          data-dropdown
          data-hover="true"
          data-hover-pane="true"
        >
          <ul className="menu vertical">
            <li>
              <OfflineDisabledLink
                offlineMessage="Account editing is not available offline"
                href={`/users/${currentUserId}/edit`}
              >
                Edit Account
              </OfflineDisabledLink>
            </li>
            {hasPermissionToManageUsers && (
              <li>
                <OfflineDisabledLink
                  offlineMessage="User management is not available offline"
                  href={`/users`}
                >
                  Manage Users
                </OfflineDisabledLink>
              </li>
            )}
          </ul>
        </div>
        <button
          type="button"
          onClick={logOut}
          className="button clear header__button show-for-medium"
        >
          Log out
        </button>
      </div>
    </header>
  );
};
export default props => <DesktopHeader {...props} />;

DesktopHeader.propTypes = {
  hasPermissionToManageUsers: PropTypes.bool.isRequired,
  currentUserId: PropTypes.number.isRequired
};
