import React, { useContext } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AssetCreate from './create';
import AssetEdit from './edit';
import AssetCollection from './index';
import AssetShow from './show';
import ClassificationsContext from '../common/ClassificationsContext';
import Page from '../common/Page';
import applicationConfigContext from '../common/application-config-context';

export default () => {
  const { assetCategories, operationalAreas } = useContext(
    ClassificationsContext
  );
  const config = useContext(applicationConfigContext);
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/new`}>
        <AssetCreate
          defaultPosition={config.defaultPosition}
          assetCategories={assetCategories}
          operationalAreas={operationalAreas}
        />
      </Route>
      <Route path={`${match.path}/:id/edit`} component={AssetEdit} />
      <Page
        title="Asset Summary"
        path={`${match.path}/:id`}
        component={AssetShow}
      />
      <Page title="Assets" path={match.path}>
        <AssetCollection operationalAreas={operationalAreas} />
      </Page>
    </Switch>
  );
};
