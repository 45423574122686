import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Mention, MentionsInput } from 'react-mentions';

function MentionableInput({ users, input, ...rest }) {
  function handleDisplayTransform(_, display) {
    return `@${display}`;
  }

  const mentionableUsers = useMemo(
    () =>
      users
        .filter(user => user.staff)
        .map(user => ({
          id: user.id,
          display: user.name
        })),
    [users]
  );

  return (
    <MentionsInput
      {...input}
      {...rest}
      className="mention-area cell auto"
      allowSuggestionsAboveCursor
      placeholder="Add a log entry"
    >
      <Mention
        data={mentionableUsers}
        className="inline-mention"
        displayTransform={handleDisplayTransform}
        appendSpaceOnAdd
        allowSpaceInQuery
        markup="@[__display__](__id__)"
      />
    </MentionsInput>
  );
}

MentionableInput.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  input: PropTypes.object
};

export default MentionableInput;
