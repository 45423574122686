import PropTypes from 'prop-types';
import React from 'react';
import ActionFields from '../actions/action-fields';
import AutosaveSpy from '../forms/autosave/AutosaveSpy';
import IncidentActionDecorator from '../forms/decorators/IncidentActionDecorator';
import { FormGroup } from '../forms/field';

const IncidentAction = props => {
  const { form, skip, storageKey } = props;
  const values = form.getState().values;

  const skipAction = () => {
    values.incidentAction = undefined;

    return skip(values);
  };

  return (
    <FormGroup prefix="incidentAction">
      <div className="grid-x align-center">
        <button
          onClick={skipAction} // eslint-disable-line react/jsx-no-bind
          type="button"
          className="button tertiary"
        >
          Skip this step
        </button>
      </div>
      <ActionFields {...props} warning={false} />
      <AutosaveSpy
        DecoratorComponent={IncidentActionDecorator}
        storageKey={storageKey}
      />
    </FormGroup>
  );
};

IncidentAction.propTypes = {
  form: PropTypes.object.isRequired,
  skip: PropTypes.func.isRequired,
  storageKey: PropTypes.string.isRequired,
  assets: PropTypes.arrayOf(PropTypes.object)
};

export default IncidentAction;
