/* based on:
  https://github.com/final-form/react-final-form/issues/130#issuecomment-559687555
  and
  https://github.com/marmelab/react-admin/pull/4082/files#diff-af9c85a910d32bf9511f6cd45c8e2058

  required because of:
  https://github.com/final-form/final-form/blob/master/docs/faq.md#why-does--final-form-set-my--field-value-to-undefined
*/

export function includeRemovedValues(initialValues, values) {
  // For every field initially provided, we check whether it value has been removed
  // and set it explicitly to an empty string
  if (!initialValues) {
    return values;
  }

  const initialValuesWithEmptyFields = Object.keys(initialValues).reduce(
    (acc, key) => {
      const value = values[key];

      if (
        value instanceof Date ||
        (Array.isArray(value) && typeof value[0] !== 'object')
      ) {
        acc[key] = value;
      } else if (Array.isArray(value) && typeof value[0] === 'object') {
        acc[key] = value.map((val, itr) => {
          return includeRemovedValues(initialValues[key][itr], val);
        });
      } else if (typeof value === 'object' && value !== null) {
        acc[key] = includeRemovedValues(initialValues[key], value);
      } else {
        acc[key] = typeof value === 'undefined' ? null : value;
      }

      return acc;
    },
    {}
  );

  // Finally, we merge back the values to not miss any which wasn't initially provided
  return Object.assign({}, values, initialValuesWithEmptyFields);
}
