import { useRxData } from 'rxdb-hooks';

const sortByDate = (a, b) => {
  if (a.closedAt !== 0 && b.closedAt === 0) {
    return 1;
  }
  if (b.closedAt !== 0 && a.closedAt === 0) {
    return -1;
  }

  return a.datetime - b.datetime;
};

const sortByClosedDateDesc = (a, b) => {
  if (a.closedAt > b.closedAt) {
    return -1;
  }

  return 1;
};

const filterByStatus =
  desiredStatus =>
  ({ status }) =>
    status === desiredStatus;

/**
 * Provides a common implementation to reference actions related to a rxDocument,
 * returning the collection as a whole as well as pre-filtered open and closed actions.
 * @param {String} refAttribute The attribute to match actions against. Typically a ref, but can be any attribute
 * @param {String} refValue The value to match actions against. Typically a UUID, but can be any value.
 * @returns {Object} The relatedActions, openActions, closedActions arrays of RxDocument actions.
 */
export default function useRelatedActions(refAttribute, refId) {
  const { result } = useRxData('actions', collection =>
    collection.find({
      selector: { [refAttribute]: refId }
    })
  );

  let relatedActions = result && result.sort(sortByDate);

  const openActions =
    relatedActions &&
    relatedActions.filter(filterByStatus('open')).sort(sortByDate);
  const closedActions =
    relatedActions &&
    relatedActions.filter(filterByStatus('closed')).sort(sortByClosedDateDesc);

  return {
    relatedActions,
    openActions,
    closedActions
  };
}
