import schema from '../schemas/logEntry';

export default {
  logentries: {
    schema,
    migrationStrategies: {
      1: oldDoc => {
        oldDoc.createdBy = oldDoc.createdBy && oldDoc.createdBy.id;
        oldDoc.editor = oldDoc.editor && oldDoc.editor.id;

        return oldDoc;
      },
      2: oldDoc => {
        oldDoc.source.type =
          oldDoc.source.__typename || oldDoc.source['|typename'];

        return oldDoc;
      }
    }
  }
};
