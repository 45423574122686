export const filters = [
  {
    label: 'Incident ID',
    field: 'pattern',
    id: 'sequence_number',
    pattern: '^[0-9]{5}$'
  },
  {
    label: 'Incident category',
    field: 'custom-select',
    category: 'incidentCategories',
    id: 'parent_incident_category_id'
  },
  {
    parentAttribute: 'parent_incident_category_id',
    childAttribute: 'incident_category_id',
    label: 'Incident sub-category',
    field: 'taxonomy-categories-select',
    category: 'incidentCategories',
    id: 'incident_category_id'
  },
  {
    label: 'Incident status',
    field: 'custom-select',
    category: 'incidentStatus',
    id: 'status'
  },
  {
    label: 'Operational area',
    field: 'custom-select',
    category: 'operationalAreas',
    id: 'parent_operational_area_id'
  },
  {
    parentAttribute: 'parent_operational_area_id',
    childAttribute: 'operational_area_id',
    label: 'Sub-operational area',
    field: 'taxonomy-categories-select',
    category: 'operationalAreas',
    id: 'operational_area_id'
  },
  {
    label: 'User group',
    field: 'custom-select',
    category: 'harbourUserGroups',
    id: 'parent_harbours_user_group_id'
  },
  {
    parentAttribute: 'parent_harbours_user_group_id',
    childAttribute: 'harbours_user_group_id',
    label: 'User sub-group',
    field: 'taxonomy-categories-select',
    category: 'harbourUserGroups',
    id: 'harbours_user_group_id'
  },
  {
    label: 'Recorded by',
    field: 'custom-select',
    category: 'users',
    id: 'user_id'
  },
  { label: 'Created between', field: 'date', id: 'created_after' },
  { label: null, field: 'date', id: 'created_before' },
  {
    label: 'Critical',
    field: 'custom-select',
    category: 'incidentCritical',
    id: 'critical'
  },
  {
    label: 'Patrol Vessel',
    field: 'custom-select',
    category: 'harbourmasterVessels',
    id: 'harbourmaster_vessel_id'
  }

  // { name: 'Actions', field: 'select', id: 'actions' }
  // { name: 'Incident Severity', field: 'select', id: 'incident_severity' },
];
