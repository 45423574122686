import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import useGeoLocation from '../common/hooks/useGeoLocation';
import useNetworkConnectivityStatus from '../common/hooks/useNetworkConnectivityStatus';
import { WebMapView } from '../common/map/index';
import {
  composeValidators,
  mustBeNumber,
  valueBetween
} from '../common/validators';
import { FormGroupContext, FormGroupField } from '../forms/field';

const DEFAULT_ZOOM = 8;
const LATITUDE_BOUNDS = [-90, 90];
const LONGITUDE_BOUNDS = [-180, 180];

function LocationSelect({
  zoom = DEFAULT_ZOOM,
  bounds,
  defaultPosition,
  form,
  prefix
}) {
  const mapRef = useRef();

  const [geoLocationSource, setGeoLocationSource] = useState('manual');
  const displayMap = geoLocationSource === 'map';
  const accessDeviceLocation = geoLocationSource === 'device';
  const geoLocation = useGeoLocation({ accessDeviceLocation });

  const formInitialized = !!form.getRegisteredFields()[0];
  const lngVal = () =>
    formInitialized &&
    form.getFieldState((prefix ? `${prefix}.` : '') + 'longitude') &&
    form.getFieldState((prefix ? `${prefix}.` : '') + 'longitude').value;

  const latVal = () =>
    formInitialized &&
    form.getFieldState((prefix ? `${prefix}.` : '') + 'latitude') &&
    form.getFieldState((prefix ? `${prefix}.` : '') + 'latitude').value;

  const requireCoordinate = () => (_val, allValues) => {
    const lat =
      prefix && allValues[prefix]
        ? allValues[prefix].latitude
        : allValues.latitude;
    const lng =
      prefix && allValues[prefix]
        ? allValues[prefix].longitude
        : allValues.longitude;

    return (lat && !lng) || (lng && !lat)
      ? `Both latitude and longitude must be provided`
      : undefined;
  };

  const currentCenter =
    lngVal() && latVal() ? [lngVal(), latVal()] : defaultPosition;

  const latitudeBounds =
    bounds && bounds.length === 2
      ? {
          lower: Math.max(bounds[1][1], LATITUDE_BOUNDS[0]),
          upper: Math.min(bounds[0][1], LATITUDE_BOUNDS[1])
        }
      : {
          lower: LATITUDE_BOUNDS[0],
          upper: LATITUDE_BOUNDS[1]
        };

  const longitudeBounds =
    bounds && bounds.length === 2
      ? {
          lower: Math.max(bounds[0][0], LONGITUDE_BOUNDS[0]),
          upper: Math.min(bounds[1][0], LONGITUDE_BOUNDS[1])
        }
      : {
          lower: LONGITUDE_BOUNDS[0],
          upper: LONGITUDE_BOUNDS[1]
        };

  if (formInitialized && displayMap) {
    setLatLng({ form, currentCenter, geoLocationSource, prefix });
  }

  if (formInitialized && accessDeviceLocation && geoLocation.longitude) {
    setLatLng({ form, geoLocation, geoLocationSource, prefix });
  }

  const isOnline = useNetworkConnectivityStatus();

  let locationSources = [
    ['manual', 'Manual'],
    ['device', 'Device']
  ];

  if (isOnline) {
    locationSources.push(['map', 'Map']);
  }

  return (
    <>
      <div className="row wrappable-input-group">
        {locationSources.map(([type, label]) => {
          return (
            <div
              key={type}
              className="input-group panel panel--bordered panel--compact wrappable-input"
            >
              <label className="radio-label flex-child-grow">
                <span>{label}</span>
                <input
                  className="block--4 align-center"
                  /* eslint-disable-next-line react/jsx-no-bind */
                  onChange={() => setGeoLocationSource(type)}
                  type="radio"
                  id={type}
                  name="geoLocationSource"
                  value={type}
                  checked={type === geoLocationSource}
                />
                <span className="radio" />
              </label>
            </div>
          );
        })}
      </div>
      <div className="row small-up-12 medium-up-2 grid-x grid-margin-x">
        <div className="cell">
          <label className="panel-label" htmlFor="latitude">
            Latitude
          </label>
          <FormGroupField
            id="latitude"
            name="latitude"
            component="input"
            type="text"
            placeholder="Latitude"
            validateFields={
              prefix
                ? [`${prefix}.longitude`, `${prefix}.latitude`]
                : ['longitude', 'latitude']
            }
            validate={composeValidators(
              requireCoordinate(prefix),
              mustBeNumber,
              valueBetween(latitudeBounds.lower, latitudeBounds.upper)
            )}
          />
        </div>
        <div className="cell">
          <label className="panel-label" htmlFor="longitude">
            Longitude
          </label>
          <FormGroupField
            id="longitude"
            name="longitude"
            component="input"
            type="text"
            placeholder="Longitude"
            validateFields={
              prefix
                ? [`${prefix}.latitude`, `${prefix}.longitude`]
                : ['latitude', 'longitude']
            }
            validate={composeValidators(
              mustBeNumber,
              valueBetween(longitudeBounds.lower, longitudeBounds.upper),
              requireCoordinate(prefix)
            )}
          />
        </div>
      </div>

      <div className="help-text margin-bottom-1">
        Enter coordinates in decimal format - -42.000000
      </div>

      {displayMap && (
        <WebMapView
          prefix={prefix}
          locationDidUpdate={setLatLng}
          center={currentCenter}
          mapRef={mapRef}
          zoom={zoom}
          form={form}
        />
      )}
    </>
  );
}

export default function LocationSelectWrapper(props) {
  return (
    <FormGroupContext.Consumer>
      {prefix => <LocationSelect prefix={prefix} {...props} />}
    </FormGroupContext.Consumer>
  );
}

LocationSelect.propTypes = {
  form: PropTypes.object,
  zoom: PropTypes.number,
  error: PropTypes.string,
  prefix: PropTypes.string,
  defaultPosition: PropTypes.arrayOf(PropTypes.number),
  bounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
};

function setLatLng({
  form,
  geoLocation,
  currentCenter,
  geoLocationSource,
  prefix
}) {
  const pre = prefix ? prefix + '.' : '';

  if (geoLocationSource === 'map') {
    form.change(`${pre}longitude`, currentCenter[0]);
    form.change(`${pre}latitude`, currentCenter[1]);
  }

  if (geoLocationSource === 'device') {
    const { longitude, latitude } = geoLocation;
    form.change(`${pre}longitude`, longitude);
    form.change(`${pre}latitude`, latitude);
  }
}
