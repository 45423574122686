import { default as React, useMemo } from 'react';
import { Link } from 'react-router-dom';
import DataTable from './DataTable';
import OfflineDataTable from './OfflineDataTable';
import extraClassifications from './data/navigation-aid-extra-classifications';
import { Provider as ClassificationsContextProvider } from '../common/ClassificationsContext';
import useNetworkConnectivityStatus from '../common/hooks/useNetworkConnectivityStatus';

export default function Index(props) {
  const filterOptions = useMemo(
    () => ({ ...extraClassifications, ...props }),
    [props]
  );

  const online = useNetworkConnectivityStatus();

  return (
    <ClassificationsContextProvider value={{ ...filterOptions }}>
      <div className="page">
        <h2>Atons Table {online || '(Offline)'}</h2>
        <div className="panel">
          {online ? <DataTable /> : <OfflineDataTable />}
          <div className="grid-x align-right">
            <Link
              to={`/navigation_aids/new`}
              className="button secondary"
              type="button"
            >
              + Create new
            </Link>
          </div>
        </div>
      </div>
    </ClassificationsContextProvider>
  );
}
