import { isAfter } from 'date-fns';
import { diff } from 'deep-object-diff';
import { FORM_ERROR } from 'final-form';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';
import { useRxData } from 'rxdb-hooks';
import ReviewSubmission from './ReviewSubmission';
import ActionFields from './action-fields';
import applyImpromptuBehaviour from './behaviours/impromptu';
import CurrentUserContext from '../common/CurrentUserContext';
import ApplicationConfigContext from '../common/application-config-context';
import useRxDocument from '../common/hooks/useRxDocument';
import { includeRemovedValues } from '../common/include-removed-values';
import leftPad from '../common/left-pad';
import { POINT_DEFAULT_SRID } from '../db/schemas/point';
import AutosaveSpy from '../forms/autosave/AutosaveSpy';
import {
  buildStorageKey,
  loadAutosaveItem,
  removeAutosaveItem
} from '../forms/autosave/autosave-helpers';
import ActionDecorator from '../forms/decorators/ActionDecorator';
import Wizard from '../forms/wizard';

async function handleUpdateSubmit(params, action, onSuccess) {
  const values = includeRemovedValues(buildInitialValues(action), {
    ...params
  });

  const { latitude, longitude, operationalArea, parentOperationalArea } =
    values;

  // Sometimes values.datetime is a string, not a Date object.
  // I suspect this relates to autosaving serialisation/deserialisation,
  // but since passing a date to the date constructor works, we do this
  // here to make sure we know what we're dealing with.
  values.datetime = new Date(values.datetime).getTime();

  // Remove additional properties
  delete values.latitude;
  delete values.longitude;
  delete values.date;
  delete values.time;
  delete values.isPriority;

  if (longitude && latitude) {
    values.location = {
      lng: parseFloat(longitude),
      lat: parseFloat(latitude),
      srid: POINT_DEFAULT_SRID
    };
  }

  let submissionErrors = {};

  if (!operationalArea && parentOperationalArea) {
    values.operationalArea = parentOperationalArea;
  }

  return action
    .incrementalPatch(values)
    .then(applyImpromptuBehaviour)
    .then(onSuccess)
    .catch(err => {
      submissionErrors[[FORM_ERROR]] = err.message || err;

      return submissionErrors;
    });
}

function buildInitialValues(action) {
  const {
    id,
    datetime,
    requestedBy,
    assignedTo,
    operationalArea,
    parentOperationalArea,
    category,
    subcategory,
    instructions,
    marineFarmSiteNumber,
    mooringNumber,
    asset
  } = action;

  const actionDatetime = new Date(datetime);
  const { lat, lng } = action.location || {};

  return {
    date: `${actionDatetime.getFullYear()}-${leftPad(
      actionDatetime.getMonth() + 1
    )}-${leftPad(actionDatetime.getDate())}`,
    datetime: actionDatetime,
    requestedBy,
    assignedTo,
    parentOperationalArea,
    operationalArea,
    category,
    subcategory,
    longitude: lng || '',
    latitude: lat || '',
    isPriority: action.urgency === 'priority',
    instructions,
    marineFarmSiteNumber,
    mooringNumber,
    id,
    asset
  };
}

function Edit(props) {
  const storageKey = buildStorageKey('action-update');
  const history = useHistory();
  const { action } = props;

  const dbValues = useMemo(() => {
    return buildInitialValues(action);
  }, [action]);

  const [warning, setWarning] = useState(false);
  const storedData = loadAutosaveItem(storageKey)
    ? JSON.parse(loadAutosaveItem(storageKey))
    : null;

  const storedForm = storedData && storedData.form;
  const initialValues = storedForm ? storedForm : dbValues;

  useEffect(() => {
    if (!storedForm) {
      return;
    }
    const JSONvalues = JSON.stringify(dbValues);
    const changes = diff(storedForm, JSON.parse(JSONvalues));

    if (!isEmpty(changes)) {
      isAfter(new Date(action.updatedAt), new Date(storedData.storedAt))
        ? setWarning('updatedSince')
        : setWarning('saved');
    }
  }, [storedForm, storedData, action, dbValues]);

  const onSubmit = useCallback(
    values => {
      const onSuccess = () => {
        removeAutosaveItem(storageKey);
        history.push(
          `/actions/${action.id}?status_message=actions.update.success`
        );
      };

      handleUpdateSubmit(values, action, onSuccess);
    },
    [action, storageKey, history]
  );

  const AutosavingActionFields = wizardProps => {
    return (
      <div>
        <ActionFields
          {...props}
          {...wizardProps}
          warning={warning}
          setWarning={setWarning}
          storageKey={storageKey}
        />
        <AutosaveSpy
          DecoratorComponent={ActionDecorator}
          storageKey={storageKey}
        />
      </div>
    );
  };

  return (
    <Wizard initialValues={initialValues} onSubmit={onSubmit}>
      <AutosavingActionFields key="wizard-step-1" title="Edit Action" />
      <ReviewSubmission title="Submit" />
    </Wizard>
  );
}

Edit.propTypes = {
  currentUserId: PropTypes.string.isRequired,
  action: PropTypes.object.isRequired,
  assets: PropTypes.arrayOf(PropTypes.object)
};

export default function WrappedEdit({
  match: {
    params: { id }
  }
}) {
  const { result: assets } = useRxData('assets', c => c.find());
  const { user } = useContext(CurrentUserContext);
  const { result: action, isFetching } = useRxDocument('actions', id);
  const { defaultPosition, operationalBounds } = useContext(
    ApplicationConfigContext
  );

  if (isFetching) {
    return 'Loading...';
  }

  if (!isFetching && !action) {
    return 'Not found.';
  }

  return (
    <Edit
      currentUserId={user.id}
      action={action}
      assets={assets}
      operationalBounds={operationalBounds}
      defaultPosition={defaultPosition}
    />
  );
}

WrappedEdit.propTypes = {
  match: PropTypes.object.isRequired
};
