export const extraClassifications = {
  urgency: [
    { id: 'priority', name: 'priority' },
    { id: 'normal', name: 'normal' }
  ],
  status: [
    { id: 'open', name: 'Open' },
    { id: 'due', name: 'Due' },
    { id: 'overdue', name: 'Overdue' },
    { id: 'closed', name: 'Closed' }
  ]
};
