import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import arrowDown from '../../images/arrow-down.svg';
import arrowUp from '../../images/arrow-up.svg';
import selectRow from '../common/select-row';

export default function DataTable({
  resource,
  tableData,
  headers,
  handleSortByChange
}) {
  useEffect(() => {
    tableData &&
      tableData.forEach(row =>
        $(`#dropdown-menu-${row[0].value}`).foundation()
      );
  }, [tableData]);

  const history = useHistory();
  const { state } = useLocation();

  return (
    <table className="scroll hover">
      <thead>
        <tr>
          {headers.map((header, i) => {
            const { name, id, className = '', sortable = true } = header;

            return (
              <th key={i} className={`table-header ${className}`}>
                {sortable ? (
                  <div
                    className="grid-x"
                    /* eslint-disable-next-line react/jsx-no-bind */
                    onClick={() => handleSortByChange(id)}
                    /* eslint-disable-next-line react/jsx-no-bind */
                    onKeyPress={() => handleSortByChange(id)}
                    tabIndex={0}
                    role="button"
                  >
                    <span className="grid-x align-left flex-child-grow">
                      {name}
                    </span>
                    <div
                      className="flex-dir-column grid-x align-spaced"
                      name={`sort-by-${name
                        .replace(/\s/g, '-')
                        .replace(/\//g, '')}`}
                    >
                      <img src={arrowUp} className="icon" alt="Arrow Up" />
                      <img src={arrowDown} className="icon" alt="Arrow Down" />
                    </div>
                  </div>
                ) : (
                  <span className="grid-x align-left flex-child-grow">
                    {name}
                  </span>
                )}
              </th>
            );
          })}
        </tr>
      </thead>
      {tableData && (
        <tbody>
          {tableData.map(row => {
            const id = row[0].value;
            const activeRow = state ? state.resourceId === id : false;

            return (
              <tr
                className={`clickable-target ${activeRow ? 'active-row' : ''}`}
                id={`table-row-${id}`}
                key={`table-row-${id}`}
                /* eslint-disable-next-line react/jsx-no-bind */
                onClick={() => selectRow(history, resource, id)}
              >
                {row.map((column, idx) => {
                  if (column.skip) {
                    return;
                  }
                  const activeCell = activeRow && idx === 1;

                  return (
                    <td
                      key={idx}
                      className={`table-text ${
                        activeCell ? 'padding-left-1' : ''
                      }`}
                    >
                      {column.value}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      )}
    </table>
  );
}

DataTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.array),
  headers: PropTypes.array,
  handleSortByChange: PropTypes.func,
  resource: PropTypes.string
};
