import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import tableCog from '../../images/table-cog.svg';
import ApplicationConfigContext from '../common/application-config-context';
import checkStatus from '../common/status-checker';

export default function StatusColumn({ action }) {
  const { overdueAfterDays } = useContext(ApplicationConfigContext);

  return (
    <div className="grid-x align-middle align-justify">
      <div>{checkStatus(action, { overdueAfterDays })}</div>
      <ul
        id={`dropdown-menu-${action.id}`}
        className="dropdown menu margin-left-auto"
        data-dropdown-menu
        data-alignment="right"
      >
        <li>
          <Link to={`/actions/${action.id}`}>
            <img src={tableCog} className="icon" alt="options" />
          </Link>
          <ul className="menu">
            <li>
              <Link to={`/actions/${action.id}#add_log_entry`}>Log Entry</Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

StatusColumn.propTypes = {
  action: PropTypes.object.isRequired
};
