import { involvedIndividualsColumn } from './incident-columns';
import { localize } from '../../common/date-format';

export default function getIncidentProperties(incident) {
  const {
    datetime,
    id,
    incidentCategory,
    harboursUserGroup,
    operationalArea,
    sequenceNumber,
    user,
    status,
    involvedIndividuals
  } = incident;

  const userName = user?.name;
  const incidentCategoryName = incidentCategory?.name;
  const harbourUserGroupName = harboursUserGroup?.name;
  const operationalAreaName = operationalArea?.name;

  return [
    { value: id, skip: true },
    { value: sequenceNumber },
    { value: involvedIndividualsColumn(involvedIndividuals) },
    { value: incidentCategoryName },
    { value: operationalAreaName },
    { value: harbourUserGroupName },
    { value: userName },
    { value: localize(datetime) },
    { value: status && status.charAt(0).toUpperCase() + status.slice(1) }
  ];
}
