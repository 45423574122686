import PropTypes from 'prop-types';
import React from 'react';
import { formatContent } from '../../format';

const FormattedContent = ({ content }) =>
  (content && (
    <div
      data-format
      data-formatted
      dangerouslySetInnerHTML={{ __html: formatContent(content) }}
    />
  )) ||
  null;

FormattedContent.propTypes = {
  content: PropTypes.string
};

export default FormattedContent;
