import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import LogEntryEdit from './Edit';

export default () => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:id/edit`} component={LogEntryEdit} />
    </Switch>
  );
};
