import React from 'react';
import { useRxCollection, useRxDB } from 'rxdb-hooks';

export const withRxCollection = (
  Component,
  collectionName,
  Loading = <span>Loading...</span>
) => {
  return props => {
    const collection = useRxCollection(collectionName);
    if (!collection) {
      return Loading;
    }

    return <Component {...props} collection={collection} />;
  };
};

export const withRxDB = (Component, Loading = <span>Loading...</span>) => {
  return props => {
    const db = useRxDB();
    if (!db) {
      return Loading;
    }

    return <Component {...props} db={db} />;
  };
};
