import { orderBy } from 'lodash-es';
import React, { useEffect, useState, useContext } from 'react';
import { useRxData } from 'rxdb-hooks';
import getOfflineIncidentProperties from './data/get-incident-document-properties';
import { headers } from './data/incidents-headers';
import ApplicationConfigContext from '../common/application-config-context';
import DataTable from '../dataTable/data-table';

export default () => {
  const [tableData, setTableData] = useState([]);
  const { offlineRecordsLimit } = useContext(ApplicationConfigContext);
  const { result: incidents, isLoading } = useRxData('incidents', collection =>
    collection.find()
  );

  const unsortableHeaders = [...headers];
  unsortableHeaders.forEach(header => (header.sortable = false));

  useEffect(() => {
    incidents &&
      Promise.all(
        orderBy(incidents, 'sequenceNumber', 'desc')
          .map(incident => getOfflineIncidentProperties(incident))
          .slice(0, offlineRecordsLimit)
      ).then(setTableData);
  }, [incidents, offlineRecordsLimit]);

  if (isLoading || !tableData.length) {
    return 'Loading...';
  }

  return (
    <DataTable
      resource={'incidents'}
      tableData={tableData}
      headers={unsortableHeaders}
    />
  );
};
