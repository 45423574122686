import { ApolloClient, InMemoryCache } from '@apollo/client';
function getCSRF() {
  const csrfElement =
    typeof window !== 'undefined' &&
    document.querySelector('meta[name=csrf-token]');
  if (csrfElement) {
    const csrfToken = csrfElement.getAttribute('content');

    return { 'X-CSRF-Token': csrfToken };
  }

  return {};
}
export default new ApolloClient({
  uri: '/graphql',
  cache: new InMemoryCache(),
  fetchOptions: {
    credentials: 'same-origin'
  },
  headers: {
    ...getCSRF()
  }
});
