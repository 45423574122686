import { useEffect, useState } from 'react';

export default function useRxAssociation(document, associationName) {
  let [associatedDocument, setAssociatedDocument] = useState();

  useEffect(
    () =>
      document &&
      document.populate(associationName).then(setAssociatedDocument) &&
      undefined,
    [document, associationName]
  );

  return associatedDocument;
}
