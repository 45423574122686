import { UUID } from '../helpers';

export default {
  version: 3,
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: UUID.maxLength,
      final: true
    },
    name: {
      type: 'string'
    },
    updatedAt: {
      type: 'integer'
    },
    active: {
      type: 'boolean',
      default: true
    },
    admin: {
      type: 'boolean',
      default: false
    },
    staff: {
      type: 'boolean',
      default: true
    }
  }
};
