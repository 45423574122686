import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ActionRouter from './actions/router';
import AssetRouter from './assets/router';
import IncidentRouter from './incidents/router';
import LogEntryRouter from './logEntries/router';
import NavigationAidRouter from './navigation_aids/router';

export default () => (
  <Switch>
    <Route path="/incidents">
      <IncidentRouter />
    </Route>
    <Route path="/actions">
      <ActionRouter />
    </Route>
    <Route path="/navigation_aids">
      <NavigationAidRouter />
    </Route>
    {/* /assets is taken by Rails' static file serving */}
    <Route path="/manage-assets">
      <AssetRouter />
    </Route>
    <Route path="/log_entries">
      <LogEntryRouter />
    </Route>
  </Switch>
);
