import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '../../images/edit-solid.svg';
import FormattedContent from '../common/content/FormattedContent';
import { localize } from '../common/date-format';
import useRxAssociation from '../common/hooks/useRxAssociation';
import useRxDocument from '../common/hooks/useRxDocument';

const sourceTypeCollections = {
  Incident: 'incidents',
  Action: 'actions',
  NavigationAid: 'navigationaids'
};

const PropagationIndicator = ({ item, displayContext }) => {
  const collectionName = sourceTypeCollections[item.source.type];
  const { result: source } = useRxDocument(collectionName, item.source.id);

  if (displayContext === 'Action' && item.propagated) {
    return (
      <>
        <br />
        Included in incident log
      </>
    );
  }

  if (displayContext === 'Incident' && source && source.incident) {
    return (
      <>
        <br />
        (Included from {item.source.type}{' '}
        {source.sequenceNumber && `#${source.sequenceNumber}`})
      </>
    );
  }

  return null;
};

PropagationIndicator.propTypes = {
  item: PropTypes.object.isRequired,
  displayContext: PropTypes.string
};

const LogEntry = ({ item, displayContext }) => {
  const createdBy = useRxAssociation(item, 'createdBy');
  const editor = useRxAssociation(item, 'editor');

  return (
    <div className="log-entry margin-bottom-1">
      <div className="grid-x">
        <div className="cell small-12">
          <b>
            <time dateTime={item.logTime}>{localize(item.logTime)}</time>
          </b>
          <PropagationIndicator item={item} displayContext={displayContext} />
        </div>
        <div className="grid-x align-justify cell small-12">
          {createdBy && (
            <div className="cell small-3 log-entry__user">{createdBy.name}</div>
          )}
          <Link
            to={`/log_entries/${item.id}/edit`}
            className="log-entry__button log-entry__button--primary cell shrink"
          >
            <img src={EditIcon} className="icon" alt="Edit log entry" />
            Edit
          </Link>
        </div>
      </div>

      {editor && (
        <>
          <b className="cell">
            <time dateTime={item.updatedAt}>{localize(item.updatedAt)}</time>
          </b>
          <div className="cell log-entry__user">edited: {editor.name}</div>
        </>
      )}
      <div className="log-entry__body">
        <FormattedContent content={item.content} />
      </div>
    </div>
  );
};

LogEntry.propTypes = {
  item: PropTypes.object.isRequired,
  displayContext: PropTypes.string
};

export default LogEntry;
