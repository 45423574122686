import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import * as Sentry from '@sentry/browser';
import { HEADERS as headers, ENDPOINT as uri } from './constants';

const retryLink = new RetryLink({
  attempts: {
    max: Infinity,
    retryIf(error) {
      return error.statusCode !== 401;
    }
  }
});
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) {
      if (networkError.statusCode === 401) {
        window.location = '/users/sign_in';

        return;
      }

      return forward(operation);
    }

    (graphQLErrors || []).forEach(({ message }) =>
      Sentry.captureException(message)
    );
  }
);

const httpLink = new HttpLink({
  uri,
  headers,
  fetchOptions: { credentials: 'same-origin' }
});

const cache = new InMemoryCache();
const client = new ApolloClient({
  cache,
  link: from([errorLink, retryLink, httpLink])
});

export { cache };
export default client;
