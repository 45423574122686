import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { localize } from '../common/date-format';
import detectBehaviour from '../common/detectBehaviour';

const LatestActionMetadata = ({ openActions, closedActions }) => {
  const [nextDueDate, setNextDueDate] = useState();
  const [lastCheckDate, setLastCheckDate] = useState();

  useEffect(() => {
    // Reset states when actions change
    setLastCheckDate(null);
    setNextDueDate(null);

    const resolveLastCheckDate = async () => {
      // Set 'lastCheckDate' to the first closed action of type 'recurring'
      for (let action of closedActions || []) {
        if (await detectBehaviour(action, 'recurring')) {
          setLastCheckDate(action.closedAt);
          break;
        }
      }
    };

    const resolveNextDueDate = async () => {
      // Set 'nextDueDate' to the first open action of type 'recurring'
      for (let action of openActions) {
        if (await detectBehaviour(action, 'recurring')) {
          setNextDueDate(action.datetime);
          break;
        }
      }
    };

    resolveLastCheckDate();
    resolveNextDueDate();
  }, [openActions, closedActions]);

  return (
    <>
      {nextDueDate && (
        <>
          <span>
            Next check due: {localize(nextDueDate, { format: 'defaultDate' })}
          </span>
          <br />
        </>
      )}
      {lastCheckDate && (
        <>
          <span>
            Last checked date:{' '}
            {localize(lastCheckDate, { format: 'defaultDate' })}
          </span>
          <br />
        </>
      )}
    </>
  );
};

LatestActionMetadata.propTypes = {
  openActions: PropTypes.arrayOf(PropTypes.object).isRequired,
  closedActions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default LatestActionMetadata;
