import schema from '../schemas/navigationAid';
export default {
  navigationaids: {
    schema,
    migrationStrategies: {
      1: oldDoc => oldDoc,
      2: doc => {
        doc.atonCategory = doc.category;
        delete doc.atonType;
        delete doc.category; // Let replication catch this up
        delete doc.subcategory;
        delete doc._attachments;
        delete doc._deleted;
        delete doc._rev;
        delete doc._meta;

        return doc;
      },
      3: doc => doc
    }
  }
};
