import PropTypes from 'prop-types';
import React from 'react';
import IncidentSummary from './Summary';
import ActionsList from '../actions/ActionsList';
import useRelatedActions from '../actions/useRelatedActions';
import useRxDocument from '../common/hooks/useRxDocument';

const IncidentShow = ({ match: { params } }) => {
  const { result: incident, isFetching } = useRxDocument(
    'incidents',
    params.id
  );

  const { openActions, closedActions } = useRelatedActions(
    'incident',
    params.id
  );

  if (isFetching) {
    return 'Loading...';
  }

  if (!incident) {
    return 'Not found';
  }

  return (
    <div className="page page--white-background grid-x grid-margin-x">
      <IncidentSummary incident={incident} />
      {openActions && openActions.length > 0 && (
        <ActionsList
          actions={openActions}
          expandedActions={openActions}
          title="Open Actions"
        />
      )}
      {closedActions && closedActions.length > 0 && (
        <ActionsList actions={closedActions} title="Closed Actions" />
      )}
    </div>
  );
};

IncidentShow.propTypes = {
  match: PropTypes.object.isRequired
};

export default IncidentShow;
