import pick from 'lodash-es/pick';
import { replicateGraphQL } from 'rxdb/plugins/replication-graphql';
import { ENDPOINT as url, HEADERS as headers } from '../../graphql/constants';
import {
  replicationWithErrorReporting,
  retryTime,
  wrapDocumentsWithCheckpoint,
  removeOptionalProperties,
  replicationWithLiveInterval,
  replicationPushResponseModifier
} from '../helpers';
import { ATON_CATEGORY_NAME } from '../schemas/navigationAid';

const pullQueryBuilder = () => {
  return (priorCheckpoint, limit) => {
    const checkpoint = priorCheckpoint || { id: '', updatedAt: 0 };

    console.log(
      `Fetching navigation aids at ${new Date()}, last modified document is ${
        checkpoint.updatedAt && new Date(checkpoint.updatedAt)
      }`
    );
    const query = `
      query ($filters: [Filter!], $limit: Int!) {
        assetCollection(filters: $filters, sort: { dir: ASC, order: "updatedAt" }, limit: $limit) {
          collection {
            id sequenceNumber name identificationNumber
            subcategory { id name }
            category { id name }
            operationalArea { id }
            parentOperationalArea { id }
            locationDescription updatedAt
            checkIntervalMonths
            location { lat lng srid }
            metadata
          }
        }
      }
    `;

    return {
      query,
      variables: {
        limit: limit,
        filters: [
          {
            identifier: 'categoryName',
            value: ATON_CATEGORY_NAME
          },
          {
            identifier: 'updated_since',
            value: checkpoint.updatedAt
              ? new Date(checkpoint.updatedAt).toISOString()
              : ''
          }
        ]
      }
    };
  };
};

const pushMutationBuilder = () => {
  return ([{ newDocumentState: document }]) => {
    const mutation = `
      mutation ($asset: AssetReplicationSetInput!) {
       setAsset(input: $asset) {
          asset {
            id
          }
          errors {
            message
            path
          }
        }
      }
    `;

    return {
      query: mutation,
      variables: { asset: document }
    };
  };
};

const convertNavigationAidTypeToDocument = aid => {
  let { operationalArea, parentOperationalArea, category, subcategory } = aid;

  aid.updatedAt = new Date(aid.updatedAt).getTime();
  aid.atonCategory = aid.metadata.category
    ? Number(aid.metadata.category)
    : null;
  aid.category = category?.id;
  aid.subcategory = subcategory?.id;
  aid.operationalArea = operationalArea?.id;
  aid.parentOperationalArea = parentOperationalArea?.id;

  removeOptionalProperties(aid);

  return aid;
};

const convertDocumentToNavigationAidType = doc => {
  let document = Object.assign(
    {},
    pick(doc, [
      'id',
      'identificationNumber',
      'name',
      'locationDescription',
      'metadata',
      'checkIntervalMonths'
    ]),
    {
      categoryId: doc.subcategory,
      location: doc.location
        ? [doc.location.lng.toString(), doc.location.lat.toString()]
        : null
    }
  );

  document.metadata = {
    ...(document.metadata || {}),
    // eslint-disable-next-line camelcase
    parent_operational_area_id: doc.parentOperationalArea,
    // eslint-disable-next-line camelcase
    operational_area_id: doc.operationalArea,
    category: doc.atonCategory
  };

  return document;
};

export default ({ collection }) =>
  replicationWithErrorReporting(
    replicationWithLiveInterval(
      replicateGraphQL({
        collection,
        url: { http: url },
        headers,
        pull: {
          queryBuilder: pullQueryBuilder(),
          dataPath: 'data.assetCollection.collection',
          batchSize: 100,
          responseModifier: wrapDocumentsWithCheckpoint,
          modifier: convertNavigationAidTypeToDocument
        },
        push: {
          queryBuilder: pushMutationBuilder(),
          batchSize: 1,
          dataPath: 'setAsset',
          responseModifier: replicationPushResponseModifier,
          // This modifier processes RxDB document data BEFORE the data is pushed
          modifier: convertDocumentToNavigationAidType
        },
        live: true,
        retryTime
      })
    )
  );
