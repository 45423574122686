import PropTypes from 'prop-types';
import React from 'react';
import CreateLogEntry from './Create';
import LogEntry from './LogEntry';
import ClassificationsContext from '../common/ClassificationsContext';

const Collection = ({
  sourceType,
  sourceId,
  canBePropagated = false,
  logEntries
}) => {
  return (
    <div className="cell large-9" id={`${sourceType}-${sourceId}-log-entries`}>
      {logEntries.map(le => (
        <LogEntry item={le} key={le.id} displayContext={sourceType} />
      ))}
      <hr />
      <ClassificationsContext.Consumer>
        {({ users }) => (
          <CreateLogEntry
            users={users}
            parentType={sourceType}
            parentId={sourceId}
            canBePropagated={canBePropagated}
          />
        )}
      </ClassificationsContext.Consumer>
    </div>
  );
};

Collection.propTypes = {
  sourceType: PropTypes.string.isRequired,
  sourceId: PropTypes.string.isRequired,
  canBePropagated: PropTypes.bool,
  logEntries: PropTypes.arrayOf(PropTypes.object)
};

export default Collection;
