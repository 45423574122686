import jQuery from 'jquery';
import castArray from 'lodash-es/castArray';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRxData } from 'rxdb-hooks';
import ActionsList from '../actions/ActionsList';
import useRelatedActions from '../actions/useRelatedActions';
import LatestActionMetadata from '../assets/LatestActionMetadata';
import OpenRecurringActionLink from '../assets/OpenRecurringActionLink';
import FormattedContent from '../common/content/FormattedContent';
import useRxDocument from '../common/hooks/useRxDocument';
import MapUI from '../common/map/index';
import LogEntryCollection from '../logEntries/Collection';

const NavigationAidShow = props => {
  const {
    match: { params },
    location
  } = props;

  const foundationRef = useRef();
  useEffect(
    () => jQuery(foundationRef.current).foundation() && undefined,
    [foundationRef]
  );

  const [associated, setAssociated] = useState({});
  const { result: navigationAid, isFetching } = useRxDocument(
    'navigationaids',
    params.id
  );

  const { openActions, closedActions } = useRelatedActions('asset', params.id);

  let logEntries = [];
  let logEntriesFetching = false;

  /* This hook is conditional only because a feature flag is checked.
  /* Webpack compilation will reduce this to a non-conditional statement
  /* based on the the feature flag value. */
  /* eslint-disable react-hooks/rules-of-hooks */
  if (FEATURE_NAVIGATION_AID_LOG_ENTRIES) {
    const logEntriesQueryConstructor = useCallback(
      collection =>
        collection.find({
          selector: {
            'source.id': { $eq: navigationAid && navigationAid.id },
            'source.type': { $eq: 'NavigationAid' }
          }
        }),
      [navigationAid]
    );

    ({ result: logEntries, isFetching: logEntriesFetching } = useRxData(
      'logentries',
      logEntriesQueryConstructor
    ));
  }
  /* eslint-enable react-hooks/rules-of-hooks */

  useEffect(
    () =>
      (async () => {
        if (!navigationAid) {
          return;
        }

        const parentOperationalArea = await navigationAid.populate(
          'parentOperationalArea'
        );

        const category = await navigationAid.populate('category');

        setAssociated({
          parentOperationalArea,
          operationalArea:
            parentOperationalArea &&
            parentOperationalArea.children.find(
              oa => oa.id === navigationAid.operationalArea
            ),
          category,
          subcategory:
            category &&
            category.children.find(cat => cat.id === navigationAid.subcategory)
        });
      })() && undefined,
    [navigationAid]
  );

  if (isFetching) {
    return 'Loading...';
  }

  if (!navigationAid) {
    return 'Not found';
  }

  return (
    <div
      ref={foundationRef}
      className="page page--white-background grid-x grid-margin-x"
    >
      <div className="small-8 cell large-9 panel summary large-offset-1 navigation-aid-summary">
        <div className="grid-x cell panelheader">
          <div className="cell auto">
            <h2>AtoN Details</h2>
          </div>
          <div className="cell small-4 large-3 text-right">
            <LatestActionMetadata
              openActions={openActions}
              closedActions={closedActions}
            />
          </div>
        </div>

        <div className="grid-x">
          <div className="cell auto">
            <dl className="data-list">
              <div className="grid-x row">
                <dt className="cell large-3">Almanac Number</dt>
                <dd className="cell large-9">
                  {navigationAid.identificationNumber}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">AtoN Name</dt>
                <dd className="cell large-9">{navigationAid.name}</dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">AtoN Type</dt>
                <dd className="cell large-9">{associated.subcategory?.name}</dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Site details</dt>
                <dd className="cell large-9">
                  <FormattedContent
                    content={navigationAid.locationDescription}
                  />
                </dd>
              </div>
              {navigationAid.location && (
                <>
                  <div className="grid-x row">
                    <dt className="cell large-3">Location</dt>
                    <dd className="cell large-9">
                      Lat: {navigationAid.location.lat}, Lng:{' '}
                      {navigationAid.location.lng}
                    </dd>
                  </div>
                  <MapUI
                    zoom={10}
                    graphicLocation={[
                      navigationAid.location.lng,
                      navigationAid.location.lat
                    ]}
                  />
                </>
              )}
              <div className="grid-x row">
                <dt className="cell large-3">Operational area</dt>
                <dd className="cell large-9">
                  {associated.parentOperationalArea &&
                  associated.parentOperationalArea.name
                    ? associated.parentOperationalArea.name + ' → '
                    : null}
                  {associated.operationalArea &&
                    associated.operationalArea.name}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Routine check period</dt>
                <dd className="cell large-9">
                  {navigationAid.checkIntervalMonths &&
                    `${navigationAid.checkIntervalMonths} months`}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Battery Type</dt>
                <dd className="cell large-9">
                  {navigationAid.metadata.battery_type || '-'}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Battery ID</dt>
                <dd className="cell large-9">
                  {navigationAid.metadata.battery_id &&
                    navigationAid.metadata.battery_id.length &&
                    castArray(navigationAid.metadata.battery_id).map(bid => (
                      <p key={bid}>
                        {bid.indexOf('B') === 0 ? bid : `B${bid}`}
                      </p>
                    ))}
                  {navigationAid.metadata.battery_id &&
                    !navigationAid.metadata.battery_id.length &&
                    navigationAid.metadata.battery_id}
                  {navigationAid.metadata.battery_id ? null : '-'}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Light Type</dt>
                <dd className="cell large-9">
                  {navigationAid.metadata.light_type || '-'}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Light ID</dt>
                <dd className="cell large-9">
                  {navigationAid.metadata.light_id || '-'}
                </dd>
              </div>
            </dl>
            {FEATURE_NAVIGATION_AID_LOG_ENTRIES &&
              (logEntriesFetching || isFetching ? (
                'Loading log entries...'
              ) : (
                <LogEntryCollection
                  sourceType={'NavigationAid'}
                  sourceId={navigationAid.id}
                  canBePropagated={false}
                  logEntries={logEntries.sort((a, b) => a.logTime - b.logTime)}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="cell small-4 large-2">
        <h5>
          <small>Add</small>
        </h5>
        <Link
          to={`/actions/new?assetId=${navigationAid.id}`}
          className="button tertiary expanded"
        >
          New Action
        </Link>
        <OpenRecurringActionLink openActions={openActions} />
        {FEATURE_NAVIGATION_AID_LOG_ENTRIES && (
          <a
            href={`#log-entry-create-NavigationAid-${navigationAid.id}-content`}
            className="button tertiary expanded"
          >
            Add Log Entry to AtoN
          </a>
        )}
        <h5>
          <small>Manage</small>
        </h5>
        <Link
          to={`/navigation_aids/${navigationAid.id}/edit`}
          className="button secondary expanded"
        >
          Edit AtoN
        </Link>
      </div>
      {openActions && openActions.length > 0 && (
        <ActionsList
          actions={openActions}
          expandedActions={[
            openActions.find(({ id }) => `#action-item-${id}` === location.hash)
          ]}
          // Use a cache-busting key when a hash is present, to ensure that the action item always re-expands
          key={
            location.hash &&
            location.hash.startsWith('#action-item-') &&
            `actions-list-rerender-${Math.random()}`
          }
          title="Open Actions"
        />
      )}
      {closedActions && closedActions.length > 0 && (
        <ActionsList actions={closedActions} title="Closed Actions" />
      )}
    </div>
  );
};

NavigationAidShow.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object
};

export default NavigationAidShow;
