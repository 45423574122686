import { useQuery } from '@apollo/client';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Form } from 'react-final-form';
import { useRxCollection } from 'rxdb-hooks';
import getIncidentProperties from './data/get-incident-properties';
import { extraClassifications } from './data/incidents-extra-classifications';
import { filters } from './data/incidents-filters';
import { headers } from './data/incidents-headers';
import useInitialFilterValues from '../common/hooks/useInitialFilterValues';
import useURLParams from '../common/hooks/useURLParams';
import { roundDown } from '../common/roundDown';
import DataTable from '../dataTable/data-table';
import FilterFormContainer from '../dataTable/filter-form-container';
import LimitSelector from '../dataTable/limit-selector';
import Pagination from '../dataTable/pagination';
import SearchForm from '../dataTable/search-form';
import INCIDENTS_QUERY from '../graphql/queries/incidents';
const queryString = require('query-string');

export default () => {
  const initialQuery = queryString.parse(location.search, {
    parseNumbers: true,
    arrayFormat: 'index'
  });

  const [limit, setLimit] = useState(initialQuery.limit || 50);
  const [offset, setOffset] = useState(initialQuery.offset || 0);
  const [sortBy, setSortBy] = useState({
    order: initialQuery.order || 'datetime',
    dir: initialQuery.dir || 'DESC'
  });
  const [appliedFilters, setAppliedFilters] = useState(() => {
    return (
      (initialQuery.appliedFilters &&
        initialQuery.appliedFilters.map(filter => JSON.parse(filter))) ||
      []
    );
  });
  const [search, setSearch] = useState(initialQuery.search || '');

  const { error, data, refetch, variables } = useQuery(INCIDENTS_QUERY, {
    variables: {
      offset: offset,
      limit: limit,
      sort: sortBy,
      filters: appliedFilters,
      search: search
    },
    fetchPolicy: 'cache-and-network'
  });

  if (error) {
    console.error(error);
  }

  const incidentsCollection = useRxCollection('incidents');
  useEffect(() => {
    if (!incidentsCollection) {
      return;
    }

    const collectionSubscription = incidentsCollection.$.subscribe(() => {
      console.log('Incidents collection changed, refetching table data');
      refetch();
    });

    return () => collectionSubscription.unsubscribe();
  }, [incidentsCollection, refetch]);

  useURLParams({ search, appliedFilters, limit, offset, sortBy });

  const handleLimitChange = useCallback(
    newLimit => {
      // round down to the nearest multiple of the new limit
      const newOffset = roundDown(offset, newLimit);
      setOffset(newOffset);

      setLimit(Number(newLimit));
      refetch({ limit, offset });
      window.scrollTo({ top: 0 });
    },
    [limit, offset, refetch]
  );

  const handleFilterChange = useCallback(
    e => {
      let newFilters = Object.keys(e).map(key => {
        const identifier = `${key}`;

        return { identifier, value: e[key] };
      });

      setOffset(0);
      setAppliedFilters([]);
      setAppliedFilters(newFilters);
      refetch(appliedFilters);
    },
    [appliedFilters, refetch]
  );

  const handleSortByChange = useCallback(
    newSortByName => {
      const {
        sort: { order, dir }
      } = variables;
      const currentSortByName = order;

      let newSort = { order: 'datetime', dir: 'DESC' };

      if (newSortByName && newSortByName !== currentSortByName) {
        newSort = { order: newSortByName, dir: 'ASC' };
      }

      if (newSortByName === currentSortByName && dir === 'ASC') {
        newSort = { order: currentSortByName, dir: 'DESC' };
      }

      if (newSortByName === 'datetime' && dir === 'DESC') {
        newSort = { order: 'datetime', dir: 'ASC' };
      }

      setSortBy(newSort);
      refetch({ sortBy });
    },
    [refetch, sortBy, variables]
  );

  function clearFilters(form) {
    setAppliedFilters([]);
    setSearch('');
    form.reset();
    refetch(appliedFilters);
  }

  const handleSearchChange = useCallback(
    newSearch => {
      setOffset(0);
      setSearch(newSearch['table-search']);
      refetch(search);
    },
    [refetch, search]
  );

  function goToPage(newPageNumber) {
    newPageNumber > 0 && newPageNumber < pageCount / limit;
    setOffset((Number(newPageNumber) - 1) * limit);
    refetch({ limit, offset });
  }

  const incidentsData = data && data.incidentCollection;
  const totalCount = incidentsData && data.incidentCollection.pageInfo.count;

  const pageCount =
    incidentsData && Math.ceil(incidentsData.pageInfo.count / variables.limit);

  const tableData = useMemo(
    () => incidentsData && incidentsData.collection.map(getIncidentProperties),
    [incidentsData]
  );

  return (
    <div className="panel">
      <div className="grid-x grid-margin-x align-justify">
        <Form
          onSubmit={handleSearchChange}
          placeholder={'Search Incidents'}
          render={SearchForm}
          initialValues={{ 'table-search': search }}
        />
        <Form
          resource={'incidents'}
          onSubmit={handleFilterChange}
          render={FilterFormContainer}
          initialValues={useInitialFilterValues(appliedFilters)}
          appliedFilters={appliedFilters}
          clearFilters={clearFilters}
          extraClassifications={extraClassifications}
          filters={filters}
        />
      </div>
      <DataTable
        resource={'incidents'}
        tableData={tableData}
        headers={headers}
        handleSortByChange={handleSortByChange}
      />
      <div className="grid-x grid-margin-x align-justify">
        <LimitSelector
          offset={offset}
          limit={limit}
          handleLimitChange={handleLimitChange}
          totalCount={totalCount}
        />
        <Pagination
          limit={limit}
          offset={offset}
          goToPage={goToPage}
          pageCount={pageCount}
        />
      </div>
    </div>
  );
};
