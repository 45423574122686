export const filters = [
  {
    label: 'Asset name',
    field: 'text',
    category: 'name',
    id: 'assetName'
  },
  {
    label: 'Asset category',
    field: 'custom-select',
    category: 'nonAtonAssetCategories',
    id: 'category'
  },
  {
    parentAttribute: 'category',
    childAttribute: 'subcategory',
    label: 'Asset sub-category',
    field: 'taxonomy-categories-select',
    category: 'assetCategories',
    id: 'subcategory'
  },
  {
    label: 'Assigned to',
    field: 'custom-select',
    category: 'users',
    id: 'assigned_to'
  }
];
