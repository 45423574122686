import React from 'react';
import { localize } from '../../common/date-format';
import StatusColumn from '../StatusColumn';

export default function getAssetProperties(asset) {
  const { id, name, locationDescription, category, subcategory, routineCheck } =
    asset;

  const nextCheck = routineCheck.collection[0] || {};

  const assignee = nextCheck.assignedTo ? nextCheck.assignedTo.name : '-';

  const assetCategory = subcategory
    ? `${category?.name} → ${subcategory?.name}`
    : category?.name;

  const checkDue = nextCheck.datetime
    ? localize(nextCheck.datetime, { format: 'defaultDate' })
    : '-';

  return [
    { value: id, skip: true },
    { value: name },
    { value: assetCategory },
    { value: locationDescription },
    { value: `${checkDue}` },
    { value: `${assignee}` },
    { value: <StatusColumn asset={asset} nextCheck={nextCheck} /> }
  ];
}
