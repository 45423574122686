function getCSRF() {
  const csrfElement =
    typeof window !== 'undefined' &&
    document.querySelector('meta[name=csrf-token]');

  if (csrfElement) {
    const csrfToken = csrfElement.getAttribute('content');

    return { 'X-CSRF-Token': csrfToken };
  }

  return {};
}

export const ENDPOINT = '/graphql';
export const HEADERS = { ...getCSRF(), accept: 'application/json' };
