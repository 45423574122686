import React from 'react';
import categorySummary from '../../common/categorySummary';
import { localize } from '../../common/date-format';
import StatusColumn from '../StatusColumn';

// id: 2,
// navigation_aid_id: 2,
// harbourmaster_vessel_id: nil,
// created_at: Mon, 17 Feb 2020 12:45:44 NZDT +13:00,
// updated_at: Mon, 17 Feb 2020 12:45:44 NZDT +13:00,
// datetime: Mon, 17 Feb 2020 12:45:44 NZDT +13:00,
// incident_id: 10192,
// instructions: "Expedita eum suscipit deserunt.",
// closed_at: nil,
// prioritised_at: Mon, 17 Feb 2020 12:45:43 NZDT +13:00,
// marine_farm_site_number: nil,
// asset_id: nil,
// operational_area_id: 153,
// category_id: 3,
// requested_by_id: 65,
// assigned_to_id: 66>,

export default async function getActionDocumentProperties(action) {
  const { id, sequenceNumber, urgency, datetime } = action;
  const assignee = (await action.populate('assignedTo')) || {};

  const category = (await action.populate('category')) || { children: [] };
  const subcategory =
    category.children.find(cat => cat.id === action.subcategory) || {};
  const parentOperationalArea = (await action.populate('operationalArea')) || {
    children: []
  };
  const operationalArea =
    parentOperationalArea.children.find(
      oa => oa.id === action.operationalArea
    ) || parentOperationalArea;

  const asset = await action.populate('asset');
  const assetName = asset?.name || 'N/A';

  return [
    { value: id, skip: true },
    { value: sequenceNumber },
    { value: urgency },
    { value: categorySummary(category, subcategory) },
    { value: assetName },
    { value: operationalArea.name },
    { value: localize(datetime, { format: 'defaultDate' }) },
    { value: assignee.name },
    { value: <StatusColumn action={action} /> }
  ];
}
