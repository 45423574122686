import PropTypes from 'prop-types';
import React from 'react';
import DesktopHeader from './DesktopHeader';
import TabletHeader from './TabletHeader';
import { ThemeProvider, useIsTabletDevice } from './common/theme-context';

const Header = ({
  serverRouted,
  hasPermissionToManageUsers,
  currentUserId
}) => {
  const { isTabletDevice } = useIsTabletDevice();
  if (isTabletDevice) {
    return <TabletHeader serverRouted={serverRouted} />;
  }

  return (
    <DesktopHeader
      hasPermissionToManageUsers={hasPermissionToManageUsers}
      currentUserId={currentUserId}
    />
  );
};

export default props => (
  <ThemeProvider>
    <Header {...props} />
  </ThemeProvider>
);

Header.propTypes = {
  hasPermissionToManageUsers: PropTypes.bool.isRequired,
  currentUserId: PropTypes.number.isRequired,
  serverRouted: PropTypes.bool
};
