import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useRxData } from 'rxdb-hooks';
import ActionsDataTable from './DataTable';
import ActionsOfflineDataTable from './OfflineDataTable';
import ClassificationsContext from '../common/ClassificationsContext';
import useNetworkConnectivityStatus from '../common/hooks/useNetworkConnectivityStatus';

export default function Actions() {
  const online = useNetworkConnectivityStatus();
  const filterOptions = useContext(ClassificationsContext);
  const { result: assets } = useRxData('assets', c => c.find());
  filterOptions.assets = assets || [];

  return (
    <div className="page">
      <h2> Actions Table {online || '(Offline)'}</h2>
      <div className="panel">
        {online ? (
          <ActionsDataTable filterOptions={filterOptions} />
        ) : (
          <ActionsOfflineDataTable />
        )}
        <div className="grid-x align-right">
          <Link to="/actions/new" className="button secondary" type="button">
            + Create new
          </Link>
        </div>
      </div>
    </div>
  );
}
