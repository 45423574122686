import { Workbox } from 'workbox-window';
globalThis.Harbours = globalThis.Harbours || {};

globalThis.Harbours.networkConnectivity = {
  intervalId: null,
  heartbeatInterval: process.env.RAILS_ENV === 'test' ? 500 : 5000,
  heartbeatTimeout: 3000,
  responseTimeThreshold: 3,
  responseTimeBufferSize: 3,
  heartbeatUrl: '/pwa/heartbeat',
  responseTimes: [],

  start: function () {
    console.log('Starting network connectivity monitor');
    this.intervalId =
      this.intervalId ||
      setInterval(() => this.heartbeat(), this.heartbeatInterval);
    this.heartbeat();
  },

  stop: function () {
    console.log('Stopping network connectivity monitor');
    this.intervalId && clearInterval(this.intervalId);
    this.intervalId = null;
  },

  heartbeat: function () {
    let controller = new AbortController();
    let timeoutId = setTimeout(() => controller.abort(), this.heartbeatTimeout);
    let requestStartedAt = new Date();
    fetch(this.heartbeatUrl, { signal: controller.signal })
      .then(
        () =>
          this._pushResponseTime(new Date() - requestStartedAt) &&
          clearTimeout(timeoutId)
      )
      .catch(() => this._pushResponseTime(this.heartbeatTimeout))
      .then(() => this.reportConnectivity());
  },

  reportConnectivity: function () {
    if (this.responseTimes.length < this.responseTimeThreshold) {
      return;
    }

    const responseTimes = this.responseTimes;
    const averageResponseTime =
      responseTimes.reduce((a, b) => a + b) / responseTimes.length;
    document.dispatchEvent(
      new CustomEvent('harbours.networkConnectivity', {
        detail: { averageResponseTime, responseTimes }
      })
    );
  },

  _pushResponseTime: function (responseTime) {
    const newResponseTimes = [responseTime, ...this.responseTimes];
    newResponseTimes.length > this.responseTimeBufferSize &&
      newResponseTimes.pop();

    this.responseTimes = newResponseTimes;
  }
};

globalThis.Harbours.application = {
  start: () => {
    globalThis.Harbours.serviceWorker.start();
    globalThis.Harbours.networkConnectivity.start();
  },
  stop: () => {
    globalThis.Harbours.caching.clear();
    globalThis.Harbours.serviceWorker.stop();
    globalThis.Harbours.networkConnectivity.stop();
  }
};

globalThis.Harbours.serviceWorker = {
  start: () => {
    console.log('Going to register the service worker');
    // Check that service workers are supported
    if ('serviceWorker' in navigator) {
      const wb = new Workbox('/service-worker.js');

      const showSkipWaitingPrompt = () => {
        // `event.wasWaitingBeforeRegister` will be false if this is
        // the first time the updated service worker is waiting.
        // When `event.wasWaitingBeforeRegister` is true, a previously
        // updated service worker is still waiting.
        // You may want to customize the UI prompt accordingly.

        if (
          confirm(
            'A new version of the Harbours application is available, activate now?'
          )
        ) {
          wb.addEventListener('controlling', () => window.location.reload());
          wb.messageSkipWaiting();
        }
      };

      // Add an event listener to detect when the registered
      // service worker has installed but is waiting to activate.
      wb.addEventListener('waiting', showSkipWaitingPrompt);

      wb.register();
    }
  },
  current: async () => {
    return navigator.serviceWorker.getRegistration();
  },
  stop: async () => {
    console.log('Going to stop the service worker');
    const registration = await globalThis.Harbours.serviceWorker.current();
    registration?.unregister();

    return registration;
  }
};

globalThis.Harbours.caching = {
  clearLocalStorage: () => window.localStorage.clear(),
  clear: () => {
    window.caches.keys().then(keys =>
      keys.forEach(key => {
        if (key.includes('-precache-')) {
          window.caches.delete(key);
        }
      })
    );
  }
};
