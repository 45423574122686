import { useCallback, useEffect } from 'react';

export default function IncidentDecorator(props) {
  const {
    values,
    form: { change }
  } = props;
  const { date, time, datetime } = values;

  const setDatetime = useCallback(() => {
    const newDateTime = date && time ? new Date(`${date} ${time}`) : undefined;
    change('datetime', newDateTime);
  }, [change, date, time]);

  useEffect(() => {
    if (date && time && !datetime) {
      setDatetime();
    }
  });

  useEffect(() => {
    setDatetime();
  }, [setDatetime]);

  return null;
}
