import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Provider as RxDbProvider } from 'rxdb-hooks';
import initializeDatabase from './initialize';

const Provider = ({ children }) => {
  const [db, setDb] = useState();
  const history = useHistory();
  useEffect(() => {
    initializeDatabase()
      .then(setDb)
      .catch(error => {
        console.error(error);
        history.push('/diagnostics');
      });
  }, [history]);

  return (
    <RxDbProvider db={db} idAttribute="id">
      {children}
    </RxDbProvider>
  );
};

Provider.propTypes = {
  children: PropTypes.node.isRequired
};

export default Provider;
