import React from 'react';
import { Form } from 'react-final-form';
import LocationSelect from '../../forms/location-select';

const handleFormSubmit = () => {};

export default function MapViewContainer() {
  return (
    /* eslint-disable react/jsx-no-bind */
    <Form
      onSubmit={handleFormSubmit}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} className="grid-x">
          <div className="cell small-auto large-8 large-offset-2">
            <fieldset className="form-section panel summary">
              <legend>
                <h2>Location</h2>
              </legend>
              <LocationSelect form={form} />
            </fieldset>
          </div>
        </form>
      )}
      /* eslint-enable react/jsx-no-bind */
    />
  );
}
