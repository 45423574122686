import { startCase } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRxData } from 'rxdb-hooks';
import ArchiveIncidentControl from './ArchiveIncidentControl';
import IncidentStatusControl from './IncidentStatusControl';
import InvolvedIndividualSummary from './InvolvedIndividualSummary';
import InvolvedVesselSummary from './InvolvedVesselSummary';
import formatBoolean from '../common/boolean-format';
import FormattedContent from '../common/content/FormattedContent';
import { localize } from '../common/date-format';
import Map from '../common/map/index';
import LogEntryCollection from '../logEntries/Collection';

const Summary = ({ incident }) => {
  const [associated, setAssociated] = useState({
    category: {},
    subcategory: {},
    operationalArea: {},
    parentOperationalArea: {},
    harboursUserGroup: {},
    harbourmasterVessel: {},
    parentHarboursUserGroup: {}
  });

  const { result: relatedActionIds } = useRxData('actions', actions =>
    actions.find({ selector: { incident: incident.id } })
  );

  const logEntriesQueryConstructor = useCallback(
    collection =>
      collection.find({
        selector: {
          $or: [
            { 'source.type': 'Incident', 'source.id': incident.id },
            {
              'source.type': 'Action',
              'source.id': { $in: relatedActionIds.map(action => action.id) },
              'propagated': true
            }
          ]
        }
      }),
    [relatedActionIds, incident]
  );
  const { result: logEntries, isFetching: logEntriesFetching } = useRxData(
    'logentries',
    logEntriesQueryConstructor
  );

  useEffect(
    () =>
      (async () => {
        if (!incident) {
          return;
        }

        const category = await incident.populate('category');
        const parentOperationalArea = await incident.populate(
          'parentOperationalArea'
        );
        const parentHarboursUserGroup = await incident.populate(
          'parentHarboursUserGroup'
        );

        setAssociated({
          parentOperationalArea,
          category,
          parentHarboursUserGroup,
          subcategory:
            (category &&
              category.children.find(ac => ac.id === incident.subcategory)) ||
            category,
          harboursUserGroup:
            parentHarboursUserGroup &&
            parentHarboursUserGroup.children.find(
              ac => ac.id === incident.harboursUserGroup
            ),
          operationalArea:
            parentOperationalArea &&
            parentOperationalArea.children.find(
              oa => oa.id === incident.operationalArea
            ),
          harbourmasterVessel: await incident.populate('harbourmasterVessel'),
          user: await incident.populate('user')
        });
      })() && undefined,
    [incident]
  );

  return (
    <>
      <div className="small-8 cell large-9 panel summary large-offset-1">
        <div className="grid-x cell panelheader">
          <div className="cell auto">
            <h2>
              Incident{' '}
              {incident.sequenceNumber && `#${incident.sequenceNumber}`} Details
            </h2>
          </div>
          <div className="cell small-4 large-3 text-right">
            {associated.user && `Opened by ${associated.user.name}`}
            {incident.createdAt && (
              <>
                <br />
                {localize(incident.createdAt, {
                  format: 'defaultDateTime'
                })}
              </>
            )}
            {incident.closedAt && (
              <>
                <br />
                Closed at{' '}
                {localize(incident.closedAt, {
                  format: 'defaultDateTime'
                })}
              </>
            )}
          </div>
        </div>
        <div className="grid-x">
          <div className="cell auto">
            <dl className="data-list">
              <div className="grid-x row">
                <dt className="cell large-3">Incident Date/Time</dt>
                <dd className="cell large-9">
                  {localize(incident.datetime, {
                    format: 'defaultDateTime'
                  })}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Status</dt>
                <dd className="cell large-9">{startCase(incident.status)}</dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Patrol Vessel</dt>
                <dd className="cell large-9">
                  {associated.harbourmasterVessel &&
                    associated.harbourmasterVessel.name}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Incident Category</dt>
                <dd className="cell large-9">
                  {associated.category && associated.category.name + ' → '}
                  {associated.subcategory && associated.subcategory.name}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Harbour user group</dt>
                <dd className="cell large-9">
                  {associated.parentHarboursUserGroup &&
                    associated.parentHarboursUserGroup.name}
                  {associated.parentHarboursUserGroup &&
                    associated.harboursUserGroup &&
                    associated.parentHarboursUserGroup !==
                      associated.harboursUserGroup &&
                    ` → ${associated.harboursUserGroup.name}`}
                </dd>
              </div>
              {incident.location && (
                <>
                  <div className="grid-x row">
                    <dt className="cell large-3">Location</dt>
                    <dd className="cell large-9">
                      Lat: {incident.location.lat}, Lng: {incident.location.lng}
                    </dd>
                  </div>
                  <Map
                    zoom={10}
                    graphicLocation={[
                      incident.location.lng,
                      incident.location.lat
                    ]}
                  />
                </>
              )}
              <div className="grid-x row">
                <dt className="cell large-3">Operational area</dt>
                <dd className="cell large-9">
                  {associated.parentOperationalArea &&
                    associated.parentOperationalArea.name + ' → '}
                  {associated.operationalArea &&
                    associated.operationalArea.name}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Incident description</dt>
                <dd className="cell large-9">
                  {<FormattedContent content={incident.description} />}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Incident log</dt>
                <dd className="cell large-9">
                  {logEntriesFetching ? (
                    'Loading incident log...'
                  ) : (
                    <LogEntryCollection
                      sourceType={'Incident'}
                      sourceId={incident.id}
                      canBePropagated={false}
                      logEntries={logEntries.sort(
                        (a, b) => a.logTime - b.logTime
                      )}
                    />
                  )}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Flag as critical</dt>
                <dd className="cell large-9">
                  {formatBoolean(incident.critical)}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Breach notice served</dt>
                <dd className="cell large-9">
                  {formatBoolean(incident.breachNoticeServed)}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Body Cam</dt>
                <dd className="cell large-9">
                  {formatBoolean(incident.bodyCamAttachments)}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Device</dt>
                <dd className="cell large-9">
                  {formatBoolean(incident.deviceAttachments)}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Log books and paperwork</dt>
                <dd className="cell large-9">
                  {formatBoolean(incident.logBookAttachments)}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Individuals Involved </dt>
                <dd className="cell large-9">
                  {incident.involvedIndividuals &&
                    incident.involvedIndividuals.map(ind => (
                      <InvolvedIndividualSummary
                        key={JSON.stringify(ind)}
                        individual={ind}
                      />
                    ))}
                </dd>
              </div>
              <div className="grid-x row">
                <dt className="cell large-3">Vessels Involved</dt>
                <dd className="cell large-9">
                  {incident.involvedVessels &&
                    incident.involvedVessels.map(vess => (
                      <InvolvedVesselSummary
                        key={JSON.stringify(vess)}
                        vessel={vess}
                      />
                    ))}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div className="cell small-4 large-2">
        <h5>
          <small>Add</small>
        </h5>
        <Link
          to={`/actions/new?incidentId=${incident.id}`}
          className="button tertiary expanded"
        >
          New Action
        </Link>
        <a
          href={`#log-entry-create-Incident-${incident.id}-content`}
          className="button tertiary expanded"
        >
          Add Log Entry to Incidents
        </a>
        <h5>
          <small>Manage</small>
        </h5>
        <Link
          to={`/incidents/${incident.id}/edit`}
          className="button secondary expanded"
        >
          Edit Incident
        </Link>
        <IncidentStatusControl incident={incident} />
        <ArchiveIncidentControl incident={incident} />
      </div>
    </>
  );
};

Summary.propTypes = {
  incident: PropTypes.object.isRequired
};
export default Summary;
