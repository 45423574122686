import subDays from 'date-fns/subDays';
import offsetLocalTimezone from './offsetLocalTimezone';

export default function checkStatus(action, { overdueAfterDays }) {
  if (action.datetime === undefined) {
    return null;
  }

  if (action.closedAt) {
    return 'Closed';
  }

  const today = Date.now();
  const overdueAt = subDays(today, overdueAfterDays);
  // When online, this is a date string. When offline this is ms in int form. We want this in ms form.
  const actionDateTime = Number.isInteger(action.datetime)
    ? action.datetime
    : Date.parse(action.datetime);

  const actionDueDate = offsetLocalTimezone(actionDateTime);

  if (actionDueDate < overdueAt) {
    return 'Overdue';
  } else if (actionDueDate < today) {
    return 'Due';
  }

  return 'Open';
}
