import { FORM_ERROR } from 'final-form';
import { merge as mergeObject } from 'lodash-es';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';
import { useRxCollection, useRxData } from 'rxdb-hooks';
import IncidentAction from './IncidentAction';
import ReviewSubmission from './ReviewSubmission';
import IncidentFields from './incident-fields';
import ClassificationsContext from '../common/ClassificationsContext';
import CurrentUserContext from '../common/CurrentUserContext';
import ApplicationConfigContext from '../common/application-config-context';
import leftPad from '../common/left-pad';
import { useIsTabletDevice } from '../common/theme-context';
import { UUID } from '../db/helpers';
import { POINT_DEFAULT_SRID } from '../db/schemas/point';
import {
  buildStorageKey,
  loadAutosaveItem,
  removeAutosaveItem
} from '../forms/autosave/autosave-helpers';
import Wizard from '../forms/wizard';

const now = new Date();

function buildInitialValues(isTabletDevice, { currentUserId }) {
  let values = {
    id: UUID.generate(),
    date: `${now.getFullYear()}-${leftPad(now.getMonth() + 1)}-${leftPad(
      now.getDate()
    )}`,
    time: `${leftPad(now.getHours())}:${leftPad(now.getMinutes())}`,
    user: currentUserId,
    parentHarboursUserGroup: {}.id,
    harbourmasterVessel: {}.id
  };
  if (isTabletDevice) {
    return values;
  }
  const actionValues = {
    incidentAction: {
      date: `${now.getFullYear()}-${leftPad(now.getMonth() + 1)}-${leftPad(
        now.getDate()
      )}`
    }
  };

  return { ...values, ...actionValues };
}

const Create = props => {
  const { isTabletDevice } = useIsTabletDevice();
  const [warning, setWarning] = useState(false);
  const incidents = useRxCollection('incidents');
  const actions = useRxCollection('actions');
  const history = useHistory();
  const storageKey = buildStorageKey('incident-create');
  const storedData = loadAutosaveItem(storageKey)
    ? JSON.parse(loadAutosaveItem(storageKey))
    : null;
  const storedForm = storedData && storedData.form;

  const initialValues = useMemo(() => {
    const builtInitialValues = buildInitialValues(isTabletDevice, {
      currentUserId: props.currentUserId
    });
    const storedInitialValues = storedForm || {};

    return mergeObject({}, storedInitialValues, builtInitialValues);
  }, [isTabletDevice, storedForm, props.currentUserId]);

  useEffect(() => {
    if (!storedForm) {
      setWarning(false);

      return;
    }
    setWarning('saved');
  }, [storedForm]);

  const onSubmit = async values => {
    const params = { ...values };
    let submissionErrors = {};
    const { longitude, latitude, incidentAction: action } = params;
    delete params.longitude;
    delete params.latitude;
    delete params.date;
    delete params.time;
    delete params.incidentAction;

    params.datetime = new Date(params.datetime).getTime();

    if (!params.operationalArea && params.parentOperationalArea) {
      params.operationalArea = params.parentOperationalArea;
    }

    if (!params.subcategory && params.category) {
      params.subcategory = params.category;
    }

    if (longitude && latitude) {
      params.location = {
        lng: parseFloat(longitude),
        lat: parseFloat(latitude),
        srid: POINT_DEFAULT_SRID
      };
    }

    params.involvedIndividuals.forEach(individual => {
      const { day, month, year } = individual.dateOfBirth || {};

      // if one of the parts is missing, drop everything
      if (!day || !month || !year) {
        individual.dateOfBirth = null;

        return;
      }

      // ISO8601DateTime requires day & month to be xx
      individual.dateOfBirth = [year, month, day]
        .map(s => s.padStart(2, '0'))
        .join('-');
    });

    params.involvedIndividuals = params.involvedIndividuals.filter(
      individual => individual
    );
    params.involvedVessels = params.involvedVessels.filter(vessel => vessel);

    try {
      // If there was a problem creating the action, this allows the
      // form to be 'resubmitted' even though it already exists.
      const incident = await incidents.incrementalUpsert(params);

      if (action && !isTabletDevice) {
        action.datetime = new Date(action.datetime).getTime();
        action.incident = incident.id;

        if (action.longitude && action.latitude) {
          action.location = {
            lng: Number(action.longitude),
            lat: Number(action.latitude),
            srid: POINT_DEFAULT_SRID
          };
        }

        delete action.isPriority;
        delete action.date;
        delete action.latitude;
        delete action.longitude;

        await actions.insert(action);
      }

      removeAutosaveItem(storageKey);
      history.push(
        `/incidents/${incident.id}?status_message=incidents.create.success`
      );
    } catch (err) {
      // If we get validation errors, treat this as an error, but present
      // a generic message.
      console.error(err);
      submissionErrors[[FORM_ERROR]] = `
        There were some problems submitting this incident.
        Please check fields and try again.
      `;
    }

    return submissionErrors;
  };

  const wizardPages = () => {
    let pages = [
      <IncidentFields
        warning={warning}
        setWarning={setWarning}
        storageKey={storageKey}
        key="wizard-step-1"
        title="Create new incident"
        {...props}
      />
    ];
    if (!isTabletDevice) {
      pages.push(
        <IncidentAction
          warning={warning}
          setWarning={setWarning}
          storageKey={storageKey}
          key="wizard-step-2"
          title="Schedule an Action"
          {...props}
        />
      );
    }
    pages.push(
      <ReviewSubmission
        storageKey={storageKey}
        key="wizard-step-3"
        title="Submit"
      />
    );

    return pages;
  };

  return (
    <Wizard
      onSubmit={useCallback(onSubmit, [
        incidents,
        actions,
        history,
        isTabletDevice,
        storageKey
      ])}
      initialValues={initialValues}
    >
      {wizardPages()}
    </Wizard>
  );
};

Create.propTypes = {
  currentUserId: PropTypes.string.isRequired,
  harbourUserGroups: PropTypes.arrayOf(PropTypes.object),
  users: PropTypes.arrayOf(PropTypes.object),
  harbourmasterVessels: PropTypes.arrayOf(PropTypes.object),
  incidentCategories: PropTypes.arrayOf(PropTypes.object),
  assets: PropTypes.arrayOf(PropTypes.object),
  actionCategories: PropTypes.arrayOf(PropTypes.object)
};

export default function WrappedCreate() {
  const { user } = useContext(CurrentUserContext);
  const classificatons = useContext(ClassificationsContext);
  const { result: assets } = useRxData('assets', coll => coll.find());
  const { defaultPosition, operationalBounds } = useContext(
    ApplicationConfigContext
  );

  return (
    <Create
      operationalBounds={operationalBounds}
      defaultPosition={defaultPosition}
      assets={assets}
      currentUserId={user.id}
      {...classificatons}
    />
  );
}
