import { loadModules } from 'esri-loader';
import { throttle as _throttle } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import useNetworkConnectivityStatus from '../hooks/useNetworkConnectivityStatus';

const ESRI_BASE_MAP_LAYER_ID = '33821abea7664e47b6990878deb58e2d';
const CENTER_SYMBOL_SIZE = 50;
const CENTER_SYMBOL_PATH =
  'M256,0c-70.703,0-128,57.313-128,128c0,51.5,30.563,95.563,74.375,115.875L256,512l53.625-268.125 \t\tC353.438,223.563,384,179.5,384,128C384,57.313,326.688,0,256,0z M256,192c-35.344,0-64-28.656-64-64s28.656-64,64-64 \t\ts64,28.656,64,64S291.344,192,256,192z';

const fixedLocationMarkerSymbol = {
  type: 'simple-marker',
  size: 15,
  outline: { width: 2 },
  color: [255, 23, 23, 0.5]
};

const centerMarkerSymbol = {
  type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
  style: 'path',
  path: CENTER_SYMBOL_PATH,
  size: CENTER_SYMBOL_SIZE,
  color: [0, 0, 0, 0.85],
  yoffset: CENTER_SYMBOL_SIZE / 2
};

export default function WebMapWrapper(props) {
  const { graphicLocation, zoom } = props;
  const mapRef = useRef();

  return (
    <WebMapView graphicLocation={graphicLocation} zoom={zoom} mapRef={mapRef} />
  );
}

WebMapWrapper.propTypes = {
  graphicLocation: PropTypes.array,
  zoom: PropTypes.number
};

export function WebMapView(props) {
  let {
    center,
    mapRef,
    zoom,
    graphicLocation,
    geoLocationSource = 'map',
    form,
    locationDidUpdate,
    locationUpdateFrequencyMs = 100,
    prefix
  } = props;

  const [view, setView] = useState();
  const updateLocation =
    locationDidUpdate &&
    (locationUpdateFrequencyMs
      ? _throttle(locationDidUpdate, locationUpdateFrequencyMs)
      : locationDidUpdate);

  if (!center && graphicLocation) {
    center = graphicLocation;
  }
  const isOnline = useNetworkConnectivityStatus();

  useEffect(() => {
    const markerSymbol = graphicLocation
      ? fixedLocationMarkerSymbol
      : centerMarkerSymbol;

    if (!isOnline) {
      return setView(null);
    }

    if (!view) {
      initializeMap({
        center,
        geoLocationSource,
        mapRef,
        setView,
        updateLocation,
        prefix,
        form,
        zoom
      });
    }

    if (view) {
      drawGraphic({ view, center, markerSymbol });
    }
  }, [
    view,
    center,
    mapRef,
    setView,
    zoom,
    graphicLocation,
    geoLocationSource,
    form,
    updateLocation,
    prefix,
    isOnline
  ]);

  return isOnline ? <div className="webmap map" ref={mapRef} /> : null;
}

WebMapView.propTypes = {
  center: PropTypes.array,
  form: PropTypes.object,
  graphicLocation: PropTypes.array,
  geoLocationSource: PropTypes.object,
  mapRef: PropTypes.object,
  prefix: PropTypes.string,
  locationDidUpdate: PropTypes.func,
  locationUpdateFrequencyMs: PropTypes.number,
  zoom: PropTypes.number
};

async function drawGraphic({ view, center, markerSymbol }) {
  const [Graphic] = await loadModules(['esri/Graphic'], { css: true });

  const point = {
    type: 'point', // autocasts as new Point()
    longitude: center[0],
    latitude: center[1]
  };

  const pointGraphic = new Graphic({
    geometry: point,
    symbol: markerSymbol
  });

  view.graphics.removeAll();
  view.graphics.add(pointGraphic);
}

async function initializeMap({
  center,
  form,
  geoLocationSource,
  mapRef,
  setView,
  updateLocation,
  prefix,
  zoom
}) {
  const [WebMap, MapView] = await loadModules(
    ['esri/WebMap', 'esri/views/MapView'],
    { css: true }
  );

  const map = new WebMap({
    portalItem: {
      id: ESRI_BASE_MAP_LAYER_ID
    }
  });

  const newView = new MapView({
    container: mapRef.current,
    map: map,
    center: center,
    zoom: zoom
  });

  setView(newView);

  // const track = new Track({
  //   view: newView
  // });

  // add tracking user lat lng when the view is ready
  // newView.ui.add(track, 'top-left');
  // newView.when(() => {
  // track.start();
  // });

  newView.on(['drag', 'mouse-wheel'], () => {
    if (updateLocation) {
      const newCenter = [newView.center.longitude, newView.center.latitude];
      updateLocation({
        form,
        currentCenter: newCenter,
        geoLocationSource,
        prefix
      });
    }
  });

  // Reset center at end of scroll because it drifts at end of zoom
  newView.watch('stationary', newValue => {
    if (newValue === true && !!updateLocation) {
      const newCenter = [newView.center.longitude, newView.center.latitude];
      updateLocation({
        form,
        currentCenter: newCenter,
        geoLocationSource,
        prefix
      });
    }
  });

  return () => {
    if (newView) {
      // destroy the map view
      newView.container = null;
    }
  };
}
