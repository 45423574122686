import React from 'react';

const toFullName = ({ firstNames, middleNames, lastNames }) => {
  return [firstNames, middleNames, lastNames].filter(name => !!name).join(' ');
};

export function involvedIndividualsColumn(involvedIndividuals) {
  if (involvedIndividuals.length === 0) {
    return '';
  }
  let names = involvedIndividuals
    .filter((_individual, idx) => idx < 2)
    .map(individual => toFullName(individual))
    .join(',\n');

  if (involvedIndividuals.length >= 3) {
    names += `,\n+ ${involvedIndividuals.length - 2} more`;
  }

  return (
    <div>
      {names.split('\n').map((x, i) => (
        <span key={`name_${i}`}>
          {x}
          <br />
        </span>
      ))}
    </div>
  );
}
