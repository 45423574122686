import React from 'react';
import { localize } from '../../common/date-format';
import detectBehaviour from '../../common/detectBehaviour';
import StatusColumn from '../StatusColumn';

export default async function getAssetDocumentProperties(
  asset,
  actions,
  users
) {
  const { id, name, locationDescription } = asset;

  const routineChecks = (
    await Promise.all(
      actions.map(
        async action =>
          action.asset === asset.id &&
          !action.closedAt &&
          (await detectBehaviour(action, 'recurring')) &&
          action
      )
    )
  ).filter(Boolean);

  const nextCheck =
    routineChecks.sort((a, b) => a.datetime - b.datetime || 0)[0] || {};

  const nextCheckUser = users.find(user => user.id === nextCheck.assignedTo);
  const assignedTo = nextCheckUser ? nextCheckUser.name : '-';

  const category = (await asset.populate('category')) || {
    children: []
  };

  const subcategory = category.children.find(
    cat => cat.id === asset.subcategory
  );

  const checkDue = nextCheck.datetime
    ? localize(nextCheck.datetime, { format: 'defaultDate' })
    : '-';

  const assetCategory = subcategory
    ? `${category?.name} → ${subcategory?.name}`
    : category?.name;

  return [
    { value: id, skip: true },
    { value: name },
    { value: assetCategory },
    { value: locationDescription },
    { value: `${checkDue}` },
    { value: `${assignedTo}` },
    { value: <StatusColumn asset={asset} nextCheck={nextCheck} /> }
  ];
}
