export const filters = [
  {
    label: 'AtoN type',
    field: 'custom-select',
    category: 'atonCategories',
    id: 'subcategory'
  },
  {
    label: 'Operational Area',
    field: 'custom-select',
    category: 'operationalAreas',
    id: 'parent_operational_area_id'
  },
  {
    parentAttribute: 'parent_operational_area_id',
    childAttribute: 'operational_area_id',
    label: 'Sub Operational Area',
    field: 'taxonomy-categories-select',
    category: 'operationalAreas',
    id: 'operational_area_id'
  },
  {
    label: 'Almanac Number',
    field: 'text',
    category: 'number',
    id: 'identification_number'
  },
  {
    label: 'Light ID',
    field: 'pattern',
    id: 'light_id',
    pattern: '^L[0-9-]{3,5}$'
  },
  {
    label: 'Battery ID',
    field: 'pattern',
    id: 'battery_id',
    pattern: '^B[0-9]{2,3}$'
  },
  {
    label: 'Incidents',
    field: 'custom-select',
    id: 'has_incidents',
    category: 'hasIncidents'
  }
];
