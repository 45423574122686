import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ClassificationsContext from '../common/ClassificationsContext';
import FormattedContent from '../common/content/FormattedContent';
import { localize } from '../common/date-format';
import useRxDocument from '../common/hooks/useRxDocument';

const classificationName = (records, id) =>
  records.find(r => r.id.toString() === (id && id.toString()))?.name;

const Summary = ({ params }) => {
  const {
    assignedTo,
    category,
    subcategory,
    datetime,
    instructions,
    longitude,
    latitude,
    mooringNumber,
    marineFarmSiteNumber,
    asset: assetId,
    operationalArea,
    parentOperationalArea,
    urgency
  } = params;
  const { actionCategories, users, operationalAreas } = useContext(
    ClassificationsContext
  );

  const { result: asset } = useRxDocument('assets', assetId);

  const parentOperationalAreaName =
    parentOperationalArea &&
    classificationName(operationalAreas, parentOperationalArea);

  const operationalAreaName = operationalArea
    ? classificationName(
        operationalAreas.find(
          c => c.id.toString() === parentOperationalArea.toString()
        ).children,
        operationalArea
      )
    : parentOperationalAreaName;

  const categoryName = classificationName(actionCategories, category);
  const subcategoryName = classificationName(
    actionCategories.find(c => c.id.toString() === category.toString())
      .children,
    subcategory
  );

  const categorySummary =
    categoryName !== subcategoryName
      ? `${categoryName} → ${subcategoryName}`
      : `${categoryName}`;

  return (
    <div className="panel summary">
      <h3>
        {category && `${classificationName(actionCategories, category)}`}
        &nbsp;Action Summary
      </h3>
      <dl className="data-list">
        <div className="grid-x row">
          <dt className="cell large-3">Date:</dt>
          <dd className="cell large-9">
            {localize(datetime, { format: 'defaultDate' })}
          </dd>
        </div>
        <div className="grid-x row">
          <dt className="cell large-3">Asset:</dt>
          <dd className="cell large-9">{asset && asset.name}</dd>
        </div>
        <div className="grid-x row">
          <dt className="cell large-3">Action description:</dt>
          <dd className="cell large-9">
            <FormattedContent content={instructions} />
          </dd>
        </div>
        <div className="grid-x row">
          <dt className="cell large-3">Urgency:</dt>
          <dd className="cell large-9">{urgency || 'normal'}</dd>
        </div>
        <div className="grid-x row">
          <dt className="cell large-3">Operational area</dt>
          <dd className="cell large-9">
            {`${parentOperationalAreaName} → ${operationalAreaName}`}
          </dd>
        </div>
        <div className="grid-x row">
          <dt className="cell large-3">Location</dt>
          <dd className="cell large-9">
            {longitude && latitude && `Lat: ${latitude}, Lng: ${longitude}`}
          </dd>
        </div>

        <div className="grid-x row">
          <dt className="cell large-3">Marine farm site number:</dt>
          <dd className="cell large-9">{marineFarmSiteNumber}</dd>
        </div>
        <div className="grid-x row">
          <dt className="cell large-3">Mooring number:</dt>
          <dd className="cell large-9">{mooringNumber}</dd>
        </div>
        <div className="grid-x row">
          <dt className="cell large-3">Action category</dt>
          <dd className="cell large-9">
            {category && subcategory && categorySummary}
          </dd>
        </div>
        <div className="grid-x row">
          <dt className="cell large-3">Assigned to:</dt>
          <dd className="cell large-9">
            {classificationName(users, assignedTo)}
          </dd>
        </div>
      </dl>
    </div>
  );
};
Summary.propTypes = {
  params: PropTypes.object
};

const ReviewSubmission = ({ params }) => {
  return <Summary params={params} />;
};
ReviewSubmission.propTypes = {
  params: PropTypes.object
};

export default ReviewSubmission;
