import { UUID } from '../helpers';
export default {
  type: 'object',
  version: 2,
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: UUID.maxLength, final: true },
    content: { type: 'string' },
    createdBy: { type: 'string', ref: 'users' },
    propagated: { type: 'boolean' },
    updatedAt: { type: 'integer' },
    logTime: { type: 'integer' },
    editor: { type: ['string', 'null'], ref: 'users' },
    source: {
      type: 'object',
      properties: {
        id: { type: 'string' },
        type: { type: 'string' }
      },
      required: ['id', 'type']
    }
  },
  required: ['id', 'content', 'logTime', 'source']
};
