import PropTypes from 'prop-types';
import React from 'react';
import GoToPageButton from './go-to-page-button';

export default function Pagination({
  goToPage,
  pageCount = 1,
  offset,
  limit,
  maxPages = 10
}) {
  const currentPage = offset / limit + 1;

  function canPreviousPage() {
    return offset > 0;
  }

  function canNextPage() {
    return currentPage < pageCount;
  }

  function callGoToPage(newPageNumber) {
    window.scrollTo({ top: 0 });
    goToPage(newPageNumber);
  }

  function fullPageList() {
    return [...Array(pageCount).keys()].map((keyNum, key) => {
      return pageLink(keyNum, key);
    });
  }

  function reducedPageList() {
    let minPage = 0;
    let maxPage = 9;
    if (currentPage > 8) {
      minPage = pageCount - 8;
      maxPage = pageCount + 1;

      while (currentPage <= minPage || currentPage > maxPage) {
        maxPage = minPage + 1;
        minPage = maxPage - 7;
      }
    }

    let pageArray = Array.from(
      { length: maxPage - (minPage + 1) },
      (x, i) => minPage + i
    ).map((keyNum, key) => {
      return pageLink(keyNum, key);
    });

    if (minPage !== 0) {
      if (minPage !== 1) {
        pageArray.unshift(<li className="ellipsis" aria-hidden="true" />);
      }

      pageArray.unshift(pageLink(0, 0));
    }

    if (maxPage !== pageCount + 1) {
      if (maxPage !== pageCount) {
        pageArray.push(<li className="ellipsis" aria-hidden="true" />);
      }

      pageArray.push(pageLink(pageCount - 1, pageCount - 1));
    }

    return pageArray;
  }

  function pageLink(keyNum, key) {
    const pageNum = keyNum + 1;

    return pageNum === currentPage ? (
      <li key={key} className="current">
        <span className="show-for-sr">You are on page</span> {pageNum}
      </li>
    ) : (
      <li key={key}>
        <GoToPageButton
          onClick={callGoToPage}
          clickValue={pageNum}
          ariaLabel={'Page ' + pageNum}
          name={'Page ' + pageNum}
        >
          {pageNum}
        </GoToPageButton>
      </li>
    );
  }

  return (
    <nav className="" aria-label="Pagination">
      <ul className="pagination">
        <li className="pagination-previous">
          <GoToPageButton
            onClick={callGoToPage}
            clickValue={currentPage - 1}
            ariaLabel="Previous page"
            name="Previous page"
            disabled={!canPreviousPage()}
          >
            &lsaquo;<span className="show-for-sr">page</span>
          </GoToPageButton>
        </li>

        {pageCount <= maxPages ? fullPageList() : reducedPageList()}

        <li className="pagination-next">
          <GoToPageButton
            onClick={callGoToPage}
            clickValue={currentPage + 1}
            ariaLabel="Next page"
            name="Next page"
            disabled={!canNextPage()}
          >
            &rsaquo;<span className="show-for-sr">page</span>
          </GoToPageButton>
        </li>
      </ul>
    </nav>
  );
}

Pagination.propTypes = {
  canPreviousPage: PropTypes.func,
  canNextPage: PropTypes.func,
  currentPage: PropTypes.number,
  goToPage: PropTypes.func,
  pageCount: PropTypes.number,
  offset: PropTypes.number,
  limit: PropTypes.number,
  maxPages: PropTypes.number
};
