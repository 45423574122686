import { useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { useRxCollection } from 'rxdb-hooks';
import { filters } from './data/asset-filters';
import { headers } from './data/asset-headers';
import getAssetProperties from './data/get-asset-properties';
import useInitialFilterValues from '../common/hooks/useInitialFilterValues';
import useURLParams from '../common/hooks/useURLParams';
import { roundDown } from '../common/roundDown';
import DataTable from '../dataTable/data-table';
import FilterFormContainer from '../dataTable/filter-form-container';
import LimitSelector from '../dataTable/limit-selector';
import Pagination from '../dataTable/pagination';
import SearchForm from '../dataTable/search-form';
import { collection as ASSETS_QUERY } from '../graphql/queries/assets';
const queryString = require('query-string');

function AssetsData() {
  const initialQuery = queryString.parse(location.search, {
    parseNumbers: true,
    arrayFormat: 'index'
  });

  const defaultFilters = useMemo(
    () => [
      {
        identifier: 'excludeCategory',
        value: '1'
      }
    ],
    []
  );

  const [limit, setLimit] = useState(initialQuery.limit || 50);
  const [offset, setOffset] = useState(initialQuery.offset || 0);
  const [search, setSearch] = useState(initialQuery.search || '');
  const [sortBy, setSortBy] = useState({
    order: initialQuery.order || 'sequenceNumber',
    dir: initialQuery.dir || 'DESC'
  });
  const [userFilters, setUserFilters] = useState(
    () =>
      (initialQuery.appliedFilters &&
        initialQuery.appliedFilters.map(filter => JSON.parse(filter))) ||
      []
  );

  const [appliedFilters, setAppliedFilters] = useState(() => {
    return [...userFilters, ...defaultFilters];
  });

  const { error, data, refetch, variables } = useQuery(ASSETS_QUERY, {
    variables: {
      offset: offset,
      limit: limit,
      sort: sortBy,
      filters: appliedFilters,
      search: search
    },
    fetchPolicy: 'cache-and-network'
  });

  if (error) {
    console.error(error);
  }

  useURLParams({ search, appliedFilters: userFilters, limit, offset, sortBy });

  const assetsCollection = useRxCollection('assets');

  useEffect(() => {
    if (!assetsCollection) {
      return;
    }

    const collectionSubscription = assetsCollection.$.subscribe(() => {
      console.log('Asset collection changed, refetching table data');
      refetch();
    });

    return () => collectionSubscription.unsubscribe();
  }, [assetsCollection, refetch]);

  const handleLimitChange = useCallback(
    newLimit => {
      // round down to the nearest multiple of the new limit
      const newOffset = roundDown(offset, newLimit);
      setOffset(newOffset);

      setLimit(Number(newLimit));
      refetch({ limit, offset });
      window.scrollTo({ top: 0 });
    },
    [limit, offset, refetch]
  );

  const handleSortByChange = useCallback(
    newSortByName => {
      const {
        sort: { order, dir }
      } = variables;
      const currentSortByName = order;

      let newSort = { order: 'sequenceNumber', dir: 'DESC' };

      if (newSortByName && newSortByName !== currentSortByName) {
        newSort = { order: newSortByName, dir: 'ASC' };
      }

      if (newSortByName === currentSortByName && dir === 'ASC') {
        newSort = { order: currentSortByName, dir: 'DESC' };
      }

      if (newSortByName === 'sequenceNumber' && dir === 'DESC') {
        newSort = { order: 'sequenceNumber', dir: 'ASC' };
      }

      setSortBy(newSort);
      refetch({ sortBy });
    },
    [refetch, sortBy, variables]
  );

  const handleFilterChange = useCallback(
    e => {
      let newFilters = Object.keys(e).map(key => {
        const identifier = `${key}`;

        return { identifier, value: e[key] };
      });

      setOffset(0);
      setUserFilters(newFilters);
      setAppliedFilters([...newFilters, ...defaultFilters]);
      refetch(appliedFilters);
    },
    [appliedFilters, defaultFilters, refetch]
  );

  const handleSearchChange = useCallback(
    newSearch => {
      setOffset(0);
      setSearch(newSearch['table-search']);
      refetch(search);
    },
    [refetch, search]
  );

  function clearFilters(form) {
    setUserFilters([]);
    setAppliedFilters(defaultFilters);
    setSearch('');
    form.reset();
    refetch(appliedFilters);
  }

  function goToPage(newPageNumber) {
    newPageNumber > 0 && newPageNumber < pageCount / limit;
    setOffset((Number(newPageNumber) - 1) * limit);
    refetch({ limit, offset });
  }

  const assetsData = data && data.assetCollection;
  const totalCount = assetsData && data.assetCollection.pageInfo.count;

  const pageCount =
    assetsData && Math.ceil(assetsData.pageInfo.count / variables.limit);

  const tableData = useMemo(
    () => assetsData && assetsData.collection.map(getAssetProperties),
    [assetsData]
  );

  return (
    <>
      <div className="grid-x grid-margin-x align-justify">
        <Form
          onSubmit={handleSearchChange}
          placeholder={'Search Assets'}
          render={SearchForm}
          initialValues={{ 'table-search': search }}
        />
        <Form
          resource={'assets'}
          onSubmit={handleFilterChange}
          render={FilterFormContainer}
          appliedFilters={userFilters}
          clearFilters={clearFilters}
          initialValues={useInitialFilterValues(userFilters)}
          filters={filters}
        />
      </div>
      <DataTable
        resource={'manage-assets'}
        tableData={tableData}
        headers={headers}
        handleSortByChange={handleSortByChange}
      />
      <div className="grid-x align-justify">
        <LimitSelector
          offset={offset}
          limit={limit}
          handleLimitChange={handleLimitChange}
          totalCount={totalCount}
        />
        <Pagination
          goToPage={goToPage}
          limit={limit}
          offset={offset}
          pageCount={pageCount}
        />
      </div>
    </>
  );
}

export default AssetsData;
