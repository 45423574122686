import React, { useEffect, useState } from 'react';
import { useRxData } from 'rxdb-hooks';
import getNavigationAidDocumentProperties from './data/get-navigation-aid-document-properties';
import { headers } from './data/navigation-aid-headers';
import DataTable from '../dataTable/data-table';

export default () => {
  const [tableData, setTableData] = useState([]);

  const { result: navigationAids, isLoading } = useRxData(
    'navigationaids',
    collection => collection.find()
  );

  const { result: actions } = useRxData('actions', collection =>
    collection.find()
  );

  const { result: incidents } = useRxData('incidents', collection =>
    collection.find()
  );

  const unsortableHeaders = [...headers];
  unsortableHeaders.forEach(header => (header.sortable = false));

  useEffect(() => {
    navigationAids &&
      actions &&
      incidents &&
      Promise.all(
        navigationAids.map(aton =>
          getNavigationAidDocumentProperties(aton, actions, incidents)
        )
      ).then(setTableData);
  }, [navigationAids, actions, incidents]);

  if (isLoading || !tableData.length) {
    return 'Loading...';
  }

  return (
    <DataTable
      resource={'navigation_aids'}
      tableData={tableData}
      headers={unsortableHeaders}
    />
  );
};
