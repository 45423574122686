import { useEffect } from 'react';
import { setActionUrgency } from './decorator-helpers';
import useRxDocument from '../../common/hooks/useRxDocument';

export default function ActionDecorator(props) {
  const {
    values: { date, isPriority, datetime, asset: assetId },
    form: { batch, change }
  } = props;

  const { result: asset } = useRxDocument('assets', assetId);

  useEffect(() => {
    if (datetime === undefined) {
      batch(() => {
        setActionUrgency({ change, isPriority });
        change('datetime', date);
      });
    }
  });

  useEffect(() => {
    if (!asset) {
      return;
    }

    batch(() => {
      asset.location?.lat && change('latitude', Number(asset.location.lat));
      asset.location?.lng && change('longitude', Number(asset.location.lng));

      asset.parentOperationalArea &&
        change('parentOperationalArea', asset.parentOperationalArea);
      asset.operationalArea && change('operationalArea', asset.operationalArea);
    });
  }, [change, batch, asset]);

  useEffect(() => {
    setActionUrgency({ change, isPriority });
  }, [change, isPriority]);

  useEffect(() => {
    change('datetime', date);
  }, [change, date]);

  useEffect(() => {
    change('datetime', date);
  }, [change, date]);

  return null;
}
