import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRxDB } from 'rxdb-hooks';
import Update from './update';
import ClassificationsContext from '../common/ClassificationsContext';
import useRxDocument from '../common/hooks/useRxDocument';

const Edit = ({ logEntry }) => {
  const { users } = useContext(ClassificationsContext);
  const db = useRxDB();
  const [canBePropagated, setCanBePropagated] = useState(false);
  const history = useHistory();
  const onSuccess = useCallback(() => history.goBack(), [history]);
  const { id: sourceId, type: sourceType } = logEntry.source;

  useEffect(() => {
    async function checkCanBePropagated() {
      if (db && sourceType === 'Action') {
        const action = await db.collections.actions.findOne(sourceId).exec();
        setCanBePropagated(action && !isEmpty(action.incident));

        return;
      }
    }

    checkCanBePropagated();
  }, [sourceId, sourceType, db]);

  return (
    <Update
      users={users}
      onSuccess={onSuccess}
      logEntry={logEntry}
      canBePropagated={canBePropagated}
    />
  );
};

Edit.propTypes = {
  logEntry: PropTypes.object.isRequired
};

const WrappedEdit = props => {
  const { id } = props.match.params;
  const { result: logEntry, isFetching } = useRxDocument('logentries', id);

  if (isFetching) {
    return 'Loading...';
  }

  if (!isFetching && !logEntry) {
    return 'Not found..';
  }

  return <Edit logEntry={logEntry} />;
};

WrappedEdit.propTypes = {
  match: PropTypes.object.isRequired
};

export default WrappedEdit;
