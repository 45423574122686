import { addRxPlugin, createRxDatabase } from 'rxdb';
import { RxDBJsonDumpPlugin } from 'rxdb/plugins/json-dump';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration-schema';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { wrappedValidateAjvStorage } from 'rxdb/plugins/validate-ajv';
import { observeNewCollections } from 'rxdb-hooks';
import actionCollection from './collections/action';
import assetCollection from './collections/asset';
import incidentCollection from './collections/incident';
import logEntryCollection from './collections/logEntry';
import navigationAidCollection from './collections/navigationAid';
import taxonomyCollections from './collections/taxonomy';
import userCollection from './collections/user';
import actionReplication from './replication/action';
import assetReplication from './replication/asset';
import incidentReplication from './replication/incident';
import logEntryReplication from './replication/logEntry';
import navigationAidReplication from './replication/navigationAid';
import taxonomyReplication from './replication/taxonony';
import userReplication from './replication/user';

addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBQueryBuilderPlugin);
addRxPlugin(RxDBJsonDumpPlugin);
addRxPlugin(observeNewCollections);

if (FEATURE_RXDB_DEV_MODE) {
  import('rxdb/plugins/dev-mode').then(({ RxDBDevModePlugin }) => {
    addRxPlugin(RxDBDevModePlugin);
  });
}

// Ensure the Harbours namespace exists, since we use it.
globalThis.Harbours = globalThis.Harbours || {};

// Set a version the database. This allows us to remotely reset databases,
// either because a migration, or lack thereof, has broken things, or because we
// have updated rxdb to a version that is not compatible with the old storage
// mechanism. While we validate this idea, we won't clear out old database
// copies, since the amount of data we store is pretty small. This means that we
// can 'roll back' to a previous version, and any devices that were running on
// that version will revert. Note that the database version can be anything, but
// we assume an incrementing integer. Note also that this only needs to be
// incremented when required, not every release. Examples of times in the past
// when we have incremented the version:
// v1: Upgrade from RxDB 11 -> 12
// v2: Upgrade from 12 -> 13
// v3: Upgrade from 13 -> 14
// v4: Reset UAT database for testing
// v5: Upgrade from 14 -> 15
const DATABASE_VERSION = 5;

const databaseOpts = {
  name: `harbours_frontend_db_${DATABASE_VERSION}`,
  storage: wrappedValidateAjvStorage({ storage: getRxStorageDexie() })
};

const initDatabase = createRxDatabase(databaseOpts);
const initCollections = db =>
  db.addCollections({
    ...taxonomyCollections,
    ...userCollection,
    ...navigationAidCollection,
    ...assetCollection,
    ...incidentCollection,
    ...actionCollection,
    ...logEntryCollection
  });

const initReplication = async db => {
  return [
    ...initTaxonomyReplication(db),
    userReplication({ collection: db.collections.users }),
    navigationAidReplication({ collection: db.collections.navigationaids }),
    assetReplication({ collection: db.collections.assets }),
    incidentReplication({ collection: db.collections.incidents }),
    actionReplication({ collection: db.collections.actions }),
    logEntryReplication({ collection: db.collections.logentries })
  ];
};

const initTaxonomyReplication = db =>
  Object.keys(taxonomyCollections).map(taxonomyName =>
    taxonomyReplication({
      collection: db.collections[taxonomyName],
      ...taxonomyCollections[taxonomyName].graphql
    })
  );

export { initDatabase, initCollections, databaseOpts };

export default async () => {
  if (globalThis.Harbours.database) {
    return globalThis.Harbours.database.db;
  }

  const db = await initDatabase;
  const collections = await initCollections(db);

  globalThis.Harbours.database = {
    db,
    collections
  };

  const pendingReplicationStates = initReplication(db);
  pendingReplicationStates.then(
    replicationStates =>
      (globalThis.Harbours.database.replicationStates = replicationStates)
  );

  return db;
};
