import pick from 'lodash-es/pick';
import { replicateGraphQL } from 'rxdb/plugins/replication-graphql';
import { ENDPOINT as url, HEADERS as headers } from '../../graphql/constants';
import {
  replicationPushResponseModifier,
  replicationWithErrorReporting,
  replicationWithLiveInterval,
  retryTime,
  wrapDocumentsWithCheckpoint
} from '../helpers';

const pullQueryBuilder = () => {
  return (priorCheckpoint, limit) => {
    const checkpoint = priorCheckpoint || { id: '', updatedAt: 0 };

    console.log(
      `Fetching log entries at ${new Date()}, last modified document is ${
        checkpoint.updatedAt && new Date(checkpoint.updatedAt)
      }`
    );
    const query = `
      query ($filters: [Filter!], $limit: Int!) {
        logEntryCollection(filters: $filters, sort: { dir: ASC, order: "updatedAt" }, limit: $limit) {
          collection {
            id
            createdBy { id }
            editor { id }
            source { __typename id }
            logTime
            propagated
            updatedAt
            archivedAt
            content
          }
        }
      }
    `;

    return {
      query,
      variables: {
        limit: limit,
        filters: [
          {
            identifier: 'updated_since',
            value: checkpoint.updatedAt
              ? new Date(checkpoint.updatedAt).toISOString()
              : ''
          },
          {
            identifier: 'include_archived',
            value: 'true'
          }
        ]
      }
    };
  };
};

const pushMutationBuilder = () => {
  return ([{ newDocumentState: document }]) => {
    const mutation = `
      mutation ($logEntry: LogEntryReplicationSetInput!) {
        setLogEntry(input: $logEntry) {
          logEntry { id }
          errors {
            message
            path
          }
        }
      }
    `;

    return {
      query: mutation,
      variables: { logEntry: document }
    };
  };
};

const convertLogEntryTypeToDocument = logEntry => {
  // If a log entry is archived, indicate to RxDB that it is deleted
  if (logEntry.archivedAt) {
    logEntry._deleted = true;
  }

  delete logEntry.archivedAt;

  logEntry.id = logEntry.id.toString();
  logEntry.updatedAt = new Date(logEntry.updatedAt).getTime();
  logEntry.logTime = new Date(logEntry.logTime).getTime();
  logEntry.createdBy = logEntry.createdBy.id;
  logEntry.editor = logEntry.editor && logEntry.editor.id;
  logEntry.source.type = logEntry.source.__typename;
  delete logEntry.source.__typename;

  return logEntry;
};

const convertDocumentToLogEntryType = doc => {
  // Don't push archived log entries
  if (doc.archivedAt) {
    return null;
  }

  return Object.assign({}, pick(doc, ['id', 'content']), {
    logTime: new Date(doc.logTime).toISOString(),
    parentId: doc.source.id,
    propagated: doc.propagated || false,
    parentType: doc.source.type
  });
};

export default ({ collection }) =>
  replicationWithErrorReporting(
    replicationWithLiveInterval(
      replicateGraphQL({
        collection,
        url: { http: url },
        headers,
        pull: {
          queryBuilder: pullQueryBuilder(),
          dataPath: 'data.logEntryCollection.collection',
          batchSize: 100,
          responseModifier: wrapDocumentsWithCheckpoint,
          modifier: convertLogEntryTypeToDocument
        },
        push: {
          queryBuilder: pushMutationBuilder(),
          batchSize: 1,
          dataPath: 'setLogEntry',
          responseModifier: replicationPushResponseModifier,
          modifier: convertDocumentToLogEntryType
        },
        live: true,
        retryTime
      })
    )
  );
