import { format as dateFormat } from 'date-fns';

const FORMATS = {
  defaultDate: 'dd/MM/yyyy',
  defaultDateTime: 'dd/MM/yyyy HH:mm',
  isoDate: 'yyyy-MM-dd'
};
export function localize(date, { format } = {}) {
  let formatString = format ? format : 'defaultDateTime';
  try {
    const dateObj = typeof date === 'object' ? date : new Date(date);

    return dateFormat(dateObj, FORMATS[formatString] || format);
  } catch {
    console.error(
      'Sorry, the date formatted provided could not be parsed: ',
      date
    );
  }
}

export function formatMonthsAndWeeks(rawMonths) {
  if (rawMonths === undefined || isNaN(rawMonths) || rawMonths <= 0) {
    return '';
  }

  const months = Math.floor(rawMonths);
  const weeks = (rawMonths % 1) * 4;
  const monthsText = `${months} month${months !== 1 ? 's' : ''}`;
  const weeksText = `${weeks} week${weeks !== 1 ? 's' : ''}`;

  if (months && weeks) {
    return `${monthsText}, ${weeksText}`;
  } else if (months) {
    return monthsText;
  } else if (weeks) {
    return weeksText;
  }

  return '';
}
