export const POINT_DEFAULT_SRID = 4326;

export default {
  type: 'object',
  version: 0,
  properties: {
    lat: { type: 'number' },
    lng: { type: 'number' },
    srid: { type: 'number' }
  },
  required: ['lat', 'lng', 'srid']
};
