import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import IncidentShow from './Show';
import IncidentCreate from './create';
import IncidentEdit from './edit';
import IncidentCollection from './index';
import Page from '../common/Page';

export default () => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route
        title="Create Incident"
        path={`${match.path}/new`}
        component={IncidentCreate}
      />
      <Page
        title="Edit Incident"
        path={`${match.path}/:id/edit`}
        component={IncidentEdit}
      />
      <Page
        title="Incident Summary"
        path={`${match.path}/:id`}
        component={IncidentShow}
      />
      <Page
        title="Incidents"
        path={match.path}
        component={IncidentCollection}
      />
    </Switch>
  );
};
