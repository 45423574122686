export const filters = [
  {
    label: 'Action number',
    field: 'text',
    id: 'sequence_number',
    category: 'sequence_number'
  },
  {
    label: 'Action category',
    field: 'custom-select',
    category: 'actionCategories',
    id: 'category_id'
  },
  {
    parentAttribute: 'category_id',
    childAttribute: 'subcategory_id',
    label: 'Action sub-category',
    field: 'taxonomy-categories-select',
    category: 'actionCategories',
    id: 'subcategory_id'
  },
  {
    label: 'Operational area',
    field: 'custom-select',
    category: 'operationalAreas',
    id: 'parent_operational_area_id'
  },
  {
    parentAttribute: 'parent_operational_area_id',
    childAttribute: 'operational_area_id',
    label: 'Sub-operational area',
    field: 'taxonomy-categories-select',
    category: 'operationalAreas',
    id: 'operational_area_id'
  },
  {
    label: 'Asset Name',
    field: 'custom-select',
    category: 'assets',
    id: 'asset_id',
    placeholder: 'Asset Name'
  },
  {
    label: 'Asset category',
    field: 'custom-select',
    category: 'assetCategories',
    id: 'asset_category_id'
  },
  {
    parentAttribute: 'asset_category_id',
    childAttribute: 'asset_subcategory_id',
    label: 'Asset sub-category',
    field: 'taxonomy-categories-select',
    category: 'assetCategories',
    id: 'asset_subcategory_id'
  },
  {
    label: 'Status',
    field: 'custom-select',
    category: 'status',
    id: 'status'
  },
  {
    label: 'Urgency',
    field: 'custom-select',
    category: 'urgency',
    id: 'urgency'
  },
  {
    label: 'Recorded by',
    field: 'custom-select',
    category: 'users',
    id: 'recorded_by'
  },
  {
    label: 'Assigned to',
    field: 'custom-select',
    category: 'users',
    id: 'assigned_to'
  },
  {
    label: 'Marine farm ID',
    field: 'pattern',
    category: 'marineFarms',
    id: 'marine_farm_site_number',
    pattern: '^[0-9]{4}$'
  },
  {
    label: 'Mooring ID',
    field: 'pattern',
    category: 'moorings',
    id: 'mooring_number',
    pattern: '^[0-9]{4}$'
  },
  { label: 'Action due date between', field: 'date', id: 'datetime_after' },
  { label: null, field: 'date', id: 'datetime_before' },
  { label: 'Created between', field: 'date', id: 'created_after' },
  { label: null, field: 'date', id: 'created_before' }
];
