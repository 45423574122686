import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import ActionCreate from './create';
import ActionEdit from './edit';
import ActionCollection from './index';
import ActionShow from './show';
import Page from '../common/Page';

export default () => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Page
        title="Create Action"
        path={`${match.path}/new`}
        component={ActionCreate}
      />
      <Page
        title="Edit Action"
        path={`${match.path}/:id/edit`}
        component={ActionEdit}
      />
      <Page
        title="Action Summary"
        path={`${match.path}/:id`}
        component={ActionShow}
      />
      <Page title="Actions" path={match.path} component={ActionCollection} />
    </Switch>
  );
};
