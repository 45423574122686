import { gql } from '@apollo/client';
export default gql`
  query IncidentCollection(
    $offset: Int
    $limit: Int
    $filters: [Filter!]
    $search: String
    $sort: SortBy
  ) {
    incidentCollection(
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
      search: $search
    ) {
      collection {
        id
        sequenceNumber
        createdAt
        datetime
        description
        status
        actionTaken
        harboursUserGroup {
          id
          name
        }
        incidentCategory {
          id
          name
        }
        involvedIndividuals {
          id
          firstNames
          middleNames
          lastNames
        }
        operationalArea {
          id
          name
        }
        location {
          lat
          lng
          srid
        }
        user {
          id
          name
        }
        harbourmasterVessel {
          id
          name
        }
      }
      pageInfo {
        count
      }
    }
  }
`;
