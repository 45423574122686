import { gql } from '@apollo/client';

export default gql`
  query groupedNotifications {
    groupedNotifications {
      groupId
      notifications {
        id
        readAt
      }
      logEntry {
        id
        createdBy {
          name
        }
        createdAt
        source {
          __typename
          sequenceNumber
          id
        }
        content
      }
    }
  }
`;
