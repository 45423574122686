import PointSchema from './point';
import { UUID } from '../helpers';

// The backend just stores assets, regardless of their category.
// There are a few places in the frontend where we need to look
// specifically for a category to match, which is where this is used.
export const ATON_CATEGORY_NAME = 'Aid to Navigation';

export default {
  version: 3,
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: UUID.maxLength,
      final: true
    },
    sequenceNumber: { type: ['integer', 'null'] },
    name: { type: 'string' },
    updatedAt: { type: ['integer', 'null'] },
    atonCategory: { type: ['integer', 'null'] },
    identificationNumber: { type: ['string', 'null'] },
    location: { ...PointSchema, type: ['object', 'null'] },
    locationDescription: { type: ['string', 'null'] },
    checkIntervalMonths: { type: ['number', 'null'], minimum: 0.25 },
    metadata: { type: 'object', additionalProperties: true },
    category: { type: ['string', 'null'], ref: 'assetcategories' },
    subcategory: { type: ['string', 'null'], ref: 'assetcategories' },
    operationalArea: { type: ['string', 'null'], ref: 'operationalareas' },
    parentOperationalArea: { type: ['string', 'null'], ref: 'operationalareas' }
  },
  required: ['id', 'name']
};
