import { gql } from '@apollo/client';
const collection = gql`
  query AssetCollection(
    $offset: Int
    $limit: Int
    $sort: SortBy
    $search: String
    $filters: [Filter!]
  ) {
    assetCollection(
      offset: $offset
      limit: $limit
      search: $search
      sort: $sort
      filters: $filters
    ) {
      collection {
        id
        identificationNumber
        name
        locationDescription
        location {
          lat
          lng
          srid
        }
        metadata
        category {
          id
          name
        }
        subcategory {
          id
          name
        }
        operationalArea {
          id
          name
        }
        incidentCollection {
          pageInfo {
            count
          }
        }
        actionCollection(
          sort: { order: "closed_at", dir: DESC }
          limit: 1
          filters: [
            { identifier: "official_visit", value: "true" }
            { identifier: "status", value: "closed" }
          ]
        ) {
          collection {
            id
            closedAt
            category {
              id
              name
            }
            subcategory {
              id
              name
            }
          }
        }
      }
      pageInfo {
        count
      }
    }
  }
`;

const show = gql`
  query navigationAid($id: ID!) {
    navigationAid(id: $id) {
      id
      identificationNumber
      name
      locationDescription
      location {
        lat
        lng
        srid
      }
      metadata
      category
      operationalArea {
        id
        name
      }
      parentOperationalArea {
        id
        name
      }
      atonType {
        id
        name
      }
      logEntries {
        id
        content
        createdAt
        updatedAt
        createdBy {
          name
        }
        editor {
          name
        }
      }
      actionCollection(sort: { order: "created_at", dir: DESC }, limit: null) {
        collection {
          id
          createdAt
          datetime
          instructions
          urgency
          marineFarmSiteNumber
          updatedAt
          mooringNumber
          status
          closedAt
          category {
            id
            name
          }
          subcategory {
            id
            name
          }
          requestedBy {
            id
            name
          }
          assignedTo {
            id
            name
          }
          parentOperationalArea {
            id
            name
          }
          operationalArea {
            id
            name
          }
          location {
            lat
            lng
            srid
          }
          incident {
            id
            description
            createdAt
            user {
              name
            }
          }
          navigationAid {
            id
            name
          }
          logEntries {
            id
            content
            createdAt
            updatedAt
            createdBy {
              name
            }
            editor {
              name
            }
          }
        }
      }
    }
  }
`;

const create = gql`
  mutation createNavigationAid(
    $name: String!
    $identification_number: String
    $location_description: String
    $location: [String!]
    $operational_area_id: ID
    $metadata: JSON
    $category: Int
    $aton_type_id: ID
  ) {
    setNavigationAid(
      input: {
        name: $name
        identificationNumber: $identification_number
        locationDescription: $location_description
        operationalAreaId: $operational_area_id
        location: $location
        atonTypeId: $aton_type_id
        metadata: $metadata
        category: $category
      }
    ) {
      navigationAid {
        id
      }
      errors {
        path
        message
      }
    }
  }
`;

export { collection, show, create };
