// const underscoredName = "first_name";
// titleize("first_name") => "First Name";
// titleize("record_id") => "Record ID"
export default input => {
  const words = input.split('_');
  const capitalizedWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1)
  );

  return capitalizedWords.join(' ').replace(/Id\s?/, 'ID ').trim();
};
