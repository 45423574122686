import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';
import { useRxCollection, useRxData } from 'rxdb-hooks';
import FollowUpActionBanner from './FollowUpActionBanner';
import ReviewSubmission from './ReviewSubmission';
import ActionFields from './action-fields';
import applyImpromptuBehaviour from './behaviours/impromptu';
import CurrentUserContext from '../common/CurrentUserContext';
import ApplicationConfigContext from '../common/application-config-context';
import useRxDocument from '../common/hooks/useRxDocument';
import leftPad from '../common/left-pad';
import { UUID } from '../db/helpers';
import { POINT_DEFAULT_SRID } from '../db/schemas/point';
import AutosaveSpy from '../forms/autosave/AutosaveSpy';
import {
  buildStorageKey,
  loadAutosaveItem,
  removeAutosaveItem
} from '../forms/autosave/autosave-helpers';
import ActionDecorator from '../forms/decorators/ActionDecorator';
import Wizard from '../forms/wizard';
const queryString = require('query-string');
const now = new Date();

function buildInitialValues({ currentUser }) {
  return {
    id: UUID.generate(),
    status: 'open',
    requestedBy: currentUser.id.toString(),
    assignedTo: currentUser.staff ? currentUser.id.toString() : null,
    date: `${now.getFullYear()}-${leftPad(now.getMonth() + 1)}-${leftPad(
      now.getDate()
    )}`,
    time: `${leftPad(now.getHours())}:${leftPad(now.getMinutes())}`
  };
}

function resolveReturnTo(belongsTo) {
  const { id, collection } = belongsTo; // Expects an RxDocument instance

  const name = collection?.name;

  switch (name) {
    case 'incidents':
      return `/incidents/${id}`;
    case 'navigationaids':
      return `/navigation_aids/${id}`;
    case 'assets':
      return `/manage-assets/${id}`;
    case 'actions':
      return `/actions/${id}`;
    default:
      return `/`;
  }
}

const storageKey = buildStorageKey('action-create');

const sanitizeAction = values => {
  const { latitude, longitude, operationalArea, parentOperationalArea } =
    values;

  values.datetime = new Date(values.datetime).getTime();

  // Remove additional properties
  delete values.latitude;
  delete values.longitude;
  delete values.date;
  delete values.time;
  delete values.isPriority;

  if (longitude && latitude) {
    values.location = {
      lng: parseFloat(longitude),
      lat: parseFloat(latitude),
      srid: POINT_DEFAULT_SRID
    };
  }
  if (!operationalArea && parentOperationalArea) {
    values.operationalArea = parentOperationalArea;
  }

  return values;
};

async function handleCreateSubmit({ values: params, collection, history }) {
  const action = sanitizeAction({ ...params });
  let submissionErrors = {};

  try {
    const createdAction = await collection.incrementalUpsert(action);
    const incident = await createdAction.populate('incident');
    const asset = await createdAction.populate('asset');
    const navigationAid =
      asset &&
      // eslint-disable-next-line no-undef
      (await Harbours.database.collections.navigationaids
        .findOne(asset.id)
        .exec());

    await applyImpromptuBehaviour(createdAction);

    removeAutosaveItem(storageKey);
    history.push(
      `${resolveReturnTo(
        incident || navigationAid || asset || createdAction
      )}?status_message=actions.create.success`
    );
  } catch (err) {
    // If we get validation errors, treat this as an error, but present
    // a generic message.
    console.error(err);
    submissionErrors[[FORM_ERROR]] = `
      There were some problems submitting this action.
      Please check fields and try again.
    `;
  }

  return submissionErrors;
}

const Create = props => {
  const { currentUser, incident, asset, navigationAid, assets, fromAction } =
    props;
  const collection = useRxCollection('actions');
  const history = useHistory();

  const onSubmit = values =>
    handleCreateSubmit({
      values,
      collection,
      history
    });

  const [warning, setWarning] = useState(false);
  const storedData = loadAutosaveItem(storageKey)
    ? JSON.parse(loadAutosaveItem(storageKey))
    : null;

  const storedForm = storedData && storedData.form;

  const initialValues = useMemo(() => {
    const builtInitialValues = buildInitialValues({ currentUser });
    const storedInitialValues = storedForm || {};
    const incidentInitialValues = incident
      ? {
          incident: incident.id,
          parentOperationalArea: incident.parentOperationalArea,
          operationalArea: incident.operationalArea,
          latitude: incident.location
            ? Number(incident.location.lat)
            : undefined,
          longitude: incident.location
            ? Number(incident.location.lng)
            : undefined
        }
      : {};
    const assetInitialValues = asset
      ? {
          asset: asset.id,
          parentOperationalArea: asset.parentOperationalArea,
          operationalArea: asset.operationalArea,
          latitude: asset.location ? Number(asset.location.lat) : undefined,
          longitude: asset.location ? Number(asset.location.lng) : undefined
        }
      : {};
    const navigationAidInitialValues = navigationAid
      ? {
          asset: navigationAid.id,
          parentOperationalArea: navigationAid.parentOperationalArea,
          operationalArea: navigationAid.operationalArea,
          latitude: navigationAid.location
            ? Number(navigationAid.location.lat)
            : undefined,
          longitude: navigationAid.location
            ? Number(navigationAid.location.lng)
            : undefined
        }
      : {};

    // Establishes an order of precedence for initial values.
    // This ensures that the latest data from the incident or nav aid
    // is used to fill in the form.
    return {
      ...builtInitialValues,
      ...storedInitialValues,
      ...incidentInitialValues,
      ...assetInitialValues,
      ...navigationAidInitialValues
    };
  }, [incident, storedForm, asset, navigationAid, currentUser]);

  useEffect(() => {
    if (!storedForm) {
      return;
    }
    setWarning('saved');
  }, [storedForm]);

  const cancelFollowUp = useCallback(() => {
    removeAutosaveItem(storageKey);
    history.push(
      resolveReturnTo(incident || asset || navigationAid || fromAction)
    );
  }, [history, incident, asset, navigationAid, fromAction]);

  const AutosavingActionFields = wizardProps => {
    return (
      <div>
        <ActionFields
          {...props}
          {...wizardProps}
          warning={warning}
          setWarning={setWarning}
          storageKey={storageKey}
          assets={assets}
        />
        <AutosaveSpy
          DecoratorComponent={ActionDecorator}
          storageKey={storageKey}
        />
      </div>
    );
  };

  const wizardPages = () => [
    <AutosavingActionFields key="wizard-step-1" title="Schedule an Action" />,
    <ReviewSubmission
      storageKey={storageKey}
      key="wizard-step-3"
      title="Submit"
    />
  ];

  return (
    <>
      {fromAction && (
        <FollowUpActionBanner action={fromAction} cancel={cancelFollowUp} />
      )}
      {/* eslint-disable-next-line react/jsx-no-bind */}
      <Wizard initialValues={initialValues} onSubmit={onSubmit}>
        {wizardPages()}
      </Wizard>
    </>
  );
};

Create.propTypes = {
  currentUser: PropTypes.object.isRequired,
  incident: PropTypes.object,
  fromAction: PropTypes.object,
  asset: PropTypes.object,
  navigationAid: PropTypes.object,
  assets: PropTypes.arrayOf(PropTypes.object),
  operationalBounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  defaultPosition: PropTypes.arrayOf(PropTypes.number)
};

export default function WrappedCreate({ location }) {
  const params = queryString.parse(location.search, {
    parseNumbers: true,
    arrayFormat: 'index'
  });

  // Look up incident from query params
  // Look up navigation aid from query params
  const { result: assets } = useRxData('assets', c => c.find());
  const { result: asset } = useRxDocument('assets', params.assetId || null);
  const { result: navigationAid } = useRxDocument(
    'navigationaids',
    params.assetId || null
  );
  const { result: incident } = useRxDocument(
    'incidents',
    params.incidentId || null
  );

  const { result: fromAction } = useRxDocument('actions', params.fromAction);

  const { user } = useContext(CurrentUserContext);
  const { defaultPosition, operationalBounds } = useContext(
    ApplicationConfigContext
  );

  return (
    <Create
      operationalBounds={operationalBounds}
      defaultPosition={defaultPosition}
      currentUser={user}
      assets={assets}
      asset={asset}
      navigationAid={navigationAid}
      fromAction={fromAction}
      incident={incident}
    />
  );
}

WrappedCreate.propTypes = { location: PropTypes.object.isRequired };
