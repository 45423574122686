import React from 'react';
import { Link } from 'react-router-dom';
import IncidentsDataTable from './DataTable';
import IncidentsOfflineDataTable from './OfflineDataTable';
import useNetworkConnectivityStatus from '../common/hooks/useNetworkConnectivityStatus';

export default function Incidents() {
  const online = useNetworkConnectivityStatus();

  return (
    <div className="page">
      <h2>Incidents Table {online || '(Offline)'}</h2>

      {online ? <IncidentsDataTable /> : <IncidentsOfflineDataTable />}

      <div className="grid-x align-right">
        <Link to="/incidents/new" className="button secondary" type="button">
          + Create new
        </Link>
      </div>
    </div>
  );
}
