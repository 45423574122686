export function removeAutosaveItem(key) {
  return localStorage.removeItem(key);
}

export function loadAutosaveItem(key) {
  return localStorage.getItem(key);
}

export function saveAutosaveItem(key, values) {
  return localStorage.setItem(
    key,
    JSON.stringify({ form: values, storedAt: new Date() })
  );
}

export function buildStorageKey(prefix) {
  return `${prefix}-${window.location.origin + window.location.pathname}`;
}
