import PropTypes from 'prop-types';
import React from 'react';
import ActionHeader from './ActionHeader';
import ActionSummary from './ActionSummary';
import CollapsibleList from '../common/CollapsibleList';

const ListItemHeader = ({ item, isExpanded, expandOrCollapse }) => (
  <ActionHeader
    onClick={expandOrCollapse}
    action={item}
    expanded={isExpanded}
  />
);

ListItemHeader.propTypes = {
  item: PropTypes.object,
  isExpanded: PropTypes.bool,
  expandOrCollapse: PropTypes.func
};

const ListItemContent = ({ item, isExpanded }) =>
  isExpanded && <ActionSummary action={item} showHeader={false} />;

ListItemContent.propTypes = {
  item: PropTypes.object,
  isExpanded: PropTypes.bool
};

const ListItem = props => (
  <>
    <ListItemHeader {...props} />
    <ListItemContent {...props} />
  </>
);

/**
 * Wraps the ListTitle component in a scope that allows the component to access
 * the 'title' argument when rendered
 * @param {String} title The title of the list
 * @returns {Element} the component to render
 */
const buildListTitle = title => {
  const ListTitle = ({ expanded, expandOrCollapseAll }) => {
    return (
      <div className="cell small-8 large-9 large-offset-1 grid-x margin-top-3">
        <h2 className="cell auto">{title}</h2>
        <div className="cell shrink">
          <button
            type="button"
            className={`collapsible-list__control padding-right-0 collapsible-list__control--${
              expanded ? 'collapse' : 'expand'
            }`}
            onClick={expandOrCollapseAll}
          >
            {(expanded ? `Collapse all ` : `Expand all `) + title}
          </button>
        </div>
      </div>
    );
  };

  ListTitle.propTypes = {
    expanded: PropTypes.bool,
    expandOrCollapseAll: PropTypes.func
  };

  return ListTitle;
};

const ActionsCollapsibleList = ({ actions, expandedActions = [], title }) => {
  return (
    <CollapsibleList
      collection={actions}
      initiallyExpanded={expandedActions}
      TitleComponent={buildListTitle(title)}
      ItemComponent={ListItem}
    />
  );
};

ActionsCollapsibleList.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  expandedActions: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  filter: PropTypes.func
};

export default ActionsCollapsibleList;
