import { gql, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import I18n from '../../i18n/index.js.erb';
import ApplicationConfigContext from '../common/application-config-context';
import useNetworkConnectivityStatus from '../common/hooks/useNetworkConnectivityStatus';
const CONFIRM_MESSAGE = I18n.t('incidents.archive.confirm');
const FAILURE_MESSAGE = I18n.t('incidents.archive.failure');

const ArchiveIncidentControl = ({ incident }) => {
  const history = useHistory();
  const { userRoles } = useContext(ApplicationConfigContext);
  const online = useNetworkConnectivityStatus();

  const [mutate, { loading, error }] = useMutation(
    gql`
      mutation archive($id: ID!) {
        incidentArchive(input: { id: $id }) {
          success
        }
      }
    `,
    {
      variables: { id: incident.id },
      onCompleted: ({ incidentArchive: { success } }) => {
        if (!success) {
          // This shouldn't happen, but let the user know something went
          // wrong.
          alert(FAILURE_MESSAGE);
        }

        incident.remove();
        history.push(`/incidents/?status_message=incidents.archive.success`);
      }
    }
  );

  const handleClick = useCallback(
    () => confirm(CONFIRM_MESSAGE) && mutate(),
    [mutate]
  );

  if (!online || !userRoles.includes('admin')) {
    return null;
  }

  if (loading) {
    return (
      <button type="button" disabled className="button secondary expanded">
        Archiving incident...
      </button>
    );
  }

  return (
    <>
      <button
        type="button"
        className="button secondary expanded"
        onClick={handleClick}
      >
        Archive Incident
      </button>
      {error && <div className="alert alert__message">{error.message}</div>}
    </>
  );
};

ArchiveIncidentControl.propTypes = {
  incident: PropTypes.object.isRequired
};

export default ArchiveIncidentControl;
