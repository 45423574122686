import schema from '../schemas/action';
export default {
  actions: {
    migrationStrategies: {
      1: oldDoc => {
        oldDoc.changedFields = [];

        return oldDoc;
      },
      2: doc => {
        doc.asset = doc.navigationAid;
        delete doc.navigationAid;

        return doc;
      },
      3: doc => {
        delete doc.changedFields;

        return doc;
      }
    },
    schema
  }
};
