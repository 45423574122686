export const headers = [
  { name: 'Action No.', id: 'sequence_number' },
  { name: 'Urgency', id: 'urgency' },
  { name: 'Categories', id: 'category' },
  { name: 'Asset Name', id: 'asset_name' },
  { name: 'Location', id: 'location_description' },
  { name: 'Action Date', id: 'datetime' },
  { name: 'Assigned To', id: 'assigned_to' },
  { name: 'Status', id: 'status' }
];
