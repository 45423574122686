import detectBehaviour from '../../common/detectBehaviour';
import offsetLocalTimezone from '../../common/offsetLocalTimezone';

export default async action => {
  if (!(await detectBehaviour(action, 'impromptu'))) {
    return action;
  }
  const now = new Date().getTime();
  const actionDueDate = offsetLocalTimezone(action.datetime);

  // Impromptu actions are closed immediately if they are due now or in the past
  if (actionDueDate <= now) {
    await action.incrementalPatch({ closedAt: now, status: 'closed' });
  }

  return action;
};
