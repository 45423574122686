import { compact } from 'lodash-es';
import React from 'react';
import { Link } from 'react-router-dom';
import tableCog from '../../../images/table-cog.svg';
import formatBoolean from '../../common/boolean-format';
import { localize } from '../../common/date-format';

export default async function getAtonDocumentProperties(
  navigationAid,
  actions,
  incidents
) {
  const { id, identificationNumber, name, atonCategory } = navigationAid;
  const relatedActions = actions.filter(
    action => action.asset === navigationAid.id
  );
  const relatedIncidentIds = compact(
    relatedActions.map(action => action.incident)
  );
  const relatedIncidents = incidents.filter(incident =>
    relatedIncidentIds.includes(incident.id)
  );

  const lastVisit =
    relatedActions.sort(
      (a, b) => (a.closedAt && b.closedAt && b.closedAt - a.closedAt) || 0
    )[0] || {};

  const lastVisitCompleted = lastVisit.closedAt;
  const hasIncidents = formatBoolean(relatedIncidents.length);

  const parentOperationalArea = (await navigationAid.populate(
    'parentOperationalArea'
  )) || {
    children: []
  };
  const operationalArea =
    parentOperationalArea.children.find(
      oa => oa.id === navigationAid.operationalArea
    ) || parentOperationalArea;

  const category = (await navigationAid.populate('category')) || {
    children: []
  };
  const subcategory =
    category.children.find(cat => cat.id === navigationAid.subcategory) ||
    category;

  return [
    { value: id, skip: true },
    { value: identificationNumber },
    { value: name },
    { value: subcategory.name },
    { value: operationalArea.name },
    { value: atonCategory },
    { value: hasIncidents },
    { value: lastVisitWithControls(lastVisitCompleted, id) }
  ];
}
function lastVisitWithControls(lastVisitCompleted, id) {
  return (
    <div className="grid-x align-middle align-justify">
      <div>
        {lastVisitCompleted ? localize(new Date(lastVisitCompleted)) : 'N/A'}
      </div>
      <ul
        id={`dropdown-menu-${id}`}
        className="dropdown menu margin-left-auto"
        data-dropdown-menu
        data-alignment="right"
      >
        <li>
          <Link to={`/navigation_aids/${id}`}>
            <img src={tableCog} className="icon" alt="options" />
          </Link>
          <ul className="menu">
            <li>
              <Link to={`/actions/new?navigationAidId=${id}`}>New Action</Link>
            </li>
            <li>
              <Link to={`/navigation_aids/${id}#add_log_entry`}>Log Entry</Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}
