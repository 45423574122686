import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import history from './common/browserHistory';
import useNetworkConnectivityStatus from './common/hooks/useNetworkConnectivityStatus';
import { HEADERS } from './graphql/constants';
import assets from '../images/assets.svg';
import chevronUp from '../images/chevron-up-white.svg';
import clipboard from '../images/clipboard-check.svg';
import compass from '../images/compass.svg';
import eye from '../images/eye.svg';
import lifeRing from '../images/life-ring.svg';
import MDCLogo from '../images/logo-with-text.svg';
import networkStatusIndicator from '../images/networkStatusIndicator.svg';
import plusCircle from '../images/plus-circle.svg';

const Overlay = ({ toggleDrawer }) => {
  return createPortal(
    <button type="button" className="overlay" onClick={toggleDrawer} />,
    document.getElementById('overlay-target')
  );
};

const HeaderDrawer = ({ serverRouted, toggle, heading, resources }) => {
  const navigate = path => () => {
    history.push({ pathname: path, search: null });
    toggle();
    serverRouted && window.location.reload();
  };

  let tabletDrawerItems = resources.map(el => {
    return (
      <button
        onClick={navigate(el.link)}
        type="button"
        key={el.heading}
        className="tablet-drawer__link"
      >
        <div className="tablet-drawer__image">
          <img src={el.image} alt="Life Ring" />
        </div>
        <div className="tablet-drawer__text">
          <h4>{heading}</h4>
          <h2>{el.heading}</h2>
        </div>
      </button>
    );
  });

  return (
    <div className="tablet-drawer">
      <div className="tablet-drawer__list">{tabletDrawerItems}</div>

      <button type="button" onClick={toggle} className="tablet-drawer__close">
        <img
          className="tablet-drawer__chevron"
          src={chevronUp}
          alt="Close Drawer"
        />
        Close
      </button>
    </div>
  );
};

HeaderDrawer.propTypes = {
  toggle: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  resources: PropTypes.arrayOf(PropTypes.object).isRequired,
  serverRouted: PropTypes.bool
};

const TabletHeader = ({ serverRouted }) => {
  const isOnline = useNetworkConnectivityStatus();
  const logOut = useCallback(() => {
    window.Harbours.application.stop();

    const url = '/users/sign_out';

    fetch(url, {
      method: 'DELETE',
      redirect: 'follow',
      headers: {
        ...HEADERS,
        'Content-Type': 'application/json'
      }
    }).then(() => (window.location = '/users/sign_in'));
  }, []);

  const [newResourceIsSelected, setNewResourceIsSelected] = useState('');

  const [viewAllIsSelected, setViewAllIsSelected] = useState('');

  const toggleNewResourceDrawer = useCallback(() => {
    setViewAllIsSelected('');
    newResourceIsSelected
      ? setNewResourceIsSelected('')
      : setNewResourceIsSelected('tablet-header__item--state-selected');
  }, [setViewAllIsSelected, newResourceIsSelected, setNewResourceIsSelected]);

  const toggleViewAllDrawer = useCallback(() => {
    setNewResourceIsSelected('');
    viewAllIsSelected
      ? setViewAllIsSelected('')
      : setViewAllIsSelected('tablet-header__item--state-selected');
  }, [setNewResourceIsSelected, viewAllIsSelected, setViewAllIsSelected]);

  return (
    <>
      <div className="tablet-header">
        <a href="/" className="tablet-header__item tablet-header__item--logo">
          <img src={MDCLogo} alt="MDC logo" />
        </a>

        <button
          type="button"
          className={`tablet-header__item ${viewAllIsSelected}`}
          onClick={toggleViewAllDrawer}
        >
          <img src={eye} alt="View all" />
          <span>View all</span>
        </button>
        <button
          type="button"
          className={`tablet-header__item ${newResourceIsSelected}`}
          onClick={toggleNewResourceDrawer}
        >
          <img src={plusCircle} alt="Create New" />
          <span>Create New</span>
        </button>
        <button
          type="button"
          onClick={logOut}
          className="tablet-header__item tablet-logo"
        >
          <span>Log out</span>
        </button>
        <div
          className={`tablet-header__network-status-indicator tablet-header__network-status-indicator--${
            isOnline ? 'online' : 'offline'
          }`}
        >
          <img src={networkStatusIndicator} alt="Network Status" />
        </div>
      </div>
      {newResourceIsSelected && (
        <div className="tablet-drawer">
          <Overlay toggleDrawer={toggleNewResourceDrawer} />
          <HeaderDrawer
            toggle={toggleNewResourceDrawer}
            heading="Create New"
            serverRouted={serverRouted}
            resources={[
              {
                heading: 'Incident',
                image: lifeRing,
                link: '/incidents/new'
              },
              {
                heading: 'Action',
                image: clipboard,
                link: '/actions/new'
              },
              {
                heading: 'AtoN',
                image: compass,
                link: '/navigation_aids/new'
              },
              {
                heading: 'Asset',
                image: assets,
                link: '/manage-assets/new'
              }
            ]}
          />
        </div>
      )}
      {viewAllIsSelected && (
        <div className="tablet-drawer">
          <Overlay toggleDrawer={toggleViewAllDrawer} />
          <HeaderDrawer
            toggle={toggleViewAllDrawer}
            heading="View all"
            serverRouted={serverRouted}
            resources={[
              {
                heading: 'Incidents',
                image: lifeRing,
                link: '/incidents'
              },
              { heading: 'Actions', image: clipboard, link: '/actions' },
              {
                heading: 'AtoNs',
                image: compass,
                link: '/navigation_aids'
              },
              {
                heading: 'Assets',
                image: assets,
                link: '/manage-assets'
              }
            ]}
          />
        </div>
      )}
    </>
  );
};

TabletHeader.propTypes = {
  serverRouted: PropTypes.bool
};

export default TabletHeader;
