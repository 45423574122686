import { gql } from '@apollo/client';
export const CREATE = gql`
  mutation createAction(
    $datetime: ISO8601DateTime!
    $instructions: String!
    $assigned_to_id: ID
    $incident_id: ID
    $navigation_aid_id: ID
    $operational_area_id: ID
    $location: [String!]
    $requested_by_id: ID!
    $subcategory: ID!
    $urgency: ActionUrgency = normal
    $marine_farm_site_number: String
    $mooring_number: String
  ) {
    setAction(
      input: {
        datetime: $datetime
        instructions: $instructions
        marineFarmSiteNumber: $marine_farm_site_number
        mooringNumber: $mooring_number
        urgency: $urgency
        categoryId: $subcategory
        operationalAreaId: $operational_area_id
        location: $location
        requestedById: $requested_by_id
        assignedToId: $assigned_to_id
        incidentId: $incident_id
        assetId: $navigation_aid_id
      }
    ) {
      action {
        id
      }
      errors {
        path
        message
      }
    }
  }
`;

export const COLLECTION = gql`
  query ActionCollection(
    $offset: Int
    $limit: Int
    $filters: [Filter!]
    $search: String
    $sort: SortBy
  ) {
    actionCollection(
      offset: $offset
      limit: $limit
      sort: $sort
      filters: $filters
      search: $search
    ) {
      collection {
        id
        sequenceNumber
        createdAt
        updatedAt
        datetime
        instructions
        urgency
        marineFarmSiteNumber
        mooringNumber
        status
        closedAt
        location {
          lat
          lng
          srid
        }
        category {
          id
          name
        }
        subcategory {
          id
          name
        }
        requestedBy {
          id
          name
        }
        assignedTo {
          id
          name
        }
        parentOperationalArea {
          id
          name
        }
        operationalArea {
          id
          name
        }
        incident {
          id
          description
          createdAt
          user {
            name
          }
        }
        asset {
          id
          name
          locationDescription
        }
        logEntries {
          id
          content
          createdAt
          createdBy {
            name
          }
        }
      }
      pageInfo {
        count
      }
    }
  }
`;
