import { gql, useMutation } from '@apollo/client';
import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
const CONFIRM_MESSAGE = I18n.t('incidents.close.confirm');

const ReopenIncidentControl = ({ incident }) => {
  const [mutate, { loading, error }] = useMutation(
    gql`
      mutation open($id: ID!) {
        incidentOpen(input: { id: $id }) {
          incident {
            id
            status
          }
        }
      }
    `,
    {
      variables: { id: incident.id },
      onCompleted: ({
        incidentOpen: {
          incident: { status }
        }
      }) => incident.incrementalPatch({ status })
    }
  );

  if (loading) {
    return (
      <button type="button" disabled className="button secondary expanded">
        Opening incident...
      </button>
    );
  }

  return (
    <>
      <button
        type="button"
        className="button secondary expanded"
        onClick={mutate}
      >
        Reopen Incident
      </button>
      {error && <div className="alert alert__message">{error.message}</div>}
    </>
  );
};

const CloseIncidentControl = ({ incident }) => {
  const [mutate, { loading, error }] = useMutation(
    gql`
      mutation close($id: ID!) {
        incidentClose(input: { id: $id }) {
          incident {
            id
            status
          }
        }
      }
    `,
    {
      variables: { id: incident.id },
      onCompleted: ({
        incidentClose: {
          incident: { status }
        }
      }) => incident.incrementalPatch({ status })
    }
  );

  const closeIncident = useCallback(() => {
    if (!confirm(CONFIRM_MESSAGE)) {
      return;
    }

    mutate();
  }, [mutate]);

  if (loading) {
    return (
      <button type="button" disabled className="button secondary expanded">
        Closing incident...
      </button>
    );
  }

  return (
    <>
      <button
        type="button"
        className="button secondary expanded"
        onClick={closeIncident}
      >
        Close Incident
      </button>
      {error && <span className="alert alert__message">{error.message}</span>}
    </>
  );
};

export default function IncidentStatusControl({ incident }) {
  return incident.status === 'open' ? (
    <CloseIncidentControl incident={incident} />
  ) : (
    <ReopenIncidentControl incident={incident} />
  );
}

IncidentStatusControl.propTypes = {
  incident: PropTypes.object.isRequired
};

CloseIncidentControl.propTypes = IncidentStatusControl.propTypes;
ReopenIncidentControl.propTypes = CloseIncidentControl.propTypes;
