import { ApolloProvider } from '@apollo/client';
import { persistCache } from 'apollo-cache-persist';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import client, { cache } from './networkedClient';

const storage = window.localStorage;
const waitOnCache = persistCache({
  cache,
  storage,
  maxSize: false,
  debug: true
});

const Provider = ({ children, loading }) => {
  const [ready, setReady] = useState(waitOnCache.isFulfilled);
  useEffect(() => waitOnCache.then(() => setReady(true)) && undefined, []);

  if (!ready) {
    return loading || 'Loading...';
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  loading: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default Provider;
