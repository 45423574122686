// Used for filtering matches based on status code, header, or both
import { CacheableResponsePlugin } from 'workbox-cacheable-response';
// Used to limit entries in cache, remove entries after a certain period of time
import { ExpirationPlugin } from 'workbox-expiration';
import { precacheAndRoute, PrecacheFallbackPlugin } from 'workbox-precaching';
import { registerRoute } from 'workbox-routing';
import { NetworkOnly, CacheFirst } from 'workbox-strategies';

const precacheManifest = [
  // __webpack_hash__ comes from ExtendedAPIPlugin in webpack
  // Setting a revision for the offline page is important, otherwise
  // the offline fallback won't be updated when new assets are released.
  //
  // eslint-disable-next-line no-undef, camelcase
  { url: '/offline', revision: __webpack_hash__ }
].concat(self.__WB_MANIFEST || []);

precacheAndRoute(precacheManifest);

// Cache page navigations (html) with a Network First strategy
registerRoute(
  // Check to see if the request is a navigation to a new page and it is same origin
  ({ request, sameOrigin }) => request.mode === 'navigate' && sameOrigin,
  // Use a NetworkOnly strategy. This means that a cache will never be checked.
  // Weirdly, this is usually what we want for navigation responses which is what
  // this route will match. We want to have the request use the network if possible,
  // but to give up quite quickly and respond with the offline fallback
  // page if the timeout is reached.
  //
  // NetworkFirst is not suitable here because it doesn't raise an error if the request times out,
  // it simply switches strategy to CacheFirst, and attempts to fetch the
  // request from the cache.
  new NetworkOnly({
    networkTimeoutSeconds: 3,
    // Put all cached files in a cache named 'pages'
    fetchOptions: {
      credentials: 'same-origin'
    },
    cacheName: 'pages',
    plugins: [
      new PrecacheFallbackPlugin({
        fallbackURL: '/offline'
      })
    ]
  })
);

// Cache images with a Cache First strategy
registerRoute(
  // Check to see if the request's destination is style for an image
  ({ request }) => request.destination === 'image',
  // Use a Cache First caching strategy
  new CacheFirst({
    // Put all cached files in a cache named 'images'
    fetchOptions: {
      credentials: 'same-origin'
    },

    cacheName: 'images',
    plugins: [
      // Ensure that only requests that result in a 200 status are cached
      new CacheableResponsePlugin({
        statuses: [200]
      }),
      // Don't cache more than 50 items, and expire them after 30 days
      new ExpirationPlugin({
        maxEntries: 50,
        maxAgeSeconds: 60 * 60 * 24 * 30 // 30 Days
      })
    ]
  })
);

// Listen for SKIP_WAITING messages and pass these along
// to Workbox when received.
addEventListener('message', event => {
  if (event.data && event.data.type === 'SKIP_WAITING') {
    self.skipWaiting();
  }
});

// // Cache CSS, JS, and Web Worker requests with a Stale While Revalidate strategy
// registerRoute(
//   // Check to see if the request's destination is style for stylesheets, script for JavaScript, or worker for web worker
//   ({ request }) =>
//     request.destination === 'style' ||
//     request.destination === 'script' ||
//     request.destination === 'worker',
//   // Use a Stale While Revalidate caching strategy
//   new StaleWhileRevalidate({
//     // Put all cached files in a cache named 'assets'
//     cacheName: 'assets',
//     plugins: [
//       // Ensure that only requests that result in a 200 status are cached
//       new CacheableResponsePlugin({
//         statuses: [200, 302]
//       })
//     ]
//   })
// );
