import { useEffect, useState } from 'react';

const CONNECTION_POOR_THRESHOLD = 1000;
const CONNECTION_OFFLINE_THRESHOLD = 3000;

const gradeResponseTime = responseTime => {
  if (!responseTime || responseTime >= CONNECTION_OFFLINE_THRESHOLD) {
    return 'offline';
  } else if (responseTime >= CONNECTION_POOR_THRESHOLD) {
    return 'poor-connection';
  }

  return 'good-connection';
};

export default function useNetworkConnectivityStatus() {
  let [connectivityStatus, setConnectivityStatus] = useState(navigator.onLine);

  useEffect(() => {
    function handleNetworkStatusChange({ detail: { averageResponseTime } }) {
      setConnectivityStatus(
        gradeResponseTime(averageResponseTime) === 'good-connection'
      );
    }

    document.addEventListener(
      'harbours.networkConnectivity',
      handleNetworkStatusChange
    );

    return () => {
      window.removeEventListener(
        'harbours.networkConnectivity',
        handleNetworkStatusChange
      );
    };
  }, []);

  return connectivityStatus;
}
