import { useCallback } from 'react';
import { useRxData } from 'rxdb-hooks';

/**
 * Searches for a single document by an id attribute.
 * Adapted from https://github.com/cvara/rxdb-hooks/blob/4.1.0/src/useRxDocument.ts,
 * with modifications since idAttribute is no longer part of the
 * context.
 */
function useRxDocument(collectionName, id, options = {}) {
  const { idAttribute = 'id', json } = options;

  /**
   * As a means of holding off data fetching when id is missing
   * don't return a valid RxQuery object
   */
  const queryConstructor = useCallback(
    c => {
      if (!id) {
        return undefined;
      }

      return c.findOne({ selector: { [idAttribute]: id } });
    },
    [id, idAttribute]
  );

  // get around type-narrowing issue
  // TODO: find a better workaround
  const { result, isFetching } = json
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useRxData(collectionName, queryConstructor, { json: true })
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useRxData(collectionName, queryConstructor, { json: false });

  return { result: result[0], isFetching };
}

export default useRxDocument;
