import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Form } from 'react-final-form';

export default function Wizard(props) {
  const { initialValues, children, formProps, onSubmit } = props;
  const [page, setPage] = useState(0);

  function next() {
    setPage(Math.min(page + 1, children.length - 1));

    window.document.documentElement.scrollTop = 0;
  }

  function handlePrevious() {
    setPage(Math.max(page - 1, 0));
  }

  function validate(values) {
    const activePage = React.Children.toArray(children)[page];

    return activePage.props.validate ? activePage.props.validate(values) : {};
  }

  function prepare(values) {
    const activePage = React.Children.toArray(children)[page];

    return activePage.props.prepare ? activePage.props.prepare(values) : values;
  }

  function wizardSubmit(values) {
    const preparedValues = prepare(values);
    const isLastPage = page === React.Children.count(children) - 1;

    if (isLastPage) {
      return onSubmit(preparedValues);
    }

    next(preparedValues);
  }

  const childComponents = React.Children.toArray(children);
  const activePage = childComponents[page];
  const isLastPage = page === React.Children.count(children) - 1;

  return (
    <Form
      initialValues={initialValues || {}}
      validate={validate}
      mutators={{ ...arrayMutators }}
      onSubmit={wizardSubmit}
      {...formProps}
    >
      {({ handleSubmit, form, submitting, submitError, values: params }) => (
        <form
          onSubmit={handleSubmit}
          className="grid-x page page--white-background"
        >
          <ul className="wizard-progress cell small-12 margin-top-2 margin-bottom-2">
            {childComponents.map(section => (
              <li
                className={
                  activePage === section ? 'menu-text is-active' : 'menu-text'
                }
                key={section.props.title}
              >
                {section.props.title || 'Section'}
              </li>
            ))}
          </ul>

          <div className="cell small-12 xlarge-10 xlarge-offset-1 xxlarge-8">
            {submitError && (
              <div className="alert alert-danger">{submitError}</div>
            )}
            {React.cloneElement(activePage, {
              skip: next,
              form,
              params
            })}

            <div className="row grid-x">
              <div className="cell auto" />
              <div className="grid-x align-spaced cell small-12 padding-left-2 padding-right-2">
                {page > 0 && (
                  <div className="cell small-5">
                    <button
                      className="button expanded padded secondary"
                      type="button"
                      onClick={handlePrevious}
                    >
                      {isLastPage ? 'Edit' : 'Back'}
                    </button>
                  </div>
                )}
                {!isLastPage && (
                  <div className={`cell small-${page === 0 ? 12 : 5}`}>
                    <button
                      className="button expanded padded primary"
                      type="submit"
                    >
                      Continue
                    </button>
                  </div>
                )}
                {isLastPage && (
                  <div className={`cell small-${page === 0 ? 12 : 5}`}>
                    <button
                      className="button expanded padded primary"
                      type="submit"
                      disabled={submitting}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
}

Wizard.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  formProps: PropTypes.object,
  initialValues: PropTypes.object
};

Wizard.Page = ({ children }) => children;
