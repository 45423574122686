import PropTypes from 'prop-types';
import React from 'react';
import CustomSelect from './CustomSelect';
import { FormGroupField } from '../forms/field';

const Select = ({
  id,
  name,
  label,
  labelClass,
  includeBlank = false,
  options,
  ...fieldProps
}) => {
  let selectOptions = options;
  includeBlank && (selectOptions = [{ value: null, label: '' }, ...options]);

  return (
    <>
      <label className={labelClass ? labelClass : null} htmlFor={id}>
        {label}
      </label>
      <FormGroupField
        {...fieldProps}
        id={id}
        name={name}
        ariaLabel={label}
        component={CustomSelect}
        options={selectOptions}
        isClearable={includeBlank}
      />
    </>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  includeBlank: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Select;
