import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import I18n from '../../i18n/index.js.erb';
import detectBehaviour from '../common/detectBehaviour';
const CONFIRM_MESSAGE = I18n.t('actions.close.confirm');

const ReopenActionControl = ({ action }) => {
  const reopen = useCallback(
    () => action.incrementalPatch({ closedAt: null, status: 'open' }),
    [action]
  );

  return (
    <>
      <button
        type="button"
        className="button secondary expanded"
        onClick={reopen}
      >
        Reopen Action
      </button>
    </>
  );
};

const CloseActionControl = ({ action }) => {
  const history = useHistory();

  const close = useCallback(async () => {
    if (!confirm(CONFIRM_MESSAGE)) {
      return;
    }

    action.incrementalPatch({
      closedAt: new Date().getTime(),
      status: 'closed'
    });

    const followUp = await detectBehaviour(action, 'show_follow_up');

    // Follow-up actions being closed take the user to the action form, allowing them
    // to add a follow-up action if they require.
    if (followUp) {
      // React-Router navigation does not scroll to the top for us.
      window.scrollTo({ top: 0 });
      const followupActionParams = new URLSearchParams({
        assetId: action.asset,
        fromAction: action.id
      });

      history.push({
        pathname: '/actions/new',
        search: followupActionParams.toString()
      });
    }
  }, [action, history]);

  return (
    <>
      <button
        type="button"
        className="button secondary expanded"
        onClick={close}
      >
        Close Action
      </button>
    </>
  );
};

export default function ActionStatusControl({ action }) {
  return action.status === 'open' ? (
    <CloseActionControl action={action} />
  ) : (
    <ReopenActionControl action={action} />
  );
}

ActionStatusControl.propTypes = {
  action: PropTypes.object.isRequired
};

CloseActionControl.propTypes = ActionStatusControl.propTypes;
ReopenActionControl.propTypes = CloseActionControl.propTypes;
