import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import { useHistory } from 'react-router-dom';
import { useRxCollection } from 'rxdb-hooks';
import MentionableInput from './mention-input';
import calendarIcon from '../../images/calendar.svg';
import clockIcon from '../../images/clock.svg';
import CurrentUserContext from '../common/CurrentUserContext';
import leftPad from '../common/left-pad';
import { composeValidators, mustNotBeInTheFuture } from '../common/validators';
import { UUID } from '../db/helpers';
import AutosaveSpy from '../forms/autosave/AutosaveSpy';
import AutosaveWarning from '../forms/autosave/AutosaveWarning';
import {
  buildStorageKey,
  loadAutosaveItem,
  removeAutosaveItem
} from '../forms/autosave/autosave-helpers';
import LogEntryDecorator from '../forms/decorators/LogEntryDecorator';

const PropagatedField = () => {
  return (
    <div className="small-5">
      <Field
        name="propagated"
        label="Include in incident log?"
        component="input"
        type="checkbox"
      />
      <label htmlFor="content">Include in incident log?</label>
    </div>
  );
};

const Create = ({
  parentType,
  parentId,
  collection,
  history,
  currentUserId,
  users,
  canBePropagated
}) => {
  const idPrefix = `log-entry-create-${parentType}-${parentId}`;
  const storageKey = buildStorageKey(idPrefix);

  const onSubmit = async (values, form) => {
    let submissionErrors = {};

    let params = {
      id: UUID.generate(),
      createdBy: currentUserId && currentUserId,
      source: { id: values.parentId, type: values.parentType },
      propagated: values.propagated,
      content: values.content,
      logTime: values.logTime
    };

    return collection
      .insert(params)
      .then(() => {
        removeAutosaveItem(storageKey);
        history.push({
          ...history.location,
          search: `?status_message=log_entries.create.success`
        });
        setTimeout(form.reset);
      })
      .catch(err => {
        submissionErrors[[FORM_ERROR]] = err.message || err;

        return submissionErrors;
      });
  };

  const now = new Date();
  const date = `${now.getFullYear()}-${leftPad(now.getMonth() + 1)}-${leftPad(
    now.getDate()
  )}`;
  const time = `${leftPad(now.getHours())}:${leftPad(now.getMinutes())}`;

  const values = {
    date,
    time,
    logTime: new Date(`${date} ${time}`).getTime(),
    propagated: false,
    content: '',
    parentType,
    parentId
  };

  const [warning, setWarning] = useState(false);
  const storedData = loadAutosaveItem(storageKey)
    ? JSON.parse(loadAutosaveItem(storageKey))
    : null;

  const storedForm = storedData && storedData.form;
  const initialValues = storedForm ? storedForm : values;

  useEffect(() => {
    if (!storedForm) {
      setWarning(false);

      return;
    }
    setWarning('saved');
  }, [storedForm]);

  return (
    <div>
      <Form
        initialValues={initialValues}
        onSubmit={useCallback(onSubmit, [
          collection,
          currentUserId,
          history,
          storageKey
        ])}
        validateOnBlur
      >
        {({ handleSubmit, submitting, submitError, form }) => (
          <form onSubmit={handleSubmit} className="grid-x">
            <AutosaveSpy
              storageKey={storageKey}
              DecoratorComponent={LogEntryDecorator}
            />
            <div className="cell small-12">
              <AutosaveWarning
                storageKey={storageKey}
                warning={warning}
                setWarning={setWarning}
                form={form}
              />
            </div>

            <div className="cell">
              {submitError && (
                <div className="alert alert-danger">{submitError}</div>
              )}
              <div className="grid-x align-justify">
                <div className="small-5">
                  <label htmlFor={`${idPrefix}-date`}>Date of Log entry</label>
                  <div className="input-group">
                    <Field
                      required
                      component="input"
                      id={`${idPrefix}-date`}
                      className="input-group-field"
                      validateFields={['date', 'time']}
                      validate={composeValidators(mustNotBeInTheFuture)}
                      name="date"
                      type="date"
                    />
                    <div className="input-group-label">
                      <img
                        alt="Calendar"
                        style={{ width: '0.9rem' }}
                        src={calendarIcon}
                      />
                    </div>
                  </div>
                </div>

                <div className="small-5">
                  <label htmlFor={`${idPrefix}-time`}>Time of Log entry</label>
                  <div className="input-group">
                    <Field
                      required
                      component="input"
                      id={`${idPrefix}-time`}
                      className="input-group-field"
                      name="time"
                      type="time"
                    />
                    <div className="input-group-label">
                      <img
                        alt="Clock"
                        style={{ width: '0.9rem' }}
                        src={clockIcon}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <Field
                name="content"
                id={`${idPrefix}-content`}
                component={MentionableInput}
                users={users}
                required
              />
              {canBePropagated && <PropagatedField />}
              <div className="row grid-x">
                <div className="cell auto" />
                <div className="cell shrink">
                  <button
                    className="button primary"
                    type="submit"
                    disabled={submitting}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

Create.propTypes = {
  parentType: PropTypes.string,
  parentId: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.object),
  canBePropagated: PropTypes.bool,
  history: PropTypes.object,
  currentUserId: PropTypes.string,
  collection: PropTypes.object.isRequired
};

const WrappedCreate = props => {
  const history = useHistory();
  const collection = useRxCollection('logentries');

  if (!collection) {
    return 'Loading...';
  }

  return (
    <CurrentUserContext.Consumer>
      {({ user: { id: currentUserId } }) => (
        <Create
          {...props}
          history={history}
          collection={collection}
          currentUserId={currentUserId}
        />
      )}
    </CurrentUserContext.Consumer>
  );
};

export default WrappedCreate;
