import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import usePageTitle from './hooks/usePageTitle';

const Page = props => {
  const { title, ...rest } = props;
  usePageTitle(title);

  return <Route {...rest} />;
};

Page.propTypes = {
  title: PropTypes.string
};

export default Page;
