import React, { useEffect } from 'react';
import { useIsTabletDevice, ThemeProvider } from './common/theme-context';

const Sidebar = () => {
  const showTabletUI = useIsTabletDevice().isTabletDevice;

  useEffect(() => {
    const element = document.querySelector('#desktop-sidebar');
    showTabletUI
      ? element.classList.remove('desktop-sidebar')
      : element.classList.add('desktop-sidebar');
  }, [showTabletUI]);

  return showTabletUI ? null : (
    <div className="sidebar">
      <a href="/incidents" className="button sidebar__button">
        Incidents
      </a>
      <a href="/actions" className="button sidebar__button">
        Actions
      </a>
      <a href="/navigation_aids" className="button sidebar__button">
        AtoNs
      </a>
      <a href="/manage-assets" className="button sidebar__button">
        Assets
      </a>
    </div>
  );
};

export default () => (
  <ThemeProvider>
    <Sidebar />
  </ThemeProvider>
);
