import { subDays } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import warningIcon from '../../images/critical-action.svg';
import ApplicationConfigContext from '../common/application-config-context';
import { localize } from '../common/date-format';

const WarningLabel = ({ text }) => (
  <span className="label warning">
    <img src={warningIcon} aria-hidden alt="" height="14" width="16" />
    &nbsp; {text}
  </span>
);

WarningLabel.propTypes = {
  text: PropTypes.string.isRequired
};

const ActionHeader = ({ action, expanded, ...rest }) => {
  const { overdueAfterDays } = useContext(ApplicationConfigContext);
  const overdueThreshold = overdueAfterDays
    ? subDays(new Date(), overdueAfterDays)
    : new Date();

  const [category, setCategory] = useState();
  const [assignee, setAssignee] = useState();
  useEffect(() => action.populate('category').then(setCategory), [action]);
  useEffect(() => action.populate('assignedTo').then(setAssignee), [action]);

  const dateLabel =
    (action.closedAt ? 'Closed ' : 'Due ') +
    localize(new Date(action.closedAt || action.datetime), {
      format: 'defaultDate'
    });

  const overdueWarning = action.datetime <= overdueThreshold.getTime() &&
    !action.closedAt && <WarningLabel text="Overdue" />;

  const priorityWarning = action.urgency !== 'normal' && (
    <WarningLabel text="Priority" />
  );

  const assigneeLabel = assignee
    ? `Assigned to ${assignee.name}`
    : 'Unassigned';

  const statusLabel =
    action.status === 'open' ? (
      <span className="label success">Open</span>
    ) : (
      <span className="label secondary">Closed</span>
    );

  return (
    <div
      data-action-id={action.id}
      id={`action-item-${action.id}`}
      className={`action-header cell large-offset-1 small-8
                  large-9 panel panel--list ${
                    action.status === 'open' ? 'secondary' : 'light-gray'
                  } grid-x ${
        expanded ? 'margin-bottom-0' : 'margin-bottom-1'
      } `}
      {...rest}
    >
      <div className="cell auto grid-x align-middle">
        <div className="cell shrink large-auto text-black">
          <h3 className="margin-vertical-0 font-bold">{category?.name}</h3>
          <small className="medium-flex-container medium-flex-dir-column large-flex-dir-row">
            {assigneeLabel && (
              <span className="">
                {assigneeLabel}
                <span className="show-for-large">&nbsp;|&nbsp;</span>
              </span>
            )}
            {dateLabel}
          </small>
        </div>
        <div className="cell auto large-shrink text-right large-order-2">
          {statusLabel}
        </div>
        <div className="cell shrink margin-vertical-1 large-margin-vertical-0">
          {priorityWarning}
          {overdueWarning}
        </div>
      </div>
      {/* The conditional class below is to make sure the divider is the "right" height.
          With badges shown, it will be around half the height of the container, otherwise
          it should fill the container */}
      <div
        className={`cell shrink divider-cell
        ${priorityWarning || overdueWarning ? 'height-50' : 'height-100'}
        height-50 large-height-100`}
      />
      <div className="cell shrink text-right">
        <small className="font-bold text-uppercase">Action</small>
        <br />
        <span className="text-tertiary">#{action.sequenceNumber}</span>
      </div>
      <div className="cell shrink">
        <small
          className={`margin-bottom-0 padding-right-0 collapsible-list__control collapsible-list__control--nested collapsible-list__control--${
            expanded ? 'collapse' : 'expand'
          }`}
        >
          <span className="show-for-sr">Expand/collapse</span>
        </small>
      </div>
    </div>
  );
};

ActionHeader.propTypes = {
  action: PropTypes.object.isRequired,
  expanded: PropTypes.bool
};

export default ActionHeader;
