import { FORM_ERROR } from 'final-form';
import pick from 'lodash-es/pick';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import AssetFields from './asset-fields';
import ClassificationsContext from '../common/ClassificationsContext';
import ApplicationConfigContext from '../common/application-config-context';
import { UUID } from '../db/helpers';
import { withRxDB } from '../db/hoc';
import { ATON_CATEGORY_NAME } from '../db/schemas/navigationAid';

function AssetCreate(props) {
  const { db, history, assetCategories } = props;
  const collection = db.collections.assets;

  function onSubmit(values) {
    let submissionErrors = {};
    values.metadata = pick(values, [
      'serialNumber',
      'purchaseDate',
      'supplier'
    ]);

    delete values.serialNumber;
    delete values.purchaseDate;
    delete values.supplier;

    if (!values.operationalArea && values.parentOperationalArea) {
      values.operationalArea = values.parentOperationalArea;
    }

    values.id = UUID.generate();

    return collection
      .insert(values)
      .then(doc =>
        history.push(
          `/manage-assets/${doc.id}?status_message=assets.create.success`
        )
      )
      .catch(err => {
        submissionErrors[[FORM_ERROR]] = err.message || err;

        return submissionErrors;
      });
  }

  return (
    <div className="page page--white-background">
      <div className="row row--divider">
        <h1>New Asset</h1>
      </div>

      <div className="grid-x grid-padding-x row row--spaced margin-top-2">
        <div className="cell auto">
          <Form onSubmit={onSubmit}>
            {({
              handleSubmit,
              submitting,
              form,
              submitError,
              values: params
            }) => (
              <form onSubmit={handleSubmit} className="grid-x">
                <div className="cell small-auto large-8 large-offset-2">
                  {submitError && (
                    <div className="alert alert-danger">{submitError}</div>
                  )}

                  <ApplicationConfigContext.Consumer>
                    {() => (
                      <ClassificationsContext.Consumer>
                        {classifications => (
                          <AssetFields
                            {...classifications}
                            form={form}
                            params={params}
                            assetCategories={assetCategories.filter(
                              cat => cat.name !== ATON_CATEGORY_NAME
                            )}
                          />
                        )}
                      </ClassificationsContext.Consumer>
                    )}
                  </ApplicationConfigContext.Consumer>

                  <div className="row grid-x">
                    <div className="cell auto" />
                    <div className="cell padding-left-2 padding-right-2">
                      <button
                        className="button primary expanded padded"
                        type="submit"
                        disabled={submitting}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}

AssetCreate.propTypes = {
  operationalAreas: PropTypes.arrayOf(PropTypes.object),
  assetCategories: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired
};

export default withRouter(withRxDB(AssetCreate));
