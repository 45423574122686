import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import tableCog from '../../images/table-cog.svg';
import ApplicationConfigContext from '../common/application-config-context';
import checkStatus from '../common/status-checker';

export default function StatusColumn({ asset, nextCheck }) {
  const { overdueAfterDays } = useContext(ApplicationConfigContext);

  return (
    <div className="grid-x align-middle align-justify">
      <div>{checkStatus(nextCheck, { overdueAfterDays }) || 'N/A'}</div>
      <ul
        id={`dropdown-menu-${asset.id}`}
        className="dropdown menu margin-left-auto"
        data-dropdown-menu
        data-alignment="right"
      >
        <li>
          <Link to={`/manage-assets/${asset.id}`}>
            <img src={tableCog} className="icon" alt="options" />
          </Link>
          <ul className="menu">
            <li>
              <Link to={`/actions/new?assetId=${asset.id}`}>New Action</Link>
            </li>
            <li>
              <Link to={`/manage-assets/${asset.id}#add_log_entry`}>
                Log Entry
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

StatusColumn.propTypes = {
  asset: PropTypes.object.isRequired,
  nextCheck: PropTypes.object
};
