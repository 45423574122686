import schema from '../schemas/user';

export default {
  users: {
    schema,
    migrationStrategies: {
      1: oldDoc => {
        oldDoc.behaviours = [];
        oldDoc.children &&
          oldDoc.children.forEach(child => (child.behaviours = []));

        return oldDoc;
      },
      2: oldDoc => {
        oldDoc.admin = false;

        return oldDoc;
      },
      3: oldDoc => oldDoc
    }
  }
};
