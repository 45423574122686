const mustBeNumber = value =>
  value && value.length && isNaN(value) ? 'Must be a number' : undefined;
const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;
const maxValue = max => value =>
  isNaN(value) || value <= max ? undefined : `Should be less than ${max}`;
const valueBetween = (min, max) => value =>
  value === '' || isNaN(value) || (value >= min && value <= max)
    ? undefined
    : `Should be between ${min} and ${max}`;

const mustNotBeInTheFuture = (_value, { date, time = '' }) => {
  const now = new Date();
  const value = new Date(`${date} ${time}`);

  return isNaN(value) || value <= now
    ? undefined
    : `Should not be in the future`;
};

const composeValidators =
  (...validators) =>
  (...args) =>
    validators.reduce(
      (error, validator) => error || validator(...args),
      undefined
    );

export {
  mustBeNumber,
  mustNotBeInTheFuture,
  minValue,
  maxValue,
  valueBetween,
  composeValidators
};
