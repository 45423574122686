import schema from '../schemas/asset';

export default {
  assets: {
    schema,
    migrationStrategies: {
      1: doc => doc,
      2: doc => doc,
      3: doc => doc,
      4: doc => doc
    }
  }
};
