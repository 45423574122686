import { gql } from '@apollo/client';
const collection = gql`
  query AssetCollection(
    $offset: Int
    $limit: Int
    $sort: SortBy
    $search: String
    $filters: [Filter!]
  ) {
    assetCollection(
      offset: $offset
      limit: $limit
      search: $search
      sort: $sort
      filters: $filters
    ) {
      collection {
        id
        identificationNumber
        name
        locationDescription
        location {
          lat
          lng
          srid
        }
        metadata
        category {
          id
          name
        }
        subcategory {
          id
          name
        }
        operationalArea {
          id
          name
        }
        incidentCollection {
          pageInfo {
            count
          }
        }
        actionCollection(
          sort: { order: "closed_at", dir: DESC }
          limit: 1
          filters: [{ identifier: "official_visit", value: "true" }]
        ) {
          collection {
            id
            closedAt
            datetime
            category {
              id
              name
            }
            subcategory {
              id
              name
            }
            assignedTo {
              id
              name
            }
          }
        }
        routineCheck: actionCollection(
          sort: { order: "datetime", dir: ASC }
          limit: 1
          filters: [
            { identifier: "category_name", value: "Routine Check" }
            { identifier: "status", value: "open" }
          ]
        ) {
          collection {
            id
            closedAt
            datetime
            category {
              id
              name
            }
            subcategory {
              id
              name
            }
            assignedTo {
              id
              name
            }
          }
        }
      }
      pageInfo {
        count
      }
    }
  }
`;

export { collection };
