import React, { useContext } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NavigationAidCreate from './create';
import NavigationAidEdit from './edit';
import NavigationAidCollection from './index';
import NavigationAidShow from './show';
import ClassificationsContext from '../common/ClassificationsContext';
import Page from '../common/Page';
import applicationConfigContext from '../common/application-config-context';

export default () => {
  const { operationalAreas, assetCategories } = useContext(
    ClassificationsContext
  );
  const config = useContext(applicationConfigContext);
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/new`}>
        <NavigationAidCreate
          operationalAreas={operationalAreas}
          operationalBounds={config.operationalBounds}
          defaultPosition={config.defaultPosition}
          assetCategories={assetCategories}
        />
      </Route>
      <Route path={`${match.path}/:id/edit`} component={NavigationAidEdit} />
      <Page
        title="AtoN Summary"
        path={`${match.path}/:id`}
        component={NavigationAidShow}
      />
      <Page title="AtoNs" path={match.path}>
        <NavigationAidCollection
          operationalAreas={operationalAreas}
          assetCategories={assetCategories}
        />
      </Page>
    </Switch>
  );
};
