import PropTypes from 'prop-types';
import React from 'react';

const ThemeContext = React.createContext();

const useIsTabletDevice = () => {
  const context = React.useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
};

function ThemeProvider(props) {
  // Set the ThemeContext's state to 'true' if the client has a touch screen, i.e navigator.maxTouchPoints > 0
  // We use the ThemeContext in components to decide if we should render a tablet or desktop theme
  // If the client device does not have a touch screen, we check if the URL parameter 'isTablet' is set to 'true'
  // We've have built in the additional parameter check so that we can easily turn on a tablet theme for testing and demos

  let isTablet = '';
  if (navigator.maxTouchPoints > 0) {
    isTablet = 'true';
  } else {
    const url = new URL(window.location.href);
    const isTabletParam = url.searchParams.get('isTablet');
    isTablet = isTabletParam === 'true' ? 'true' : '';
  }

  const [isTabletDevice, setIsTabletDevice] = React.useState(isTablet);
  React.useEffect(() => {
    setIsTabletDevice(isTablet);
  }, [isTablet]);

  const value = { isTabletDevice, setIsTabletDevice };

  return (
    <ThemeContext.Provider value={value} {...props}>
      {props.children}
    </ThemeContext.Provider>
  );
}

export { ThemeProvider, useIsTabletDevice };

ThemeProvider.propTypes = {
  children: PropTypes.node
};
