import PropTypes from 'prop-types';
import React from 'react';
import useRxDocument from './hooks/useRxDocument';

const defaultContext = {
  userId: null,
  user: null,
  isLoading: false
};
const Context = React.createContext(defaultContext);

const Provider = ({ id, children }) => {
  const currentUserId = id || {};
  const { result: user, isLoading } = useRxDocument('users', currentUserId);

  return (
    <Context.Provider value={{ user: user || { id }, isLoading }}>
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node
};

export { Context, Provider };
export default Context;
