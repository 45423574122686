import schema from '../schemas/incident';

export default {
  incidents: {
    schema,
    migrationStrategies: {
      // Made incident involved vessel description optional
      1: doc => doc
    }
  }
};
