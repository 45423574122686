import { useEffect } from 'react';
const queryString = require('query-string');

export default function useURLParams(props) {
  const { search = '', appliedFilters, limit, offset, sortBy } = props;

  useEffect(() => {
    const searchParam = search === '' ? null : search;
    let params =
      '?' +
      queryString.stringify(
        {
          limit,
          offset,
          dir: sortBy.dir,
          order: sortBy.order,
          appliedFilters: appliedFilters.map(filter => JSON.stringify(filter)),
          search: searchParam
        },
        { skipNull: true, arrayFormat: 'index' }
      );

    if (params === location.search) {
      return;
    }

    if (!location.search) {
      window.history.replaceState(null, null, params);

      return;
    }

    window.history.pushState(null, null, params);
  }, [search, limit, offset, sortBy, appliedFilters]);
}
