import PropTypes from 'prop-types';
import React from 'react';
import { Router, Route } from 'react-router-dom';
import Diagnostics from './Diagnostics';
import Flash from './Flash';
import ApplicationRouter from './Router';
import { Provider as ClassificationsContextProvider } from './common/ClassificationsContext';
import { Provider as CurrentUserProvider } from './common/CurrentUserContext';
import ApplicationConfigContext from './common/application-config-context';
import browserHistory from './common/browserHistory';
import { ThemeProvider } from './common/theme-context';
import DatabaseProvider from './db/Provider';
import GraphqlClientProvider from './graphql/Provider';

const Application = ({ currentUserId, configuration }) => {
  const routes = (
    <ApplicationConfigContext.Provider value={configuration || {}}>
      <ThemeProvider>
        <DatabaseProvider>
          <CurrentUserProvider id={currentUserId}>
            <ClassificationsContextProvider>
              <Flash />
              <ApplicationRouter />
            </ClassificationsContextProvider>
          </CurrentUserProvider>
        </DatabaseProvider>

        <Route path="/diagnostics" component={Diagnostics} />
      </ThemeProvider>
    </ApplicationConfigContext.Provider>
  );

  return <Router history={browserHistory}>{routes}</Router>;
};

Application.propTypes = {
  currentUserId: PropTypes.string,
  location: PropTypes.string,
  configuration: PropTypes.object
};

export default props => (
  <GraphqlClientProvider>
    <Application {...props} />
  </GraphqlClientProvider>
);
