const CATEGORY_NAME = 'Vessel';

export const harbourmasterVesselsPullQueryBuilder = () => {
  return doc => {
    const lastDoc = doc || { id: null, updatedAt: null };
    const query = `
    query ($filters: [Filter!]) {
      assetCollection(filters: $filters, sort: { dir: ASC, order: "updatedAt" }) {
        collection {
          id
          name
          updatedAt
        }
      }
    }
    `;

    return {
      query,
      variables: {
        filters: [
          {
            identifier: 'categoryName',
            value: CATEGORY_NAME
          },
          {
            identifier: 'updated_since',
            value: lastDoc.updatedAt
              ? new Date(lastDoc.updatedAt).toISOString()
              : ''
          }
        ]
      }
    };
  };
};
