import PropTypes from 'prop-types';
import React from 'react';

export default function InvolvedVesselSummary({ vessel }) {
  return (
    <>
      {vessel.name}
      <br />
      {vessel.description}
      <br />
    </>
  );
}

InvolvedVesselSummary.propTypes = {
  vessel: PropTypes.object.isRequired
};
