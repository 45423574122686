import { UUID } from '../helpers';

export const taxonomyProperties = {
  id: {
    type: 'string',
    maxLength: UUID.maxLength,
    final: true
  },
  name: {
    type: 'string'
  },
  behaviours: {
    type: 'array',
    items: { type: 'string' }
  }
};

export const migrationStrategies = {
  1: oldDoc => {
    oldDoc.behaviours = [];
    oldDoc.children &&
      oldDoc.children.forEach(child => (child.behaviours = []));

    return oldDoc;
  }
};

export default {
  version: 1,
  primaryKey: 'id',
  type: 'object',
  properties: {
    ...taxonomyProperties,
    id: {
      ...taxonomyProperties.id
    },
    updatedAt: {
      type: 'integer'
    },
    children: {
      type: 'array',
      items: {
        type: 'object',
        properties: taxonomyProperties
      }
    }
  },
  required: ['id', 'name']
};
