import PropTypes from 'prop-types';
import React from 'react';
import { options as checkIntervalOptions } from './data/check-interval-options';
import TaxonomySelect from '../forms/classification-select';
import { FormGroupField } from '../forms/field';
import Select from '../forms/select';
import TaxonomyChildSelect from '../forms/taxonomy-child-select';

const AssetFields = props => {
  const { operationalAreas, assetCategories } = props;

  return (
    <>
      <fieldset className="form-section panel summary">
        <legend>
          <h3>Asset details</h3>
        </legend>

        <label htmlFor="name">Asset Name</label>
        <FormGroupField
          component="input"
          required
          type="text"
          id="name"
          name="name"
        />

        <TaxonomySelect
          id="category"
          name="category"
          label="Category"
          includeBlank
          collection={assetCategories}
          required
        />

        <TaxonomyChildSelect
          parents={assetCategories}
          parentAttribute="category"
          childAttribute="subcategory"
          label="Subcategory"
          required
        />

        <label htmlFor="description">Details</label>
        <FormGroupField
          component="textarea"
          id="description"
          name="description"
        />
      </fieldset>

      <fieldset className="form-section panel summary">
        <legend>
          <h3>Asset Location</h3>
        </legend>

        <label htmlFor="locationDescription">Location</label>
        <FormGroupField
          component="textarea"
          id="locationDescription"
          name="locationDescription"
        />

        <TaxonomySelect
          id="parentOperationalArea"
          name="parentOperationalArea"
          includeBlank
          label="Operational area"
          collection={operationalAreas}
        />
        <TaxonomyChildSelect
          parents={operationalAreas}
          parentAttribute="parentOperationalArea"
          childAttribute="operationalArea"
          label="Operational sub-area"
        />
      </fieldset>

      <fieldset className="form-section panel summary">
        <legend>
          <h3>Routine check period</h3>
        </legend>

        <Select
          id="checkIntervalMonths"
          name="checkIntervalMonths"
          label="Asset Check"
          placeholder="None"
          options={checkIntervalOptions}
        />
      </fieldset>

      <fieldset className="form-section panel summary">
        <legend>
          <h3>Other Details</h3>
        </legend>

        <label htmlFor="serialNumber">Serial Number</label>
        <FormGroupField
          component="input"
          type="text"
          id="serialNumber"
          name="serialNumber"
        />

        <label htmlFor="purchaseDate">Purchase Date</label>
        <FormGroupField
          component="input"
          id="purchaseDate"
          name="purchaseDate"
          type="date"
        />

        <label htmlFor="supplier">Supplier</label>
        <FormGroupField
          component="input"
          type="text"
          id="supplier"
          name="supplier"
        />
      </fieldset>
    </>
  );
};

AssetFields.propTypes = {
  form: PropTypes.object.isRequired,
  operationalAreas: PropTypes.arrayOf(PropTypes.object),
  assetCategories: PropTypes.arrayOf(PropTypes.object)
};

export default AssetFields;
