import PointSchema from './point';
import { UUID } from '../helpers';

export default {
  version: 4,
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: UUID.maxLength,
      final: true
    },
    sequenceNumber: { type: ['integer', 'null'] },
    name: { type: 'string' },
    updatedAt: { type: ['integer', 'null'] },
    identificationNumber: { type: ['string', 'null'] },
    location: { ...PointSchema, type: ['object', 'null'] },
    locationDescription: { type: ['string', 'null'] },
    description: { type: ['string', 'null'] },
    checkIntervalMonths: { type: ['number', 'null'], minimum: 0.25 },
    metadata: { type: 'object', additionalProperties: true },
    category: { type: ['string', 'null'], ref: 'assetcategories' },
    subcategory: { type: ['string', 'null'], ref: 'assetcategories' },
    operationalArea: { type: ['string', 'null'], ref: 'operationalareas' },
    parentOperationalArea: { type: ['string', 'null'], ref: 'operationalareas' }
  },
  required: ['id', 'name']
};
