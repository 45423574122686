import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import detectBehaviour from '../common/detectBehaviour';

const OpenRecurringActionLink = ({ openActions }) => {
  const [openRecurringAction, setOpenRecurringAction] = useState();

  useEffect(() => {
    if (!openActions) {
      return;
    }

    // Reset states when actions change
    setOpenRecurringAction(null);

    const resolveOpenRecurringAction = async () => {
      for (let action of openActions) {
        if (await detectBehaviour(action, 'recurring')) {
          setOpenRecurringAction(action);
          break;
        }
      }
    };

    resolveOpenRecurringAction();
  }, [openActions]);

  return (
    <>
      {openRecurringAction && (
        <>
          <a
            href={`#action-item-${openRecurringAction.id}`}
            className="button tertiary expanded"
          >
            Routine Check
          </a>
        </>
      )}
    </>
  );
};

OpenRecurringActionLink.propTypes = {
  openActions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default OpenRecurringActionLink;
