import { involvedIndividualsColumn } from './incident-columns';
import { localize } from '../../common/date-format';

export default async function getIncidentDocumentProperties(incident) {
  const { datetime, id, sequenceNumber, status } = incident;

  const userName = (await incident.populate('user'))?.name;
  const operationalAreaName = (
    (await incident.populate('operationalArea')) ||
    (await incident.populate('parentOperationalArea'))
  )?.name;
  const incidentCategoryName = (
    (await incident.populate('subcategory')) ||
    (await incident.populate('category'))
  )?.name;
  const harbourUserGroupName = (await incident.populate('harboursUserGroup'))
    ?.name;
  const involvedIndividuals = incident.involvedIndividuals;

  return [
    { value: id, skip: true },
    { value: sequenceNumber },
    { value: involvedIndividualsColumn(involvedIndividuals) },
    { value: incidentCategoryName },
    { value: operationalAreaName },
    { value: harbourUserGroupName },
    { value: userName },
    { value: localize(datetime) },
    { value: status && status.charAt(0).toUpperCase() + status.slice(1) }
  ];
}
