import * as Sentry from '@sentry/browser';
import { ensureNotFalsy } from 'rxdb';
import { v4 as uuidv4 } from 'uuid';

export const retryTime = (process.env.RAILS_ENV === 'test' ? 1 : 10) * 1000;

/**
 * Provides our own GraphQL request function, allowing us to provide more logging
 * and error reporting than what RxDB provides
 *
 * @this {RxGraphQLReplicationState} The RxGraphQLReplicationState that is being replicated
 * @param {*} queryParams Any query params to pass along
 * @returns A Promise that resolves to the JSON response from the server
 */
function graphQLRequest(queryParams) {
  const httpUrl = this.url.http;
  const clientState = this.clientState;
  const headers = new Headers(clientState.headers || {});
  headers.append('Content-Type', 'application/json');

  const req = new Request(ensureNotFalsy(httpUrl), {
    method: 'POST',
    body: JSON.stringify(queryParams),
    headers,
    credentials: clientState.credentials
  });

  return fetch(req)
    .then(async res => {
      const responseBody = await res.text();

      if (!res.ok) {
        console.error(
          `GraphQL request failed with status ${res.status}. Response body: ${responseBody}`
        );

        if (res.status === 401) {
          window.location = '/users/sign_in';

          return;
        }

        // Additional error handling can be done here if needed
        throw new Error(`GraphQL request failed with status ${res.status}`);
      }

      try {
        // Attempt to parse the JSON response
        return JSON.parse(responseBody);
      } catch (parseError) {
        console.error(
          `Error parsing JSON response: ${parseError.message}. Response body: ${responseBody}`
        );
        // Additional error handling can be done here if needed
        throw parseError;
      }
    })
    .catch(error => {
      console.error(`GraphQL request error: ${error.message}`);
      // Additional error handling can be done here if needed
      throw error;
    });
}

/** Filter out 'Failed to fetch' errors, since those
 *  generally mean that the client is offline.
 */
function didFailToFetch({ parameters: { errors } }) {
  return errors && errors.some(error => error.message === 'Failed to fetch');
}

export function replicationWithErrorReporting(replication) {
  replication.graphQLRequest = graphQLRequest;

  replication.error$.subscribe(error => {
    console.error(error);

    if (!didFailToFetch(error)) {
      Sentry.captureException(error);
    }
  });

  return replication;
}

export function replicationWithLiveInterval(replication) {
  if (replication.live) {
    setInterval(() => replication.reSync(), replication.retryTime);
  }

  return replication;
}

export function replicationPushResponseModifier({ errors }) {
  // If we got errors, throw them so that the replication is retried
  if (errors?.length) {
    throw new Error(
      `Server-side validation failed: ${errors
        .map(({ message, path }) => `${path} ${message}`)
        .join(', ')}`
    );
  }

  // Always return an empty array if we haven't thrown,
  // otherwise RxDB treats this as a conflict
  return [];
}

export function removeOptionalProperties(doc) {
  // We have to remove optional non-existent field values
  // they are set as null by GraphQL but should be undefined
  Object.entries(doc).forEach(([k, v]) => {
    if (v === null) {
      delete doc[k];
    }
  });

  return doc;
}

export function wrapDocumentsWithCheckpoint(
  response, // the exact response that was returned from the server
  _origin, // either 'handler' if plainResponse came from the pull.handler, or 'stream' if it came from the pull.stream
  requestCheckpoint // if origin==='handler', the requestCheckpoint contains the checkpoint that was send to the backend
) {
  /**
   * In this example we aggregate the checkpoint from the documents array
   * that was returned from the graphql endpoint.
   */
  const docs = response;

  const returnValue = {
    documents: docs,
    checkpoint:
      docs.length === 0
        ? requestCheckpoint
        : {
            id: docs[docs.length - 1].id,
            updatedAt: new Date(docs[docs.length - 1].updatedAt).getTime()
          }
  };

  return Promise.resolve(returnValue);
}

export class UUID {
  static maxLength = 36; // 32 characters + 4 dashes
  static generate() {
    return uuidv4();
  }
}
