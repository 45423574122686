import PropTypes from 'prop-types';
import React from 'react';
import ClassificationsContext from '../common/ClassificationsContext';
import FilterForm from '../dataTable/filter-form';

export default function FilterFormContainer({
  resource,
  handleSubmit,
  form,
  submitting,
  pristine,
  values,
  appliedFilters,
  clearFilters,
  extraClassifications,
  filters
}) {
  return (
    <>
      <ClassificationsContext.Consumer>
        {classifications => (
          <form onSubmit={handleSubmit}>
            <FilterForm
              form={form}
              submitting={submitting}
              pristine={pristine}
              values={values}
              filters={filters}
              filterOptions={{ ...classifications, ...extraClassifications }}
            />
          </form>
        )}
      </ClassificationsContext.Consumer>
      <div className=" grid-x grid-margin-x cell shrink">
        <button
          className={`button ${appliedFilters[0] ? 'primary' : 'secondary'}`}
          type="button"
          data-toggle="filters-dropdown"
        >
          {appliedFilters[0] ? 'View current filters' : `Filter ${resource} by`}
        </button>
        <button
          type="button"
          /* eslint-disable-next-line react/jsx-no-bind */
          onClick={() => clearFilters(form)}
          className="button secondary"
        >
          Clear filters
        </button>
      </div>
    </>
  );
}

FilterFormContainer.propTypes = {
  resource: PropTypes.string,
  handleSubmit: PropTypes.func,
  form: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  values: PropTypes.array,
  appliedFilters: PropTypes.array,
  clearFilters: PropTypes.func,
  extraClassifications: PropTypes.obj,
  filters: PropTypes.obj
};
