import { compact, uniqBy } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import usePermittedActionCategories, {
  RESTRICTED_ACCESS_BEHAVIOUR
} from './behaviours/restrictedAccessCategory';
import calendarIcon from '../../images/calendar.svg';
import ClassificationsContext from '../common/ClassificationsContext';
import CurrentUserContext from '../common/CurrentUserContext';
import detectBehaviour from '../common/detectBehaviour';
import AutosaveWarning from '../forms/autosave/AutosaveWarning';
import {
  default as ClassificationSelect,
  default as TaxonomySelect
} from '../forms/classification-select';
import { FormGroupField } from '../forms/field';
import LocationSelect from '../forms/location-select';
import TaxonomyChildSelect from '../forms/taxonomy-child-select';

const ActionFields = ({
  form,
  storageKey,
  action,
  assets,
  operationalBounds,
  defaultPosition,
  warning,
  setWarning
}) => {
  const [assignedToOptions, setAssignedToOptions] = useState([]);
  const [requestedByOptions, setRequestedByOptions] = useState([]);
  const [categoryRestricted, setCategoryRestricted] = useState(false);
  const { user } = useContext(CurrentUserContext);
  const { users, operationalAreas } = useContext(ClassificationsContext);
  const categories = usePermittedActionCategories();

  useEffect(
    () =>
      (async () => {
        const assignedTo = action && (await action.populate('assignedTo'));
        setAssignedToOptions(uniqBy(compact(users.concat([assignedTo])), 'id'));

        const requestedBy = action && (await action.populate('requestedBy'));
        setRequestedByOptions(
          uniqBy(compact(users.concat([requestedBy])), 'id')
        );

        // If the user is not an admin and this action belongs to a restricted category,
        // it can't be changed.
        action &&
          setCategoryRestricted(
            (await detectBehaviour(action, RESTRICTED_ACCESS_BEHAVIOUR)) &&
              !user.admin
          );
      })() && undefined,
    [action, users, user]
  );

  return (
    <>
      <AutosaveWarning
        storageKey={storageKey}
        warning={warning}
        setWarning={setWarning}
        form={form}
      />
      <fieldset className="form-section panel panel--large summary with-header margin-top-0">
        <div className="panelheader">
          <h2>Action details</h2>
        </div>
        <div className="panel-inner">
          <label className="panel-label" htmlFor="date">
            Action due date
          </label>
          <div className="input-group">
            <FormGroupField
              required
              component="input"
              id="date"
              className="input-group-field"
              name="date"
              type="date"
            />
            <div className="input-group-label">
              <img
                alt="Calendar"
                style={{ width: '0.9rem' }}
                src={calendarIcon}
              />
            </div>
          </div>

          <ClassificationSelect
            id="requestedBy"
            name="requestedBy"
            label="Recorded by"
            labelClass="panel-label"
            required
            collection={requestedByOptions}
          />

          <ClassificationSelect
            id="assignedTo"
            name="assignedTo"
            includeBlank
            labelClass="panel-label"
            label="Assign action to"
            collection={assignedToOptions}
          />
        </div>
      </fieldset>

      <fieldset className="form-section panel panel--large summary">
        {!categoryRestricted && (
          <>
            <TaxonomySelect
              id="category"
              name="category"
              includeBlank
              required
              label="Action category"
              labelClass="panel-label"
              collection={categories}
            />

            <TaxonomyChildSelect
              parents={categories}
              required
              parentAttribute="category"
              childAttribute="subcategory"
              label="Action sub-category"
              labelClass="panel-label"
            />
          </>
        )}

        <div className="row grid-x">
          <div className="input-group panel-label panel panel--bordered panel--compact small-12 medium-6 xlarge-4">
            <FormGroupField name="isPriority" type="checkbox">
              {props => {
                return (
                  <>
                    {/* eslint-disable react/prop-types */}
                    <label className="checkbox-label">
                      <span>This is a priority action</span>
                      <input {...props.input} />
                      <span className="checkmark" />
                    </label>
                    {/* eslint-enable react/prop-types */}
                  </>
                );
              }}
            </FormGroupField>
          </div>
        </div>

        {/* CCSD-1441: Adjust labels for fields to clarify what the fields are used for */}
        <label className="panel-label" htmlFor="instructions">
          Action description
        </label>
        <FormGroupField
          component="textarea"
          rows={10}
          required
          id="instructions"
          name="instructions"
        />

        <label className="panel-label" htmlFor="mooringNumber">
          Mooring number
        </label>
        <FormGroupField
          component="input"
          type="text"
          pattern="^[0-9]{4}$"
          id="mooringNumber"
          name="mooringNumber"
        />
        <div className="help-text margin-bottom-1">
          Mooring numbers are a four-digit number
        </div>

        <label className="panel-label" htmlFor="marineFarmSiteNumber">
          Marine farm site number
        </label>
        <FormGroupField
          component="input"
          type="text"
          pattern="^[0-9]{4}$"
          id="marineFarmSiteNumber"
          name="marineFarmSiteNumber"
        />
        <div className="help-text margin-bottom-1">
          Marine farm site numbers are a four-digit number, without the prefix
          &apos;MF&apos;
        </div>

        <TaxonomySelect
          id="asset"
          name="asset"
          includeBlank
          collection={assets}
          labelClass="panel-label"
          placeholder="Asset name"
          label="AtoN/Asset"
        />
      </fieldset>

      <fieldset className="form-section panel panel--large summary with-header">
        <div className="panelheader">
          <h2>Location</h2>
        </div>
        <div className="panel-inner">
          <LocationSelect
            bounds={operationalBounds}
            defaultPosition={defaultPosition}
            form={form}
          />
          <TaxonomySelect
            id="parentOperationalArea"
            name="parentOperationalArea"
            includeBlank
            required
            label="Operational area"
            labelClass="panel-label"
            collection={operationalAreas}
          />
          <TaxonomyChildSelect
            parents={operationalAreas}
            parentAttribute="parentOperationalArea"
            childAttribute="operationalArea"
            label="Operational sub-area"
            labelClass="panel-label"
          />
        </div>
      </fieldset>
    </>
  );
};

ActionFields.propTypes = {
  operationalAreas: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.object),
  users: PropTypes.arrayOf(PropTypes.object),
  action: PropTypes.object,
  form: PropTypes.object.isRequired,
  storageKey: PropTypes.string,
  setWarning: PropTypes.func,
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  assets: PropTypes.arrayOf(PropTypes.object),
  operationalBounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  defaultPosition: PropTypes.arrayOf(PropTypes.number),
  DecoratorComponent: PropTypes.func
};

export default ActionFields;
